import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Modal, Switch } from "antd";

import ConnectData from "containers/connect.container";
import colors from "theme/colors";
import { PAYMENT_METHOD_NAME, PAYMENT_METHOD_TYPE } from "constants/index";
import { useTranslation } from "react-i18next";

const SwitchContainer = styled.div`
  height: 431px;
  overflow-y: scroll;
  padding-right: 15px;
  padding-left: 15px;
`;

const SettingRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  padding-left: ${(props) => (props.$offset ? "20px" : "0px")};
`;

const SettingText = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 18px;
  transition: all 0.36s;
  align-items: center;
  color: ${({ disabled }) => (disabled ? colors.lightGrey : colors.black)};
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${colors.lightGrey};
  margin-left: ${(props) => (props.$offset ? "20px" : "0px")};
`;

const DeviceSettings = ({ settings, onSettingChange, forgetDevice, device }) => {
  const { t } = useTranslation();

  const { payment_methods } = ConnectData.useContainer();
  const paymentMethods = payment_methods
    .filter((pm) => pm.show_in_pos && pm.type !== PAYMENT_METHOD_TYPE.CASH)
    .sort((lhs, rhs) => lhs.type.localeCompare(rhs.type));

  const handleForgetDevice = () => {
    Modal.confirm({
      title: t("devices.ForgetDevice.Title", { name: device.name }),
      okText: t("devices.ForgetDevice.Forget"),
      cancelText: t("Cancel"),
      onOk: () => forgetDevice(device.id),
    });
  };

  return (
    <SwitchContainer>
      {paymentMethods.map((pm, index, arr) => {
        const key = pm.type === PAYMENT_METHOD_TYPE.CUSTOM ? pm.id : pm.type;
        const value = pm.type === PAYMENT_METHOD_TYPE.CUSTOM ? settings[pm.id] : settings[pm.type];
        return (
          <React.Fragment key={pm.id}>
            <SettingRow
              onClick={() => onSettingChange(key, !value)}
            >
              <SettingText>
                {pm.type === PAYMENT_METHOD_TYPE.CUSTOM ? pm.name : PAYMENT_METHOD_NAME[pm.name]}
              </SettingText>
              <Switch checked={value} />
            </SettingRow>
            {index + 1 !== arr.length && <Divider />}
          </React.Fragment>
        );
      })}
      {device && (
        <Button
          onClick={handleForgetDevice}
          danger
          block
          style={{ marginBottom: 10 }}
        >
          {t("devices.ForgetDevice.Button")}
        </Button>
      )}
    </SwitchContainer>
  );
};

DeviceSettings.propTypes = {
  settings: PropTypes.object.isRequired,
  onSettingChange: PropTypes.func,
  forgetDevice: PropTypes.func,
  device: PropTypes.object,
};

export default DeviceSettings;
