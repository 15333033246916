import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Col } from "antd";
import * as Sentry from "@sentry/react";

import colors from "theme/colors";
import { printComponentOnPrinter } from "utils/print";

import DeviceTabs from "./components/DeviceTabs";

const DeviceContainer = styled(Col)`
  padding-top: 20px;
`;

const DeviceHeader = styled.h2`
  flex: 1;
  margin: 0px;
`;

const DeviceHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 15px;
  padding-left: 15px;
`;

const Subtext = styled.div`
  color: ${colors.grey};
  padding-right: 15px;
  padding-left: 15px;
`;

const PrinterSettings = ({ device, onDeviceChange, t }) => {
  const handleTestPrint = () => {
    const testContent = device.type === "printer-escpos"
      ? `Test Print. Device IP: ${device.ip}` : `Test Print. Device Name: ${device.name}`;
    printComponentOnPrinter(testContent, device).catch((err) => Sentry.captureException(err, {
      tags: { section: "devices/printers", action: "print" },
      extra: { device },
    }));
  };

  return (
    <DeviceContainer sm={16}>
      <DeviceHeaderContainer>
        <DeviceHeader>{device.name}</DeviceHeader>
        <Button onClick={handleTestPrint}>
          {t("devices.SelectedDevice.PrintTest")}
        </Button>
      </DeviceHeaderContainer>
      <Subtext>
        {device.type === "printer-escpos"
          ? t("devices.SelectedDevice.ConnectedVia", { connInfo: `LAN (${device.ip})` })
          : t("devices.SelectedDevice.SystemPrinter")}
      </Subtext>
      <DeviceTabs
        device={device}
        onDeviceChange={(changes) => onDeviceChange(device.id, changes)}
      />
    </DeviceContainer>
  );
};

PrinterSettings.propTypes = {
  device: PropTypes.object,
  onDeviceChange: PropTypes.func,
  t: PropTypes.func,
};

export default PrinterSettings;
