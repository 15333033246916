import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import PropTypes from "prop-types";

const Container = styled.div`
  display: flex;
`;

const CountBlock = styled.div`
  width: 30px;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const Counter = ({ increment, decrement, value, ...rest }) => (
  <Container {...rest}>
    <Button onClick={decrement}>-</Button>
    <CountBlock>
      <span>{value}</span>
    </CountBlock>
    <Button onClick={increment}>+</Button>
  </Container>
);

Counter.propTypes = {
  increment: PropTypes.func.isRequired,
  decrement: PropTypes.func.isRequired,
  index: PropTypes.number,
  value: PropTypes.number.isRequired,
};

export default Counter;
