import React from "react";
import { Button } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";

import i18n from "i18n";
import ConnectData from "containers/connect.container";
import { ORDER_STATUS } from "constants/index";

const CourierButton = styled(Button)`
  width: 170px;
  font-size: 15px;
  font-weight: 600;
  white-space: normal;
  line-height: 1;
  padding: 3px 10px;
  overflow: hidden;

  &.ant-btn {
    border-color: #000000a6;
    color: #000000a6;
  }
`;

const CourierColumn = ({ order, onAssignCourier }) => {
  const { couriers } = ConnectData.useContainer();

  const handleCourierButtonClick = (e) => {
    e.stopPropagation();
    onAssignCourier();
  };

  if (order.type !== "delivery") {
    return <div style={{ textAlign: "center" }}>-</div>;
  }

  const courier = order.courier_id && couriers.find((c) => c.id === order.courier_id);

  return (
    <CourierButton
      onClick={handleCourierButtonClick}
      type="dashed"
      ghost
      size="large"
      disabled={order.status === ORDER_STATUS.SERVED}
    >
      {courier
        ? courier.name
        : i18n.t("deliveryOrders.CourierColumn.AssignCourier")}
    </CourierButton>
  );
};

CourierColumn.propTypes = {
  order: PropTypes.object.isRequired,
  onAssignCourier: PropTypes.func.isRequired,
};

export default CourierColumn;
