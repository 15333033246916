import { useEffect, useState } from "react";
import { setItem, getItem, removeItem, getKeys, webLocalStorage } from "./crossPlatformLocalStorage";

const ON_LOCAL_STORAGE_CHANGE = "on-local-storage-change";

const isNative = window.electron || window.cordova;

const writeStorage = (key, value) =>
  setItem(key, value).then(() => {
    if (isNative) webLocalStorage.setItem(key, value); // Keep value synced
    document.dispatchEvent(new CustomEvent(ON_LOCAL_STORAGE_CHANGE, {
      detail: { key, value },
    }));
  });

const deleteFromStorage = (key) =>
  removeItem(key).then(() => {
    if (isNative) webLocalStorage.removeItem(key); // Keep value synced
    document.dispatchEvent(new CustomEvent(ON_LOCAL_STORAGE_CHANGE, {
      detail: { key, value: null },
    }));
  });

const syncStorage = async () => { // Synchronize native storage with web local storage
  if (!isNative) return true;
  return getKeys().then((keys) => Promise.all(
    keys.map((key) => getItem(key)
      .then((value) => value && webLocalStorage.setItem(key, value))),
  ));
};

const useLocalStorage = (key, defaultValue) => {
  const [localState, setLocalState] = useState(webLocalStorage.getItem(key) ?? defaultValue);

  useEffect(() => {
    const onLocalStorageChange = (event) => {
      if (event.detail.key === key) {
        setLocalState(event.detail.value);
      }
    };
    document.addEventListener(ON_LOCAL_STORAGE_CHANGE, onLocalStorageChange);
    return () => document.removeEventListener(ON_LOCAL_STORAGE_CHANGE, onLocalStorageChange);
  }, []);

  return [localState, (value) => writeStorage(key, value)];
};

export { useLocalStorage, writeStorage, deleteFromStorage, syncStorage };
