import { Model } from "@nozbe/watermelondb";
import { field, readonly, date, relation, json } from "@nozbe/watermelondb/decorators";

export default class OrderPayment extends Model {
  static table = "order_payments";

  static associations = {
    orders: { type: "belongs_to", key: "order_id" },
    cashier_shifts: { type: "belongs_to", key: "shift_id" },
  };

  @field("amount") amount;
  @field("type") type;
  @field("payment_method_id") payment_method_id;
  @json("metadata", (v) => v) metadata;
  @field("user_id") user_id;
  @field("terminal_id") terminal_id;
  @readonly @date("created_at") created_at;

  @relation("orders", "order_id") order;
  @relation("cashier_shifts", "shift_id") shift;
}
