import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Switch } from "antd";

import ConnectData from "containers/connect.container";
import colors from "theme/colors";

const SwitchContainer = styled.div`
  height: 431px;
  overflow-y: scroll;
  padding-right: 15px;
  padding-left: 15px;
`;

const SettingRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  padding-left: ${(props) => (props.$offset ? "20px" : "0px")};
`;

const SettingText = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 18px;
  transition: all 0.36s;
  align-items: center;
  color: ${({ disabled }) => (disabled ? colors.lightGrey : colors.black)};
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${colors.lightGrey};
  margin-left: ${(props) => (props.$offset ? "20px" : "0px")};
`;

const DeviceStations = ({ stations, onStationChange }) => {
  const { t } = useTranslation();
  const { workshops } = ConnectData.useContainer();

  return (
    <SwitchContainer>
      <SettingRow
        key="distribution"
        onClick={() => onStationChange("distribution", !stations.distribution)}
      >
        <SettingText>
          {t("devices.SelectedDevice.Stations.Distribution")}
        </SettingText>
        <Switch checked={stations.distribution} />
      </SettingRow>
      <Divider />
      <SettingRow
        key="kitchen"
        onClick={() => onStationChange("kitchen", !stations.kitchen)}
      >
        <SettingText>
          {t("devices.SelectedDevice.Stations.Kitchen")}
        </SettingText>
        <Switch checked={stations.kitchen} />
      </SettingRow>
      <Divider />
      {stations.kitchen && (
        workshops.map((workshop, index, arr) => (
          <React.Fragment key={workshop.id}>
            <SettingRow
              onClick={() => onStationChange(workshop.id, !stations[workshop.id])}
              $offset
            >
              <SettingText>
                {workshop.name}
              </SettingText>
              <Switch checked={stations[workshop.id]} />
            </SettingRow>
            {index + 1 !== arr.length && <Divider $offset />}
          </React.Fragment>
        ))
      )}
    </SwitchContainer>
  );
};

DeviceStations.propTypes = {
  stations: PropTypes.object,
  onStationChange: PropTypes.func,
};

export default DeviceStations;
