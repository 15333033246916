import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, Row, Col } from "antd";
import { useTranslation } from "react-i18next";

import ConnectData from "containers/connect.container";

const AssignCourierModal = ({ order, onSelectCourier, onClose }) => {
  const { t } = useTranslation();
  const { couriers } = ConnectData.useContainer();

  return (
    <Modal
      width="80%"
      visible
      closable={false}
      transitionName="none"
      maskTransitionName="none"
      onCancel={onClose}
      footer={(
        <Button size="large" onClick={onClose}>{t("Cancel")}</Button>
      )}
    >
      <Row gutter={[16, 16]}>
        {couriers.map((courier) => (
          <Col key={courier.id} xs={12} sm={8} md={6} lg={4}>
            <Button
              block
              style={{ height: 80, fontSize: 17, whiteSpace: "normal" }}
              type={courier.id === order.courier_id ? "primary" : "default"}
              onClick={() => onSelectCourier(courier)}
            >
              {courier.name}
            </Button>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};

AssignCourierModal.propTypes = {
  order: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectCourier: PropTypes.func.isRequired,
};

export default AssignCourierModal;
