import React, { useEffect, useRef, useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import ScrollButton from "./components/ScrollButton";

const ScrollableContainer = ({ children, deps = [] }) => {
  const containerRef = useRef();
  const [upButtonDisabled, setUpButtonDisabled] = useState(true);
  const [downButtonDisabled, setDownButtonDisabled] = useState(true);

  const syncScrollButtonsVisibility = () => {
    const currentPosition = Math.ceil(containerRef.current.scrollTop);
    const maxScrollTop = containerRef.current.scrollHeight - containerRef.current.offsetHeight;
    setUpButtonDisabled(currentPosition === 0);
    setDownButtonDisabled(currentPosition >= maxScrollTop);
  };

  useEffect(() => {
    containerRef.current.onscroll = syncScrollButtonsVisibility;
  }, []);

  useEffect(() => {
    syncScrollButtonsVisibility();
  }, deps);

  const verticalScroll = (direction) => {
    const distance = containerRef.current.offsetHeight * 0.65;
    // eslint-disable-next-line no-param-reassign
    containerRef.current.scrollTop += distance * (direction === "up" ? -1 : 1);
  };

  const UpButton = (props) => (
    <ScrollButton
      {...props}
      onClick={() => verticalScroll("up")}
      disabled={upButtonDisabled}
    >
      <UpOutlined style={{ fontSize: 24 }} />
    </ScrollButton>
  );

  const DownButton = (props) => (
    <ScrollButton
      {...props}
      onClick={() => verticalScroll("down")}
      disabled={downButtonDisabled}
    >
      <DownOutlined style={{ fontSize: 24 }} />
    </ScrollButton>
  );

  return children({
    containerRef,
    showScrollButtons: !window.cordova,
    UpButton,
    DownButton,
    syncScrollButtonsVisibility,
  });
};

ScrollableContainer.propTypes = {
  children: PropTypes.func,
  deps: PropTypes.array,
};

export default ScrollableContainer;
