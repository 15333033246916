import { printOptionsSingleton } from "./print";

const LocalForCurrency = {
  USD: "en-US",
  EUR: "en-US",
  RUB: "ru-RU",
  TRY: "tr-TR",
};

const currencySingleton = {
  priceFormatter: null,
  currency: null,
  getPriceFormatter() {
    return this.priceFormatter;
  },
  getCurrency() {
    return this.currency;
  },
  setCurrency(value) {
    if (value === "KZT") {
      const numberFormatter = new Intl.NumberFormat({}, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      this.priceFormatter = {
        format: (price) => `${numberFormatter.format(price)} тг.`,
      };
      this.currency = "тг.";
    } else {
      this.priceFormatter = new Intl.NumberFormat(LocalForCurrency[value] || {}, {
        style: "currency",
        currencyDisplay: "symbol",
        currency: value,
      });
      this.currency = this.priceFormatter
        .formatToParts().find((part) => part.type === "currency").value;
    }

    printOptionsSingleton.setCurrency(this.currency);
  },
};

export const formatPrice = (price = 0) => currencySingleton.getPriceFormatter().format(price);

export default currencySingleton;
