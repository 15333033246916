import React from "react";
import { Row, Col, Typography } from "antd";
import PropTypes from "prop-types";

const OrderListHeader = ({ t }) => (
  <Row
    justify="space-between"
    align="middle"
    style={{ padding: "0px 8px 8px" }}
  >
    <Col order={1} flex="55px" style={{ textAlign: "center" }}>
      <Typography.Text strong style={{ margin: 0 }}>#</Typography.Text>
    </Col>
    <Col
      xs={{ order: 3, span: 4 }}
      md={5}
      lg={{ order: 2, span: 3 }}
      style={{ textAlign: "center", overflow: "hidden" }}
    >
      <Typography.Text strong ellipsis style={{ margin: 0 }}>
        {t("deliveryOrders.Columns.StartOrder")}
      </Typography.Text>
    </Col>
    <Col xs={{ order: 2 }} lg={{ order: 3 }} flex="210px" style={{ textAlign: "center" }}>
      <Typography.Text strong style={{ margin: 0 }}>
        {t("deliveryOrders.Columns.Status")}
      </Typography.Text>
    </Col>
    <Col order={4} xs={3} sm={5} lg={2} style={{ textAlign: "center" }}>
      <Typography.Text strong style={{ margin: 0 }}>
        {t("deliveryOrders.Columns.Amount")}
      </Typography.Text>
    </Col>
    <Col order={5} xs={5} md={7} lg={3}>
      <Typography.Text strong style={{ margin: 0 }}>
        {t("deliveryOrders.Columns.Payment")}
      </Typography.Text>
    </Col>
    <Col xs={{ order: 3, span: 5 }} sm={7} md={8} lg={{ order: 6, span: 3 }}>
      <Typography.Text strong style={{ margin: 0 }}>
        {t("deliveryOrders.Columns.Address")}
      </Typography.Text>
    </Col>
    <Col order={7} flex="170px" style={{ textAlign: "center" }}>
      <Typography.Text strong style={{ margin: 0 }}>
        {t("deliveryOrders.Columns.Courier")}
      </Typography.Text>
    </Col>
    <Col order={8} flex="122px" />
  </Row>
);

OrderListHeader.propTypes = {
  t: PropTypes.func,
};

export default OrderListHeader;
