import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const NumberColumn = ({ number }) => <Container>{number}</Container>;

NumberColumn.propTypes = {
  number: PropTypes.string.isRequired,
};

export default NumberColumn;
