import { ApiOutlined, CreditCardOutlined, PhoneOutlined, UserOutlined, WalletOutlined } from "@ant-design/icons";
import { PAYMENT_METHOD_TYPE } from "constants/index";

export const PAYMENT_TYPE_ICONS = {
  [PAYMENT_METHOD_TYPE.CASH]: WalletOutlined,
  [PAYMENT_METHOD_TYPE.CARD]: CreditCardOutlined,
  [PAYMENT_METHOD_TYPE.CUSTOM]: ApiOutlined,
};

export const ORDER_SOURCE_ICONS = {
  callcenter: PhoneOutlined,
  pos: UserOutlined,
  api: ApiOutlined,
};
