import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";

const ContainerStyles = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  width: "100%",
};

const DefaultButtonStyles = {
  fontSize: 24,
  backgroundColor: "#fff",
  borderStyle: "none",
  cursor: "pointer",
  border: "1px solid white",
  borderRadius: 5,
};

const numberSymbols = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
const BACKSPACE = "backspace";
const POINTER = ".";

const Numpad = ({
  onChange,
  onConditions,
  onConditionsBackspace,
  value = "",
  delimiter = true,
  backspace = true,
  isDelete = false,
  styleButton = { width: 75, height: 75 },
}) => {
  const [number, setNumber] = useState("");

  useMemo(() => {
    if (value !== number) {
      setNumber(`${value}`);
    }
  }, [value]);

  useEffect(() => {
    if (onChange) onChange(number);
  }, [number]);

  const handleClick = (symbol) => {
    if (onConditions && onConditions(number)) return;

    setNumber((previousNumber) => {
      if (symbol === "POINTER") {
        return previousNumber.indexOf(POINTER) !== -1
          ? previousNumber
          : `${previousNumber}${symbol}`;
      }
      return `${previousNumber}${symbol}`;
    });
  };

  const handleBackspace = () => {
    if (onConditionsBackspace && onConditionsBackspace()) return;

    setNumber((previousNumber) =>
      (previousNumber.length > 1 ? previousNumber.slice(0, -1) : ""));
  };

  const containerWidth = styleButton.width * 3 + 15;
  const containerHeight = styleButton.height * 4 + 20;
  return (
    <div style={{
      ...ContainerStyles, height: containerHeight, maxWidth: containerWidth,
    }}
    >
      {numberSymbols.map((symbol) => (
        <button
          type="button"
          style={{ ...DefaultButtonStyles, ...styleButton }}
          onClick={() => handleClick(symbol)}
          key={symbol}
        >
          {symbol}
        </button>
      ))}
      {delimiter && (
        <button
          type="button"
          style={{ ...DefaultButtonStyles, ...styleButton }}
          onClick={() => handleClick(POINTER)}
        >
          .
        </button>
      )}
      {isDelete && (
        <Button
          style={{ width: 75, height: 75, border: "white" }}
          onClick={() => setNumber("")}
        >
          <CloseOutlined style={{ fontSize: 22 }} />
        </Button>
      )}
      {!delimiter && !isDelete && (
        <div style={{ height: styleButton.height, width: styleButton.width }} />
      )}
      <button
        type="button"
        style={{ ...DefaultButtonStyles, ...styleButton }}
        onClick={() => handleClick(0)}
      >
        0
      </button>
      {backspace ? (
        <Button
          style={{ width: 75, height: 75, border: "white" }}
          onClick={handleBackspace}
        >
          <ArrowLeftOutlined style={{ fontSize: 22 }} />
        </Button>
      ) : (
        <div style={{ height: styleButton.height, width: styleButton.width }} />
      )}
      <KeyboardEventHandler
        handleFocusableElements
        handleKeys={["numeric", POINTER, BACKSPACE]}
        onKeyEvent={(key) => {
          if (POINTER === key && !delimiter) {
            return;
          }

          if (key === BACKSPACE && backspace) {
            handleBackspace();
          } else {
            handleClick(key);
          }
        }}
      />
    </div>
  );
};

Numpad.propTypes = {
  value: PropTypes.string,
  delimiter: PropTypes.bool,
  backspace: PropTypes.bool,
  isDelete: PropTypes.bool,
  styleButton: PropTypes.object,
  onChange: PropTypes.func,
  onConditions: PropTypes.func,
  onConditionsBackspace: PropTypes.func,
};

export default Numpad;
