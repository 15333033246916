import { Model } from "@nozbe/watermelondb";
import { field, readonly, date, relation } from "@nozbe/watermelondb/decorators";

export default class OrderLineCancel extends Model {
  static table = "order_line_cancels";

  static associations = {
    order_lines: { type: "belongs_to", key: "order_line_id" },
  };

  @field("reason") reason;
  @field("refund") refund;
  @field("write_off") write_off;
  @field("user_id") user_id;
  @readonly @date("created_at") created_at;

  @relation("order_lines", "order_line_id") order_line;
}
