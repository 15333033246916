import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { ORDER_TYPE } from "constants/index";

import CurrentUser from "containers/current-user";
import Orders from "containers/orders.container";
import OrdersList from "components/OrdersList";

import OrderCard from "./components/OrderCard";
import OrderListHeader from "./components/OrderListHeader";

const OpenOrders = ({ history }) => {
  const { t } = useTranslation();
  const { user } = CurrentUser.useContainer();
  const { openOrders, userStats } = Orders.useContainer();

  const currentUserOrders = user ? openOrders.filter(
    (order) => order.type === ORDER_TYPE.IN_STORE && (user.isAdmin || order.user_id === user.id),
  ) : [];

  const renderOrderCard = (
    order, setOrderToPay, setOrderToCancel, syncScrollButtons, isLast,
  ) => (
    <OrderCard
      t={t}
      key={order.id}
      order={order}
      onPay={setOrderToPay}
      onCancel={setOrderToCancel}
      onExpand={syncScrollButtons}
      isLast={isLast}
    />
  );

  return (
    <OrdersList
      history={history}
      orders={currentUserOrders}
      stats={userStats}
      renderOrderCard={renderOrderCard}
      listHeader={<OrderListHeader t={t} />}
    />
  );
};

OpenOrders.propTypes = {
  history: PropTypes.object,
};

export default OpenOrders;
