import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import colors from "theme/colors";
import PropTypes from "prop-types";
import { lighten } from "polished";
import { UpOutlined, DownOutlined } from "@ant-design/icons";

import { formatPrice } from "utils/currency";

import TimerColumn from "components/OrdersList/components/TimerColumn";
import NumberColumn from "components/OrdersList/components/NumberColumn";
import ActionsColumn from "components/OrdersList/components/ActionsColumn";
import StatusWithTimer from "components/OrdersList/components/StatusWithTimer";
import OrderDetailsPanel from "components/OrdersList/components/OrderDetailsPanel";
import PaymentColumn from "components/OrdersList/components/PaymentColumn";

import CourierColumn from "./CourierColumn";

const OrderCard = ({
  order, onPay, onCancel, onAssignCourier, onExpand, t, isLast,
}) => {
  const [expanded, setExpanded] = useState();
  const { street, house, flat } = order.delivery_info?.address || {};

  useEffect(() => {
    onExpand();
  }, [expanded]);

  const ExpandIcon = expanded ? UpOutlined : DownOutlined;

  return (
    <Row
      justify="space-between"
      align="middle"
      gutter={[0, 8]}
      style={{
        padding: 8,
        marginBottom: isLast ? 0 : 5,
        borderRadius: 5,
        cursor: "pointer",
        background: colors.statusColors[order.status],
      }}
      onClick={() => setExpanded(!expanded)}
    >
      <Col order={1} flex="55px" style={{ textAlign: "center" }}>
        <NumberColumn number={order.display_number} />
      </Col>
      <Col xs={{ order: 3, span: 4 }} md={5} lg={{ order: 2, span: 3 }} style={{ textAlign: "center" }}>
        <TimerColumn createdAt={order.time} />
        <i>{order.notes}</i>
      </Col>
      <Col xs={{ order: 2 }} lg={{ order: 3 }}>
        <StatusWithTimer openOrder={order} />
      </Col>
      <Col order={4} xs={3} sm={5} lg={2} style={{ textAlign: "center" }}>
        {formatPrice(order.list_price)}
      </Col>
      <Col order={5} xs={5} md={7} lg={3}>
        <PaymentColumn order={order} />
      </Col>
      <Col xs={{ order: 3, span: 5 }} sm={7} md={8} lg={{ order: 6, span: 3 }}>
        {order.type === "pickup"
          ? t("deliveryOrders.Columns.Pickup")
          : [street, house, flat].filter((el) => el).join(", ")}
      </Col>
      <Col order={7} flex="170px">
        <CourierColumn order={order} onAssignCourier={() => onAssignCourier(order)} />
      </Col>
      <Col order={8} style={{ alignSelf: "stretch" }}>
        <Row gutter={10} style={{ height: "100%" }}>
          <Col style={{ alignSelf: "center" }}>
            <ActionsColumn order={order} onClickPay={() => onPay(order)} />
          </Col>
          <Col style={{ alignSelf: "flex-end" }}>
            <ExpandIcon style={{ fontSize: 24, opacity: 0.3, marginRight: 2 }} />
          </Col>
        </Row>
      </Col>
      {expanded && (
        <Col span={24} order={10}>
          <OrderDetailsPanel
            style={{
              background: lighten(0.07, colors.statusColors[order.status]),
              borderRadius: 5,
              padding: 10,
              cursor: "default",
            }}
            order={order}
            onCancelOrder={onCancel}
            t={t}
          />
        </Col>
      )}
    </Row>
  );
};

OrderCard.propTypes = {
  order: PropTypes.object,
  onPay: PropTypes.func,
  onAssignCourier: PropTypes.func,
  onCancel: PropTypes.func,
  onExpand: PropTypes.func,
  t: PropTypes.func,
  isLast: PropTypes.bool,
};

export default OrderCard;
