import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import colors from "theme/colors";

const Background = styled.div`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.5);
  z-index: 1000;
  position: absolute;
`;

const Panel = styled.div`
  background: ${colors.background};
  z-index: 3;
  height: 80%;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
`;

const Block = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 180px;
  height: 90px;
  margin-right: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  text-align: center;
  font-weight: bold;
  background-color: ${(props) => (props.color || colors.white)};
  color: ${(props) => props.color && colors.white};
`;

const ContainerFlexbox = styled.div`
  display: flex;
  margin-bottom: 5px;
  flex-wrap: wrap;
  justify-content: ${(props) => props.justify || "flex-start"};
`;

const CancelBlock = styled.button`
  cursor: pointer;
  text-align: center;
  width: 100px;
  height: 70px;
  border: 1px solid red;
  border-radius: 5px;
  text-align: center;
  background-color: red;
  color: ${colors.white};
`;

const PaymentMethodsModal = ({ paymentMethods, onSetCustomPaymentMethod, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Background onClick={(e) => e.stopPropagation()}>
      <Panel>
        <ContainerFlexbox>
          {paymentMethods.map((pm) => (
            <Block
              key={pm.id}
              onClick={() => onSetCustomPaymentMethod(pm)}
            >
              {pm.name}
            </Block>
          ))}
        </ContainerFlexbox>
        <ContainerFlexbox justify="flex-end">
          <CancelBlock
            onClick={onCancel}
          >
            {t("Close")}
          </CancelBlock>
        </ContainerFlexbox>
      </Panel>
    </Background>
  );
};

PaymentMethodsModal.propTypes = {
  paymentMethods: PropTypes.array.isRequired,
  onSetCustomPaymentMethod: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default PaymentMethodsModal;
