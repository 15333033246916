import { ApiOutlined, ReloadOutlined, SettingOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { useService } from "pos-service";

import colors from "theme/colors";
import useSocketClients from "utils/useSocketClients";
import { getPlatform } from "utils/helpers";
import useDevices from "utils/useDevices";
import ConnectData from "containers/connect.container";

import PrinterSettings from "./components/PrinterSettings";
import KDSSettings from "./components/KDSSettings";
import POSSettings from "./components/POSSettings";

const CenteredDiv = styled.div`
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .center-icon {
    svg {
      font-size: 100px;
      margin-bottom: 30px;
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .download-button {
    margin-top: 10px
  }
`;

const SideBar = styled(Col)`
  background-color: ${colors.whisper};
  height: 550px;
  border-bottom-left-radius: 5px;
  border-width: 0px 1px 0px 0px;
  border-color: ${colors.lightGrey};
  border-style: solid;
  overflow-y: scroll;
`;

const SectionTitle = styled.h4`
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 3px;
  margin-bottom: 0px;
  border-width: 0px 0px 1px 0px;
  border-color: ${colors.lightGrey};
  border-style: solid;
`;

const Loader = styled(Spin)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const SectionDevice = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-width: 0px 0px 1px 0px;
  border-color: ${colors.lightGrey};
  border-style: solid;
  cursor: pointer;
  font-size: 16px;
  justify-content: space-between;
  flex-direction: row;
  ${(({ selected }) => (selected ? css`
    background-color: ${colors.dodgerBlue};
    color: ${colors.white};
  ` : ""))}
`;

const RefreshContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 15px;
  align-items: center;
  cursor: pointer;
`;

const RefreshButton = styled.span`
  cursor: pointer;
  color: ${colors.dodgerBlue};
  font-size: 16px;
  margin-left: 5px;
`;

const ErrorBlock = styled.div`
  text-align: center;
  margin: 10px 10px 0px;
`;

const Refresh = ({ onRefresh, t }) => (
  <RefreshContainer onClick={onRefresh}>
    <ReloadOutlined style={{ color: colors.dodgerBlue }} />
    <RefreshButton>{t("devices.DeviceList.Refresh")}</RefreshButton>
  </RefreshContainer>
);

Refresh.propTypes = {
  onRefresh: PropTypes.func,
  t: PropTypes.func,
};

const isConfigured = (device) => {
  if (device.type.startsWith("pos")) {
    return Object.values(device.settings || {}).some((enabled) => enabled);
  }
  if (!device.stations) return false;
  const { distribution, kitchen, products, receipt, ...workshops } = device.stations;
  const workshopsEnabled = Object.values(workshops).some((enabled) => enabled);
  switch (device.type) {
    case "kds":
      return distribution || (kitchen && workshopsEnabled);
    case "printer-escpos":
    case "printer-system":
      return receipt
        || (kitchen && (products || workshopsEnabled));
    default:
      return false;
  }
};

const isKDSConnected = (device, clients) => clients.some((id) => `kds-${id}` === device.id);

const Devices = () => {
  const clients = useSocketClients();
  const { service } = useService();
  const { t } = useTranslation();
  const [selectedDeviceId, setSelectedDeviceId] = useState();
  const { terminal } = ConnectData.useContainer();
  const {
    devices, loading, refetch, error, updateDevice, deleteDevice,
  } = useDevices({
    skipRecognizeKDS: !terminal.main,
  });

  const forgetDevice = (id) => {
    deleteDevice(id);
    setSelectedDeviceId(null);
  };

  useEffect(() => {
    if (devices?.length > 0 && !selectedDeviceId) setSelectedDeviceId(devices[0].id);
  }, [devices]);

  if (!window.electron && !window.cordova) {
    return (
      <CenteredDiv>
        <ApiOutlined className="center-icon" />
        <h2>{t("devices.DevicesUnavailable.Title")}</h2>
        <p>{t("devices.DevicesUnavailable.DownloadApp", { platform: getPlatform() || "Mac/Windows/Android" })}</p>
        <Button href="https://desktop.salempos.com" target="blank" className="download-button" type="primary">
          {t("devices.DevicesUnavailable.DownloadAppButton")}
        </Button>
      </CenteredDiv>
    );
  }

  const selectedDevice = devices?.find((device) => device.id === selectedDeviceId);

  return (
    <Row>
      <SideBar sm={8}>
        <SectionTitle>{t("devices.DeviceList.Printers")}</SectionTitle>
        {devices?.filter((device) => device.type.startsWith("printer"))
          .map((printer) => (
            <SectionDevice
              key={printer.id}
              onClick={() => setSelectedDeviceId(printer.id)}
              selected={printer.id === selectedDeviceId}
            >
              {printer.name}
              {isConfigured(printer) && (
                <SettingOutlined
                  style={{
                    fontSize: 14,
                    color: printer.id === selectedDeviceId
                      ? colors.white : colors.disabled,
                  }}
                />
              )}
            </SectionDevice>
          ))}
        <SectionTitle>{t("devices.DeviceList.POSTerminals")}</SectionTitle>
        {devices?.filter((device) => device.type.startsWith("pos"))
          .map((pos) => (
            <SectionDevice
              key={pos.id}
              onClick={() => setSelectedDeviceId(pos.id)}
              selected={pos.id === selectedDeviceId}
            >
              {pos.name}
              {isConfigured(pos) && (
                <SettingOutlined
                  style={{
                    fontSize: 14,
                    color: pos.id === selectedDeviceId
                      ? colors.white : colors.disabled,
                  }}
                />
              )}
            </SectionDevice>
          ))}
        {terminal.main && (
          <>
            <SectionTitle>{t("devices.DeviceList.KDS")}</SectionTitle>
            {devices?.filter((device) => device.type === "kds")
              .map((kds) => (
                <SectionDevice
                  key={kds.id}
                  onClick={() => setSelectedDeviceId(kds.id)}
                  selected={kds.id === selectedDeviceId}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ marginRight: 7 }}>
                      {kds.name}
                    </span>
                    <Badge offset={[1, 1]} status={isKDSConnected(kds, clients) ? "success" : "default"} />
                  </div>
                  {isConfigured(kds) && (
                    <SettingOutlined
                      style={{
                        fontSize: 14,
                        color: kds.id === selectedDeviceId
                          ? colors.white : colors.disabled,
                      }}
                    />
                  )}
                </SectionDevice>
              ))}
          </>
        )}
        {error && <ErrorBlock>{t("devices.DeviceList.SearchError", { message: error.message })}</ErrorBlock>}
        {loading ? (
          <div style={{ textAlign: "center", color: colors.grey, lineHeight: 1.2 }}>
            <Loader style={{ marginBottom: 10 }} />
            <span>{t("devices.DeviceList.Searching")}</span>
          </div>
        ) : <Refresh onRefresh={refetch} t={t} />}
      </SideBar>
      {selectedDevice?.type.startsWith("printer") && (
        <PrinterSettings
          forgetDevice={forgetDevice}
          device={selectedDevice}
          onDeviceChange={updateDevice}
          t={t}
        />
      )}
      {selectedDevice?.type === "kds" && (
        <KDSSettings
          forgetDevice={forgetDevice}
          isConnected={isKDSConnected(selectedDevice, clients)}
          device={selectedDevice}
          onDeviceChange={(id, changes) => updateDevice(id, changes)
            .then(() => {
              if (changes.settings) {
                service.notifyKDSSettingsUpdate(id);
              }
              if (changes.stations) {
                service.notifyKDSStationsUpdate(id);
              }
            })}
          t={t}
        />
      )}
      {selectedDevice?.type.startsWith("pos") && (
        <POSSettings
          forgetDevice={forgetDevice}
          device={selectedDevice}
          onDeviceChange={updateDevice}
          t={t}
        />
      )}
    </Row>
  );
};

export default Devices;
