import { useContext, useEffect } from "react";

import { KeyboardContext } from "./index";

const PREVENT_WINDOW_KEYBOARD = window.cordova;

const useKeyboard = ({ clear = true, initialValue, showInitially } = {}) => {
  const [
    keyboardState, setKeyboardVisibility, setKeyboardState, keyboardVisible,
  ] = useContext(KeyboardContext);

  const clearState = () => setKeyboardState("");

  useEffect(() => {
    if (initialValue) setKeyboardState(initialValue);
    else if (clear) clearState();
    if (showInitially) setKeyboardVisibility(showInitially);
    return () => {
      setKeyboardVisibility(false);
      clearState();
    };
  }, []);

  const preventInputBlur = (e) => {
    // relatedTarget exists only when focusable element clicked,
    // that`s why we set tabIndex property on Layout component in AppLayout.js
    const { relatedTarget } = e.nativeEvent;
    if (relatedTarget) {
      setKeyboardVisibility(false);
    } else {
      e.target.focus();
    }
  };

  return {
    state: keyboardState,
    show: () => !PREVENT_WINDOW_KEYBOARD && setKeyboardVisibility(true),
    hide: () => setKeyboardVisibility(false),
    setState: setKeyboardState,
    clearState,
    onBlur: preventInputBlur,
    visible: keyboardVisible,
  };
};

export default useKeyboard;
