import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Timer from "./Timer";

const TimerContainer = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const TimerColumn = ({ createdAt }) => (
  <TimerContainer>
    <Timer
      start={createdAt}
      textColor={(duration) => (duration >= 15 * 60 ? "red" : null)}
    />
  </TimerContainer>
);

TimerColumn.propTypes = {
  createdAt: PropTypes.instanceOf(Date),
};

export default TimerColumn;
