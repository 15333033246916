import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useShiftManager } from "components/ShiftManager";
import colors from "theme/colors";

import ConnectData from "../../containers/connect.container";

const ShiftMessageContainer = styled.div`
  height: calc(100vh - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShiftClosedMessage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${colors.surface};
  border-radius: 15px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  line-height: 1.3;
`;

const ShiftClosed = () => {
  const { t } = useTranslation();
  const shiftManager = useShiftManager();
  const { terminal } = ConnectData.useContainer();

  return (
    <ShiftMessageContainer>
      <ShiftClosedMessage>
        <span style={{ fontSize: 24, marginBottom: 8 }}>
          {t("shiftClosed.Title")}
        </span>
        {terminal.main ? (
          <>
            <span style={{ fontSize: 18, marginBottom: 10 }}>
              {t("shiftClosed.Subtext")}
            </span>
            <Button type="primary" size="large" onClick={shiftManager.show}>
              {t("shiftClosed.OpenShift")}
            </Button>
          </>
        ) : (
          <span style={{ fontSize: 18 }}>
            {t("shiftClosed.CantOpenShift")}
          </span>
        )}
      </ShiftClosedMessage>
    </ShiftMessageContainer>
  );
};

export default ShiftClosed;
