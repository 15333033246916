import React, { useState } from "react";
import { Col, List, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useObservable } from "rxjs-hooks";
import { map } from "rxjs/operators";

import { useService } from "pos-service";
import colors from "theme/colors";

import ScrollableContainer from "components/ScrollableContainer";
import OrderCard from "./components/OrderCard";
import OrderDetails from "./components/OrderDetails";

const Archive = () => {
  const { t } = useTranslation();
  const { service } = useService();
  const [selectedOrder, setSelectedOrder] = useState();
  const orders = useObservable(() => service.shiftOrders$().pipe(
    map((arr) => arr.sort((lhs, rhs) => lhs.created_at - rhs.created_at)),
  ), []);

  return (
    <Row>
      <Col xl={6} lg={8} sm={10} xs={4} style={{ backgroundColor: colors.whiteSmoke }}>
        <ScrollableContainer deps={[orders]}>
          {({ containerRef, showScrollButtons, UpButton, DownButton }) => (
            <>
              <div
                className="no-scroll-bar"
                style={{
                  overflowY: "scroll",
                  height: showScrollButtons ? "calc(100vh - 133px)" : "calc(100vh - 67px)",
                }}
                ref={containerRef}
              >
                <List
                  split
                  rowKey="id"
                  itemLayout="vertical"
                  dataSource={orders}
                  loading={!orders}
                  renderItem={(order) => (
                    <OrderCard
                      t={t}
                      order={order}
                      isActive={selectedOrder?.id === order.id}
                      onSelect={setSelectedOrder}
                    />
                  )}
                />
              </div>
              {showScrollButtons && (
                <Row gutter={8} style={{ margin: "8px 5px" }}>
                  <Col span={12}>
                    <UpButton />
                  </Col>
                  <Col span={12}>
                    <DownButton />
                  </Col>
                </Row>
              )}
            </>
          )}
        </ScrollableContainer>
      </Col>
      <Col xl={18} lg={16} sm={14} xs={20}>
        <ScrollableContainer deps={[selectedOrder]}>
          {({ containerRef, showScrollButtons, UpButton, DownButton }) => (
            <>
              <div
                className="no-scroll-bar"
                style={{
                  overflowY: "scroll",
                  height: showScrollButtons ? "calc(100vh - 133px)" : "calc(100vh - 67px)",
                }}
                ref={containerRef}
              >
                {selectedOrder && <OrderDetails t={t} order={selectedOrder} />}
              </div>
              {showScrollButtons && (
                <Row gutter={8} style={{ margin: "8px 5px" }}>
                  <Col xs={12} sm={5}>
                    <UpButton />
                  </Col>
                  <Col xs={12} sm={5}>
                    <DownButton />
                  </Col>
                </Row>
              )}
            </>
          )}
        </ScrollableContainer>
      </Col>
    </Row>
  );
};

export default Archive;
