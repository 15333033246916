import React from "react";
import PropTypes from "prop-types";
import { Button, Col, Row } from "antd";

import { ORDER_TYPE_LABELS, ORDER_TYPE } from "constants/index";

import ConnectData from "containers/connect.container";
import colors from "theme/colors";

import CurrentOrder from "../../../../containers/currentOrder.container";
import ControllerDropdown from "./components/ControllerDropdown";
import DiscountPopover from "./components/DiscountPopover";

const ButtonsBlock = ({
  t,
  onResetOrder,
  onWithoutPayment,
  onPay,
  onShowTablePanel,
  style,
  ...props
}) => {
  const { tables } = ConnectData.useContainer();
  const currentOrder = CurrentOrder.useContainer();

  const tableName = currentOrder.tableId
    ? tables.find((table) => table.id === currentOrder.tableId).name
    : ORDER_TYPE_LABELS[ORDER_TYPE.IN_STORE];

  return (
    <div
      style={{
        background: colors.surface,
        padding: 10,
        borderRadius: 8,
        ...style,
      }}
      {...props}
    >
      <Row gutter={[4, 4]}>
        <Col xs={14}>
          <Button
            block
            onClick={onShowTablePanel}
            size="large"
            disabled={currentOrder.orderType === ORDER_TYPE.DELIVERY}
          >
            {currentOrder.orderType === ORDER_TYPE.IN_STORE
              ? tableName : ORDER_TYPE_LABELS[currentOrder.orderType]}
          </Button>
        </Col>
        <Col xs={5}>
          <DiscountPopover t={t} />
        </Col>
        <Col xs={5}>
          <ControllerDropdown t={t} onResetOrder={onResetOrder} />
        </Col>
        <Col xs={9}>
          <Button
            size="large"
            onClick={() => onWithoutPayment()}
            disabled={currentOrder.cart.isEmpty}
            block
          >
            {t("cashier.Cashier.CartPanel.ButtonsBlock.WithoutPayment")}
          </Button>
        </Col>
        <Col xs={15}>
          <Button
            size="large"
            onClick={onPay}
            disabled={currentOrder.cart.isEmpty}
            type="primary"
            block
          >
            {t("cashier.Cashier.CartPanel.ButtonsBlock.Pay")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

ButtonsBlock.propTypes = {
  onShowTablePanel: PropTypes.func,
  onResetOrder: PropTypes.func,
  onWithoutPayment: PropTypes.func,
  onPay: PropTypes.func,
  t: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default ButtonsBlock;
