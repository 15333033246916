import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Col, Row } from "antd";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { CloseOutlined, MoreOutlined } from "@ant-design/icons";

import Numpad from "components/Numpad";
import colors from "theme/colors";
import { formatPrice } from "utils/currency";
import { PAYMENT_METHOD_TYPE } from "constants/index";
import { getSavedPOSTerminals } from "utils/devices";

import Coopers from "./Coopers";
import PaymentMethodsModal from "./PaymentMethodsModal";
import PrintSwitch from "./PrintSwitch";
import PanelPaymentMethod from "./PanelPaymentMethod";

const TextPrice = styled.p`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const TextChange = styled.p`
  font-size: 16px;
  height: 16px;
  font-weight: 600;
  text-align: right;
  padding: 0;
`;

const TopBlock = styled.div`
  height: 75vh;
  margin-bottom: 25px;
`;

const ButtonsBlock = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerNumpadAndCooper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const SelectPaymentButton = styled(Button)`
  margin-left: 5px;
  height: 50px;
  width: 50px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BlockAlign = styled.div`
  display: flex;
`;

const CustomPaymentMethods = styled(BlockAlign)`
  width: 100%;
`;

const CustomPaymentMethod = styled.button`
  line-height: 1;
  padding-top: 3.5px;
  height: 50px;
  width: 25%;
  display: block;
  font-size: 18px;
  font-weight: 700;
  border-radius: 5px;
  margin-left: ${(props) => (props.disableMargin ? "0" : "5px")};
  border: 1px solid white;
  background-color: ${colors.white};
  background-color:  ${(props) => (props.disabled ? colors.gainsboro : colors.white)};
  cursor: pointer;
  &&:hover {
    border: 1px solid ${(props) => (props.disabled ? "white" : colors.dodgerBlue)};
    color: 1px solid ${(props) => (props.disabled ? colors.dodgerBlue : colors.dark)};
  }
`;

const CloseButton = styled(CloseOutlined)`
  display: flex;
  font-size: 30px;
  cursor: pointer;
`;

const PaymentPanel = ({
  paymentAmount,
  isNewOrder,
  paymentMethods,
  onOrderPayment,
  t,
  onSetCustomPaymentMethod,
  printSettings,
  onPrintSettingsChange,
  onClose,
  location,
}) => {
  const [cash, setCash] = useState(0);
  const [card, setCard] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    PAYMENT_METHOD_TYPE.CASH,
  );
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);

  const hasCardPOS = useMemo(() =>
    card > 0 && getSavedPOSTerminals().find((d) => d.settings?.card), [card]);

  const cardPaymentMethod = paymentMethods.find((el) => el.type === PAYMENT_METHOD_TYPE.CARD);
  const cashPaymentMethod = paymentMethods.find((el) => el.type === PAYMENT_METHOD_TYPE.CASH);

  const customPaymentMethods = paymentMethods
    .filter((el) => el.type === PAYMENT_METHOD_TYPE.CUSTOM);

  const change = cash + card - paymentAmount;

  const handleChangeNumpad = (number) => {
    const currentNumber = number ? parseFloat(number) : 0;
    if (selectedPaymentMethod === PAYMENT_METHOD_TYPE.CASH) {
      setCash(currentNumber);
    }
    if (selectedPaymentMethod === PAYMENT_METHOD_TYPE.CARD) {
      setCard(currentNumber);
    }
  };

  const handleSelectCooper = (number) => {
    if (selectedPaymentMethod === PAYMENT_METHOD_TYPE.CASH) {
      setCash((previousCash) => previousCash + number);
    }
    if (selectedPaymentMethod === PAYMENT_METHOD_TYPE.CARD) {
      setCard((previousCard) => previousCard + number);
    }
  };

  const handleClear = (type) => {
    setSelectedPaymentMethod(type);
    if (type === PAYMENT_METHOD_TYPE.CASH) {
      setCash(0);
    }
    if (type === PAYMENT_METHOD_TYPE.CARD) {
      setCard(0);
    }
  };

  const handleSendPayment = () => {
    const payments = [];
    if (cash > 0) {
      payments.push({
        amount: change > 0 ? cash - change : cash,
        paymentMethodId: cashPaymentMethod.id,
      });
    }
    if (card > 0) {
      payments.push({
        amount: card,
        paymentMethodId: cardPaymentMethod.id,
      });
    }
    onOrderPayment(payments, hasCardPOS, {
      amount: card,
      paymentMethodId: cardPaymentMethod.id,
    });
  };

  return (
    <div>
      <TopBlock>
        <Row>
          <Col span={20}>
            <TextPrice>
              {t("cashier.Payment.PaymentPanel.PaymentAmount",
                { paymentAmount: formatPrice(paymentAmount) })}
            </TextPrice>
          </Col>
          <Col span={4} style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseButton onClick={onClose} />
          </Col>
        </Row>
        {cashPaymentMethod && (
          <PanelPaymentMethod
            label={t("cashier.Payment.PaymentPanel.InCash")}
            price={cash}
            disabled={!location.cash_payment_account_id}
            type={PAYMENT_METHOD_TYPE.CASH}
            onClick={() => setSelectedPaymentMethod(PAYMENT_METHOD_TYPE.CASH)}
            onClear={handleClear}
            isActive={PAYMENT_METHOD_TYPE.CASH === selectedPaymentMethod}
          />
        )}
        {cardPaymentMethod && (
          <PanelPaymentMethod
            label={t("cashier.Payment.PaymentPanel.Card")}
            disabled={!location.card_payment_account_id}
            price={card}
            type={PAYMENT_METHOD_TYPE.CARD}
            onClick={() => setSelectedPaymentMethod(PAYMENT_METHOD_TYPE.CARD)}
            onClear={handleClear}
            isActive={PAYMENT_METHOD_TYPE.CARD === selectedPaymentMethod}
          />
        )}
        {customPaymentMethods.length > 0 && (
          <BlockAlign>
            <CustomPaymentMethods>
              {customPaymentMethods
                .slice(0, 4)
                .map((pm, index) => (
                  <CustomPaymentMethod
                    key={pm.id}
                    disabled={paymentAmount <= 0}
                    disableMargin={index === 0}
                    onClick={() => {
                      onSetCustomPaymentMethod(pm);
                      setCash(0);
                      setCard(0);
                    }}
                  >
                    {pm.name}
                  </CustomPaymentMethod>
                ))}
            </CustomPaymentMethods>
            {customPaymentMethods.length > 5
              && (
                <SelectPaymentButton
                  disabled={paymentAmount <= 0}
                  onClick={() => setShowPaymentMethod(true)}
                  icon={<MoreOutlined style={{ fontSize: 24, height: 24 }} />}
                />
              )}
          </BlockAlign>
        )}
        <TextChange>{change > 0 && t("cashier.Payment.PaymentPanel.Change", { change: formatPrice(change) })}</TextChange>
        <ContainerNumpadAndCooper>
          <Coopers
            onSelect={handleSelectCooper}
            paymentAmount={paymentAmount}
          />
          <Numpad
            onChange={handleChangeNumpad}
            value={selectedPaymentMethod === PAYMENT_METHOD_TYPE.CARD ? `${card}` : `${cash}`}
          />
          <div />
        </ContainerNumpadAndCooper>
      </TopBlock>
      <ButtonsBlock>
        <PrintSwitch
          printSettings={printSettings}
          onPrintSettingsChange={onPrintSettingsChange}
          isNewOrder={isNewOrder}
        />
        <Button
          size="large"
          type="primary"
          onClick={handleSendPayment}
        >
          {t(`cashier.Payment.PaymentPanel.${hasCardPOS ? "SendToPOS" : "Payment"}`)}
        </Button>
      </ButtonsBlock>
      {showPaymentMethod && ReactDOM.createPortal(
        <PaymentMethodsModal
          onSetCustomPaymentMethod={(pm) => {
            onSetCustomPaymentMethod(pm);
            setShowPaymentMethod(false);
            setCash(0);
            setCard(0);
          }}
          paymentMethods={customPaymentMethods}
          onCancel={() => setShowPaymentMethod(false)}
        />,
        document.body,
      )}
    </div>
  );
};

PaymentPanel.propTypes = {
  paymentAmount: PropTypes.number.isRequired,
  isNewOrder: PropTypes.bool.isRequired,
  paymentMethods: PropTypes.array,
  onOrderPayment: PropTypes.func.isRequired,
  onSetCustomPaymentMethod: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  printSettings: PropTypes.object,
  onPrintSettingsChange: PropTypes.func,
  onClose: PropTypes.func,
  location: PropTypes.object,
};

export default PaymentPanel;
