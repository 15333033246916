import React from "react";
import PropTypes from "prop-types";
import { Popover, Button } from "antd";
import styled from "styled-components";
import ReactDOM from "react-dom";

const PositionPointer = styled.div`
  position: absolute;
`;

const CancelRowPopover = ({
  popover,
  selectedOrderLine,
  onHide,
  onCancelSelectedRow,
  t,
}) => {
  const domBody = document.body;

  const container = (
    <Button type="danger" size="large" onClick={onCancelSelectedRow}>
      {t(
        "cashier.Cashier.CartPanel.CancelRowPopover.Cancel",
        {
          name: selectedOrderLine.display_name,
          interpolation: { escapeValue: false },
        },
      )}
    </Button>
  );

  return ReactDOM.createPortal(
    <Popover
      onVisibleChange={onHide}
      placement="bottom"
      visible
      content={container}
      trigger={["click"]}
    >
      <PositionPointer
        style={{
          top: popover.coordinates.top,
          left: popover.coordinates.left,
        }}
      />
    </Popover>,
    domBody,
  );
};

CancelRowPopover.propTypes = {
  popover: PropTypes.object.isRequired,
  selectedOrderLine: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  onCancelSelectedRow: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default CancelRowPopover;
