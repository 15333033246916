import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import colors from "theme/colors";

const ErrorAlert = styled.div`
  background-color: #FBE5E1;
  color: #A62D19;
  padding: 16px 32px 16px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100%;

  && > button {
    font-size: 24px;
    margin: 0 10px;
    outline: none;
    cursor: pointer;
    color: ${colors.white};
    background-color: ${colors.dodgerBlue};
    border-color: ${colors.dodgerBlue};
  }
`;

const Title = styled.h1`
  text-align: center;
`;

const PageError = ({ error, onReload, onLogout }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <div>
        <Title>{t("errorPage.Message")}</Title>
        <ErrorAlert>{error}</ErrorAlert>
        <ContainerButton>
          {onReload && (
            <button type="button" onClick={onReload}>
              {t("errorPage.DownloadAgain")}
            </button>
          )}
          {onLogout && (
            <button type="button" onClick={onLogout}>
              {t("Logout")}
            </button>
          )}
        </ContainerButton>
      </div>
    </Container>
  );
};

PageError.propTypes = {
  error: PropTypes.string.isRequired,
  onLogout: PropTypes.func,
  onReload: PropTypes.func,
};

export default PageError;
