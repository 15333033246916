import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import { formatPrice } from "utils/currency";
import colors from "theme/colors";

import { ColoredCard } from "components/Card";
import ScrollableContainer from "components/ScrollableContainer";

const minPrice = (menu_items) =>
  Math.min(...menu_items.map((menuItem) => menuItem.price));

const CategoryItems = ({ menuItemGroups, onMenuItemSelect }) => {
  const { t } = useTranslation();
  return (
    <ScrollableContainer deps={[menuItemGroups]} style={{ marginBottom: 8 }}>
      {({ containerRef, showScrollButtons, UpButton, DownButton }) => (
        <>
          <div
            ref={containerRef}
            className="no-scroll-bar"
            style={{
              background: colors.surface,
              padding: 10,
              borderRadius: 8,
              height: `calc(100vh - 157px - ${showScrollButtons ? 43 : 0}px)`,
              overflowY: "scroll",
            }}
          >
            <Row gutter={[8, 8]} id="menu-items">
              {menuItemGroups.map((menuItemGroup) => (
                <Col key={menuItemGroup.id} xs={8} sm={8} md={12} lg={8} xl={6}>
                  <ColoredCard
                    role="button"
                    onClick={() => onMenuItemSelect(menuItemGroup)}
                    color={menuItemGroup.color || colors.defaultCartItemColor}
                    title={menuItemGroup.name}
                    footerRightText={menuItemGroup.menu_items.length === 1
                      ? formatPrice(menuItemGroup.menu_items[0].price)
                      : t("cashier.Cashier.MenuPanel.PriceFrom", {
                        price: formatPrice(minPrice(menuItemGroup.menu_items)),
                      })}
                  />
                </Col>
              ))}
            </Row>
          </div>
          {showScrollButtons && (
            <Row gutter={8} style={{ marginTop: 8, marginBottom: 8 }}>
              <Col xs={12} sm={5} md={5} lg={4} xl={3}>
                <UpButton />
              </Col>
              <Col xs={12} sm={5} md={5} lg={4} xl={3}>
                <DownButton />
              </Col>
            </Row>
          )}
        </>
      )}
    </ScrollableContainer>
  );
};

CategoryItems.propTypes = {
  menuItemGroups: PropTypes.array,
  onMenuItemSelect: PropTypes.func.isRequired,
};

export default CategoryItems;
