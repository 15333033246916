import { ApolloClient } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { HttpLink, ApolloLink, split } from "@apollo/client";
import { getDirectiveNames } from "@apollo/client/utilities";
import { InMemoryCache } from "@apollo/client/cache";
import { persistCache } from "apollo3-cache-persist";

import { deleteFromStorage } from "utils/useLocalStorage";

import * as platformStorage from "./utils/useLocalStorage/crossPlatformLocalStorage";

const host = process.env.REACT_APP_BASE_URL ?? window.config.REACT_APP_BASE_URL ?? "";

const graphqlApiClient = new HttpLink({
  uri: `${host}/graphql_api`,
});

const hasuraClient = new HttpLink({
  uri: `${host}/v1/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("terminal_token");
  return {
    headers: token ? { ...headers, authorization: token } : headers,
  };
});

const link = ApolloLink.from([
  authLink,
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors?.[0].message?.includes("unauthorized") || networkError?.statusCode === 401) {
      deleteFromStorage("terminal_token");
    }
  }),
  split(
    ({ query }) => getDirectiveNames(query).includes("hasura"),
    hasuraClient,
    graphqlApiClient,
  ),
]);

const cache = new InMemoryCache();

export default () => persistCache({
  cache,
  storage: platformStorage,
  key: "apollo-cache-persist",
  maxSize: false,
})
  .then(() => {
    Object.keys(cache.data.data)
      .filter((key) => ["Order", "Customer", "Delivery"].some((prefix) => key.startsWith(prefix)))
      .forEach((key) => cache.evict({ id: key }));
  })
  .then(() => new ApolloClient({ link, cache }));
