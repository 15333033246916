import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "theme/colors";

const Card = styled.div`
  position: relative;
  cursor: pointer;
  min-height: 100px;
  padding: 10px;
  border-radius: 5px;
  background: ${colors.surface};
`;

const CardBottomPanel = styled.div`
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 0px;
  left: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: ${(props) => props.color};
`;

const BackgroundPanel = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: ${(props) => props.color};
  top: 0px;
  left: 0px;
  border-radius: 5px;
`;

const Title = styled.span`
  position: relative;
  font-size: 1em;
  font-weight: 800;
  margin: auto 0;
  z-index: 2;
`;

const FooterText = styled.div`
  position: absolute;
  font-size: 1em;
  font-weight: 600;
  color: white;
  bottom: 5px;
`;

const CardBody = styled.div`
  position: relative;
  z-index: 2;
`;

export const ColoredCard = ({
  children, color, title, footerLeftText, footerRightText, footerTextColor, body, ...props
}) => (
  <Card {...props}>
    <BackgroundPanel color={color} />
    <CardBottomPanel color={color} />
    {typeof title === "string" && <Title>{title}</Title>}
    {typeof title === "function" && title()}
    {body && (
      <CardBody>
        {body}
        <div style={{ height: 20 }} />
      </CardBody>
    )}
    {footerLeftText && (
      <FooterText
        style={{
          ...footerTextColor ? { color: footerTextColor } : {},
          left: 10,
        }}
      >
        {footerLeftText}
      </FooterText>
    )}
    {footerRightText && (
      <FooterText
        style={{
          ...footerTextColor ? { color: footerTextColor } : {},
          right: 10,
        }}
      >
        {footerRightText}
      </FooterText>
    )}
    {children}
  </Card>
);

ColoredCard.propTypes = {
  children: PropTypes.element,
  body: PropTypes.element,
  color: PropTypes.string.isRequired,
  title: PropTypes.string,
  footerLeftText: PropTypes.string,
  footerRightText: PropTypes.string,
  footerTextColor: PropTypes.string,
};

export default Card;
