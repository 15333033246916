const axios = require("axios");

const pay = (device, payment) =>
  new Promise((resolve, reject) => {
    const baseURL = `http://${device.ip}:8080`;
    axios.get(`${baseURL}/payment?amount=${payment.amount}`, { timeout: 10000 })
      .then((res) => {
        const intervalId = setInterval(() => {
          axios.get(`${baseURL}/status?processId=${res.data.processId}`)
            .then((r) => {
              if (r.data.status === "success") {
                clearInterval(intervalId);
                resolve(r.data);
              } else if (r.data.status === "fail") {
                clearInterval(intervalId);
                reject(r.data);
              }
            })
            .catch((e) => {
              clearInterval(intervalId);
              reject(e);
            });
        }, 700);
      })
      .catch(reject);
  });

module.exports = { pay };
