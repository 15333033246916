import React, { useState, useEffect } from "react";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/react";
import axios from "axios";

import TerminalLogin from "@salempos/terminal-login-page";
import { writeStorage, useLocalStorage, deleteFromStorage } from "utils/useLocalStorage";

import ConnectData from "./containers/connect.container";
import FactoryContainers from "./ContainersFactory";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || window.config.REACT_APP_BASE_URL;
const Router = window.location.protocol === "file:" ? MemoryRouter : BrowserRouter;

const App = () => {
  const client = useApolloClient();
  const [error, setError] = useState(null);
  const [token] = useLocalStorage("terminal_token");

  useEffect(() => {
    if (!token) {
      Sentry.setUser(null);
    }
  }, [token]);

  const handleLogout = () => {
    client.clearStore();
    deleteFromStorage("terminal_token");
    deleteFromStorage("devices");
    deleteFromStorage("print_settings");
    deleteFromStorage("orderNotes");
    deleteFromStorage("apollo-cache-persist");
  };

  const handleSubmit = (values) =>
    axios.post("auth/terminal/login", values).then((res) => {
      client.clearStore();
      writeStorage("terminal_token", res.data.token);
    }).catch((err) => setError(err.message));

  return token ? (
    <Router>
      <ConnectData.Provider>
        <FactoryContainers onLogout={handleLogout} />
      </ConnectData.Provider>
    </Router>
  ) : (
    <TerminalLogin type="pos" error={error} onSubmit={handleSubmit} />
  );
};

export default App;
