const kaspi = require("./kaspi");

const getDriver = (deviceType) => {
  switch (deviceType) {
    case "pos-kaspi":
      return kaspi;
    default:
      return {};
  }
};

const pay = (device, payment) => getDriver(device.type).pay(device, payment);

module.exports = { pay };
