import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import Numpad from "@salempos/numpad";
import { useObservable } from "rxjs-hooks";
import { switchMap } from "rxjs/operators";
import { of } from "rxjs";
import _ from "lodash";

import { useService } from "pos-service";
import CurrentUser from "containers/current-user";
import ConnectData from "containers/connect.container";
import { PAYMENT_METHOD_TYPE } from "constants/index";
import colors from "theme/colors";
import useKeyboard from "components/Keyboard/useKeyboard";

import DateTimeBlock from "./DateTimeBlock";
import CloseShiftReport from "./CloseShiftReport";

const CLOSE_SHIFT_STAGES = ["actual_amounts", "close_amounts", "report"];
const LAST_STAGE = CLOSE_SHIFT_STAGES[CLOSE_SHIFT_STAGES.length - 1];

const CloseShift = ({ onClose }) => {
  const { t } = useTranslation();
  const { terminals, payment_methods } = ConnectData.useContainer();
  const { service } = useService();
  const { user } = CurrentUser.useContainer();
  const keyboard = useKeyboard();

  const [stage, setStage] = useState(CLOSE_SHIFT_STAGES[0]);
  const [amounts, setAmounts] = useState({ close_amounts: {}, actual_amounts: {} });
  const [selectedTerminalId, setSelectedTerminal] = useState(terminals[0].id);

  const orderPayments = useObservable(() => service.openCashierShift$.pipe(
    switchMap((shift) => shift?.order_payments.observe() ?? of([])),
  ), []);

  const cashPaymentMethodId = payment_methods.find((p) => p.type === PAYMENT_METHOD_TYPE.CASH)?.id;

  const cashRevenueByTerminal = useMemo(() =>
    orderPayments.reduce((acc, { terminal_id, amount, payment_method_id, type }) =>
      (payment_method_id === cashPaymentMethodId ? {
        ...acc, [terminal_id]: (acc[terminal_id] || 0) + (amount * (type === "payment" ? 1 : -1)),
      } : acc), {}),
  [orderPayments, cashPaymentMethodId]);

  const revenueByPaymentMethods = useMemo(() =>
    orderPayments.reduce((acc, { payment_method_id, amount, type }) => ({
      ...acc,
      [payment_method_id]: (acc[payment_method_id] || 0) + (amount * (type === "payment" ? 1 : -1)),
    }), {}),
  [orderPayments]);

  const financeTransactions = useObservable(() =>
    service.openCashierShift.getValue().finance_transactions.observe(), []);

  const expectedCashAmounts = useMemo(() => terminals.reduce((acc, { id }) => {
    const transactionsTotal = financeTransactions.filter((ft) => ft.terminal_id === id)
      .reduce((total, { type, amount }) => (total + (type === "expense" ? (-amount) : amount)), 0);
    return {
      ...acc,
      [id]: {
        total: (parseFloat(cashRevenueByTerminal[id]) || 0)
            + (parseFloat(service.openCashierShift.getValue().open_cash_amounts[id]) || 0)
            + transactionsTotal,
        openCashAmounts:
            (parseFloat(service.openCashierShift.getValue().open_cash_amounts[id]) || 0),
        cashRevenue: (parseFloat(cashRevenueByTerminal[id]) || 0),
        transactionsTotal,
      },
    };
  }, {}), [cashRevenueByTerminal, financeTransactions]);

  const incassationAmount = useMemo(() => {
    const actualTotal = Object.values(amounts.actual_amounts)
      .reduce((total, amount) => total + parseFloat(amount), 0);
    const closeTotal = Object.values(amounts.close_amounts)
      .reduce((total, amount) => total + parseFloat(amount), 0);
    return actualTotal - closeTotal;
  }, [amounts]);

  const correctionAmount = useMemo(() => {
    const expectedTotal = Object.values(expectedCashAmounts)
      .reduce((total, amount) => total + parseFloat(amount.total), 0);
    const actual = Object.values(amounts.actual_amounts)
      .reduce((total, amount) => total + parseFloat(amount), 0);
    return expectedTotal - actual;
  }, [expectedCashAmounts, amounts.actual_amounts]);

  const handleValue = (number) => {
    setAmounts({
      ...amounts,
      [stage]: {
        ...amounts[stage],
        [selectedTerminalId]: number,
      },
    });
  };

  const submitDisabled = useMemo(() =>
    (stage !== LAST_STAGE && terminals.some((term) => !amounts[stage][term.id])),
  [amounts, stage]);

  const handleOk = () => {
    service.closeShift(
      user.id,
      amounts.actual_amounts,
      amounts.close_amounts,
      _.mapValues(expectedCashAmounts, (a) => a.total),
      incassationAmount,
      { amount: correctionAmount, reason: keyboard.state },
    ).then(onClose);
  };

  const handleBack = () => {
    setStage(CLOSE_SHIFT_STAGES[CLOSE_SHIFT_STAGES.indexOf(stage) - 1]);
  };

  const handleNext = () => {
    setStage(CLOSE_SHIFT_STAGES[CLOSE_SHIFT_STAGES.indexOf(stage) + 1]);
  };

  return (
    <Modal
      visible
      onCancel={onClose}
      onOk={handleOk}
      title={t("ShiftManager.ClosingShift")}
      centered={!keyboard.visible}
      bodyStyle={stage === LAST_STAGE ? { background: colors.background } : {}}
      width="90%"
      style={{ maxWidth: 850, top: keyboard.visible ? "-30%" : 0 }}
      footer={[
        stage === CLOSE_SHIFT_STAGES[0] ? (
          <Button key="cancel" size="large" onClick={onClose}>
            {t("Cancel")}
          </Button>
        ) : (
          <Button key="back" size="large" onClick={handleBack}>
            {t("ShiftManager.Back")}
          </Button>
        ),
        stage === LAST_STAGE ? (
          <Button key="close" size="large" onClick={handleOk} type="primary" disabled={submitDisabled}>
            {t("ShiftManager.CloseShift")}
          </Button>
        ) : (
          <Button key="next" size="large" onClick={handleNext} type="primary" disabled={submitDisabled}>
            {t("ShiftManager.Next")}
          </Button>
        ),
      ]}
    >
      {stage === LAST_STAGE ? (
        <CloseShiftReport
          amounts={amounts}
          terminals={terminals}
          cashRevenueByTerminal={cashRevenueByTerminal}
          revenueByPaymentMethods={revenueByPaymentMethods}
          expectedCashAmounts={expectedCashAmounts}
          incassationAmount={incassationAmount}
          correctionAmount={correctionAmount}
          keyboard={keyboard}
        />
      ) : (
        <>
          <span style={{ fontSize: 18 }}>
            {stage === CLOSE_SHIFT_STAGES[0]
              ? t("ShiftManager.Input.Label")
              : t("ShiftManager.Input.LabelAfterIncassation")}
          </span>
          <Form layout="vertical" style={{ marginTop: 10 }}>
            {terminals.map((terminal) => (
              <Form.Item
                key={terminal.id}
                style={{ marginBottom: 10 }}
              >
                <Input
                  size="large"
                  onFocus={() => setSelectedTerminal(terminal.id)}
                  value={amounts[stage][terminal.id]}
                  placeholder={terminal.name}
                />
              </Form.Item>
            ))}
          </Form>
          <div style={{ display: "flex", justifyContent: "space-between", fontSize: 16 }}>
            {user && <span>{t("ShiftManager.CloseUser")}: {user.name}</span>}
            <DateTimeBlock />
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
            <Numpad
              value={amounts[stage][selectedTerminalId]}
              onConditions={(value) => {
                if (stage === CLOSE_SHIFT_STAGES[1]) {
                  return parseFloat(value)
                    >= parseFloat(amounts.actual_amounts[selectedTerminalId]);
                }
                return false;
              }}
              onChange={handleValue}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

CloseShift.propTypes = {
  onClose: PropTypes.func,
};

export default CloseShift;
