/* global codePush */
import React from "react";
import ReactDOM from "react-dom";
import preval from "preval.macro";
import * as Sentry from "@sentry/react";

import SyncedApp from "./SyncedApp";

import "./i18n";

const buildTime = preval`module.exports = new Date().toLocaleString([], {timeZoneName: "short"});`;
// eslint-disable-next-line no-console
console.log(`Build time: ${buildTime}`);

const version = preval`module.exports = require("../package.json").version`;

Sentry.init({
  dsn: "https://3a0900326cab45cb8094e2b304960e59@o558138.ingest.sentry.io/5691240",
  release: `pos-react@${version}`,
  enabled: process.env.NODE_ENV === "production",
  autoSessionTracking: false,
});

const startApp = () => {
  ReactDOM.render(
    <SyncedApp />,
    document.getElementById("root"),
  );
};

if (!window.cordova) {
  startApp();
} else {
  window.document.addEventListener("deviceready", () => {
    startApp();
    window.plugins.insomnia.keepAwake();
    codePush.sync();
  }, false);
}
