import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Align } from "theme/globalStyles";
import { formatPrice } from "utils/currency";
import colors from "theme/colors";
import { ColoredCard } from "components/Card";

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const Title = styled.span`
  font-size: 22px;
  font-weight: 600;
`;

const MenuItem = ({
  menuItemGroup,
  selectedMenuItemIndex,
  orderLine,
  onSave,
  onCancel,
  onRemove,
  t,
}) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [count, setCount] = useState(1);

  const handleDone = () => {
    if (!selectedMenuItem) {
      return;
    }

    onSave({
      ...selectedMenuItem,
      count,
      total_price: selectedMenuItem.price * count,
      category_id: menuItemGroup.category_id,
      menu_group_id: menuItemGroup.id,
      color: menuItemGroup.color,
    });
  };

  useEffect(() => {
    if (selectedMenuItem != null && orderLine == null) handleDone();
  }, [selectedMenuItem]);

  const handleChooseMenuItem = (menuItem) => {
    setSelectedMenuItem({
      menu_item_id: menuItem.id,
      display_name: menuItemGroup.menu_items.length > 1
        ? `${menuItemGroup.name} ${menuItem.name}` : menuItemGroup.name,
      price: parseFloat(menuItem.price),
      unit: menuItem.product_id ? menuItem.product.unit : menuItem.tech_card.unit,
    });
  };

  useEffect(() => {
    if (selectedMenuItemIndex != null) {
      setCount(orderLine.count);
      setSelectedMenuItem({
        menu_item_id: orderLine.menu_item_id,
        display_name: orderLine.display_name,
        price: parseFloat(orderLine.price),
      });
    } else if (menuItemGroup.menu_items.length === 1) {
      handleChooseMenuItem(menuItemGroup.menu_items[0]);
    }
  }, [selectedMenuItemIndex]);

  return (
    <div
      style={{
        background: colors.surface,
        padding: 10,
        borderRadius: 8,
      }}
    >
      <Align align="space-between" style={{ marginBottom: 8 }}>
        <Title>{menuItemGroup.name}</Title>
        <div>
          {selectedMenuItemIndex != null && (
            <StyledButton
              id="delete-item-btn"
              type="danger"
              onClick={() => onRemove(selectedMenuItemIndex)}
            >
              {t("Delete")}
            </StyledButton>
          )}
          <StyledButton
            id="done-item-btn"
            type="primary"
            onClick={handleDone}
            disabled={!selectedMenuItem}
          >
            {t("Done")}
          </StyledButton>
          <Button id="cancel-item-btn" onClick={onCancel}>
            {t("Cancel")}
          </Button>
        </div>
      </Align>
      <Row gutter={[8, 8]}>
        {menuItemGroup.menu_items.map((menuItem) => (
          <Col sm={10} md={8} lg={6}>
            <ColoredCard
              key={menuItem.id}
              isActive={selectedMenuItem && menuItem.id === selectedMenuItem.menu_item_id}
              onClick={() => handleChooseMenuItem(menuItem)}
              color={selectedMenuItem && menuItem.id === selectedMenuItem.menu_item_id
                ? colors.defaultCartItemColor : colors.lightGrey}
              margins={4}
              title={menuItem.name}
              footerRightText={formatPrice(menuItem.price)}
              footerTextColor={(selectedMenuItem && menuItem.id === selectedMenuItem.menu_item_id) ? "white" : "black"}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

MenuItem.propTypes = {
  menuItemGroup: PropTypes.object,
  orderLine: PropTypes.object,
  selectedMenuItemIndex: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default MenuItem;
