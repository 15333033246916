import React from "react";
import { Popover, Space, Statistic } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { InfoCircleOutlined } from "@ant-design/icons";

import { formatPrice } from "utils/currency";

const Stats = ({ stats }) => {
  const { t } = useTranslation();

  return (
    <Space size="large">
      <Statistic
        title={t("Stats.Orders")}
        value={stats.count}
        valueStyle={{ lineHeight: 1, fontSize: 22 }}
      />
      <Statistic
        title={t("Stats.AverageCheck")}
        value={formatPrice(stats.avg)}
        valueStyle={{ lineHeight: 1, fontSize: 22 }}
      />
      <Popover content={t("Stats.SalesDescription")}>
        <Statistic
          title={<>{t("Stats.Sales")} <InfoCircleOutlined /></>}
          value={formatPrice(stats.sum)}
          valueStyle={{ lineHeight: 1, fontSize: 22 }}
        />
      </Popover>
    </Space>
  );
};

Stats.propTypes = {
  stats: PropTypes.object.isRequired,
};

export default Stats;
