import React, { useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  ArrowLeftOutlined,
  BarChartOutlined,
  ClockCircleOutlined,
  DesktopOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  InboxOutlined,
  LogoutOutlined,
  PoweroffOutlined,
  FieldTimeOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Modal, Button, Typography } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import i18n from "i18n";

import colors from "theme/colors";
import { useService } from "pos-service";

import { useShiftManager } from "components/ShiftManager";
import useFullscreen from "utils/useFullscreen";
import { getPlatform } from "utils/helpers";

import Devices from "./Devices";
import ConnectData from "../../containers/connect.container";
import CreateTransaction from "./CreateTransaction";

const { Title } = Typography;

const NAVIGATION_PAGES = {
  DEVICES: "devices",
  CREATE_TRANSACTION: "create_transaction",
};

const NAVIGATION_PAGE_TITLES = {
  [NAVIGATION_PAGES.DEVICES]: i18n.t("SettingsModal.Menu.Equipment.Devices"),
  [NAVIGATION_PAGES.CREATE_TRANSACTION]: i18n.t("createTransaction.Title"),
};

const NavigationBar = styled.div`
  display: flex;
  background-color: ${colors.whiteSmoke};
  height: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 1px 0 0 0; // compensate border
  border-bottom: 1px solid ${colors.lightGrey};
  border-radius: 4px 4px 0 0;

  button {
    color: ${colors.black};
    :hover {
      color: #000;
    }
  }

  h4 {
    font-size: 17px;
    margin: 0;
  }
`;

const MenuContainer = styled.div`
  padding: 10px 15px;
  height: 550px;
  overflow: scroll;

  h4 {
    font-size: 16px;
  }

  .menu-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5px;

    ::after {
      content: "";
      flex: 0 0 250px;
    }

    button {
      height: 50px;
      width: 250px;
      text-align: left;
      color: ${colors.nightRider};
      margin-bottom: 10px;

      .anticon {
        vertical-align: -0.25em;
        svg {
          font-size: 20px;
        }
      }

      span {
        font-weight: 500;
        padding-top: 1px;
      }
    }
  }
`;

const Menu = ({
  t, history, onNavigationChange, onTerminalLogout, onCloseModal, onQuit,
}) => {
  const { service } = useService();
  const { isFullscreen, isEnabled: isFullscreenEnabled, setFullscreen } = useFullscreen();
  const shiftManager = useShiftManager();
  const { terminal } = ConnectData.useContainer();

  return (
    <MenuContainer>
      {[{
        title: t("SettingsModal.Menu.Shift.Title"),
        buttons: [terminal.main && {
          title: t(`SettingsModal.Menu.Shift.${service.isShiftOpen ? "CloseShift" : "OpenShift"}`),
          icon: <FieldTimeOutlined />,
          action: () => { shiftManager.show(); onCloseModal(); },
        }, {
          title: t("SettingsModal.Menu.Shift.Report"),
          icon: <BarChartOutlined />,
          action: () => { history.push("/stats"); onCloseModal(); },
          disabled: !service.isShiftOpen,
        }, {
          title: t("SettingsModal.Menu.Shift.Archive"),
          icon: <ClockCircleOutlined />,
          action: () => { history.push("/archive"); onCloseModal(); },
          disabled: !service.isShiftOpen,
        }, {
          title: t("SettingsModal.Menu.Shift.CreateTransaction"),
          icon: <PlusCircleOutlined />,
          action: () => onNavigationChange(NAVIGATION_PAGES.CREATE_TRANSACTION),
          disabled: !service.isShiftOpen,
        }].filter((e) => e),
      }, {
        title: t("SettingsModal.Menu.Equipment.Title"),
        buttons: [{
          title: t("SettingsModal.Menu.Equipment.Devices"),
          icon: <DesktopOutlined />,
          action: () => onNavigationChange(NAVIGATION_PAGES.DEVICES),
        }, {
          title: t("SettingsModal.Menu.Equipment.CashDrawer"),
          icon: <InboxOutlined />,
          action: () => "not_implemented",
        }],
      }, {
        title: t("SettingsModal.Menu.Terminal.Title"),
        buttons: [isFullscreenEnabled ? {
          title: t("SettingsModal.Menu.Terminal.Fullscreen"),
          icon: isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />,
          action: () => setFullscreen(!isFullscreen),
        } : null, {
          title: t("SettingsModal.Menu.Terminal.Logout"),
          icon: <LogoutOutlined />,
          action: onTerminalLogout,
        }, onQuit ? {
          title: t("SettingsModal.Menu.Terminal.Quit"),
          icon: <PoweroffOutlined />,
          action: onQuit,
        } : null].filter((v) => v),
      }].map(({ title, buttons }) => (
        <React.Fragment key={title}>
          <Title level={4}>{title}</Title>
          <div className="menu-buttons">
            {buttons.map(({ title: bTitle, icon, action, disabled }) => (
              <Button
                style={bTitle.length >= 20 ? { fontSize: "15px" } : {}}
                key={bTitle}
                size="large"
                icon={icon}
                onClick={action}
                disabled={disabled}
              >
                {bTitle}
              </Button>
            ))}
          </div>
        </React.Fragment>
      ))}
    </MenuContainer>
  );
};

Menu.propTypes = {
  t: PropTypes.func.isRequired,
  onNavigationChange: PropTypes.func.isRequired,
  onTerminalLogout: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onQuit: PropTypes.func,
  history: PropTypes.object.isRequired,
};

const MenuWithRouter = withRouter(Menu);

const SettingsModal = ({ onClose, onTerminalLogout, onQuitApp }) => {
  const { t } = useTranslation();
  const [navigation, setNavigation] = useState(null);

  const tabContents = useMemo(() => {
    switch (navigation) {
      case NAVIGATION_PAGES.DEVICES:
        return <Devices />;
      case NAVIGATION_PAGES.CREATE_TRANSACTION:
        return <CreateTransaction onCloseModal={onClose} />;
      default:
        return (
          <MenuWithRouter
            t={t}
            onNavigationChange={setNavigation}
            onCloseModal={onClose}
            onTerminalLogout={onTerminalLogout}
            onQuit={onQuitApp}
          />
        );
    }
  }, [navigation]);

  return (
    <Modal
      visible
      centered
      width="100%"
      closable={false}
      transitionName="none"
      maskTransitionName="none"
      onCancel={onClose}
      footer={null}
      bodyStyle={{ padding: "0px", height: "600px" }}
      style={{ maxWidth: getPlatform() === "Windows" ? "820px" : "800px" }}
    >
      <NavigationBar>
        {navigation ? (
          <Button onClick={() => setNavigation(null)} icon={<ArrowLeftOutlined />} type="link" size="large">
            {t("SettingsModal.Title")}
          </Button>
        ) : <div style={{ width: "95px" }} />}
        <Title level={4}>
          {navigation ? NAVIGATION_PAGE_TITLES[navigation] : t("SettingsModal.Title")}
        </Title>
        <Button onClick={onClose} size="large" type="link">{t("Close")}</Button>
      </NavigationBar>
      {tabContents}
    </Modal>
  );
};

SettingsModal.propTypes = {
  onTerminalLogout: PropTypes.func.isRequired,
  onQuitApp: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default SettingsModal;
