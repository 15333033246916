import { Select, Switch } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";

import colors from "theme/colors";

const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
`;

const SettingRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const SettingText = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 18px;
  transition: all 0.36s;
  align-items: center;
  color: ${({ disabled }) => (disabled ? colors.lightGrey : colors.black)};
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${colors.lightGrey};
`;

const DeviceSettings = ({ settings, onSettingChange, isEscPos }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <SettingRow key="paper_width">
        <SettingText>
          {t("devices.SelectedDevice.Settings.PaperWidth")}
        </SettingText>
        <Select
          defaultValue="80"
          style={{ width: "50%", margin: 0 }}
          size="large"
          onChange={(value) => onSettingChange("paper_width", value)}
          value={settings.paper_width || "80"}
        >
          <Select.Option value="80">80 mm</Select.Option>
          <Select.Option value="72">80 mm (wide margins)</Select.Option>
          <Select.Option value="58">58 mm</Select.Option>
          <Select.Option value="48">48 mm</Select.Option>
        </Select>
      </SettingRow>
      {isEscPos && (
        <>
          <Divider />
          <SettingRow
            key="bell"
            onClick={() => onSettingChange("bell", !settings.bell)}
          >
            <SettingText>
              {t("devices.SelectedDevice.Settings.Bell")}
            </SettingText>
            <Switch checked={settings.bell} />
          </SettingRow>
          <Divider />
          <SettingRow
            key="cash_drawer"
            onClick={() => onSettingChange("cash_drawer", !settings.cash_drawer)}
          >
            <SettingText>
              {t("devices.SelectedDevice.Settings.CashDrawer")}
            </SettingText>
            <Switch checked={settings.cash_drawer} />
          </SettingRow>
        </>
      )}
    </Container>
  );
};

DeviceSettings.propTypes = {
  settings: PropTypes.object,
  onSettingChange: PropTypes.func,
  isEscPos: PropTypes.bool,
};

export default DeviceSettings;
