import { Database } from "@nozbe/watermelondb";
import LokiJSAdapter from "@nozbe/watermelondb/adapters/lokijs";

import * as Sentry from "@sentry/react";

import schema from "./models/schema";

import * as Models from "./models";

export default (config) => {
  const adapter = new LokiJSAdapter({
    schema,
    useWebWorker: false,
    useIncrementalIndexedDB: true,
    dbName: `pos_db_${config.terminal.id}`,
    onSetUpError: (error) => {
      Sentry.captureException(error, {
        tags: { section: "database" },
      });
    },
  });

  return new Database({
    adapter,
    modelClasses: Object.values(Models),
  });
};
