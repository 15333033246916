import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Col, Row, Grid, notification } from "antd";

import { useService } from "pos-service";
import colors from "theme/colors";

import CurrentUser from "containers/current-user";

import PaymentModal from "components/PaymentModal";
import CancelModal from "components/CancelModal";
import Stats from "components/Stats";
import ScrollableContainer from "components/ScrollableContainer";

const OrdersList = ({ history, orders, stats, renderOrderCard, listHeader }) => {
  const { t } = useTranslation();
  const breakpoints = Grid.useBreakpoint();
  const { user } = CurrentUser.useContainer();
  const { service } = useService();
  const [orderToPay, setOrderToPay] = useState(null);
  const [orderToCancel, setOrderToCancel] = useState(null);

  const nonScrollableHeight = useMemo(() => {
    const titlesHeight = breakpoints.lg ? 22 : 44;
    const footerHeight = breakpoints.md ? 50 : 106;
    // header, p, p, titles, p, p, p, footer, p
    return 64 + 8 + 10 + titlesHeight + 8 + 10 + 8 + footerHeight + 12;
  }, [breakpoints]);

  const handleCancelSubmit = (values) =>
    service.cancelOrder(user.id, orderToCancel, values)
      .then(() => setOrderToCancel(null))
      .catch(notification.error);

  const handlePayOrder = (payments) =>
    service.payOrder(user.id, orderToPay, payments)
      .then((order) => {
        setOrderToPay(null);
        return order;
      })
      .catch(notification.error);

  return (
    <div style={{ padding: "8px 12px 12px" }}>
      <ScrollableContainer deps={[orders]}>
        {({
          containerRef, UpButton, DownButton, syncScrollButtonsVisibility,
        }) => (
          <>
            <div style={{ borderRadius: 8, background: colors.surface, padding: 10 }}>
              {listHeader}
              <div
                style={{
                  height: `calc(100vh - ${nonScrollableHeight}px)`,
                  overflowY: "scroll",
                }}
                className="no-scroll-bar"
                ref={containerRef}
              >
                {orders.map((order, index) => renderOrderCard(
                  order, setOrderToPay, setOrderToCancel,
                  syncScrollButtonsVisibility, orders.length === index + 1,
                ))}
              </div>
            </div>
            <Row gutter={[8, 8]} style={{ marginTop: 8 }}>
              <Col xs={8} sm={6} md={4}>
                <UpButton />
              </Col>
              <Col xs={8} sm={6} md={4}>
                <DownButton />
              </Col>
              <Col xs={24} md={{ offset: 1, span: 15 }}>
                {stats && <Stats stats={stats} />}
              </Col>
            </Row>
          </>
        )}
      </ScrollableContainer>
      {orderToPay && (
        <PaymentModal
          visible
          t={t}
          history={history}
          order={orderToPay}
          listPrice={orderToPay.list_price}
          onPay={handlePayOrder}
          onCancel={() => setOrderToPay(null)}
        />
      )}
      {orderToCancel && (
        <CancelModal
          order={orderToCancel}
          onSubmitCancel={handleCancelSubmit}
          onCancel={() => setOrderToCancel(null)}
          t={t}
        />
      )}
    </div>
  );
};

OrdersList.propTypes = {
  history: PropTypes.object,
  orders: PropTypes.array.isRequired,
  stats: PropTypes.object,
  renderOrderCard: PropTypes.func.isRequired,
  listHeader: PropTypes.node.isRequired,
};

export default OrdersList;
