import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Numpad from "@salempos/numpad";
import colors from "theme/colors";

const Panel = styled.div`
  background: #fff;
  z-index: 3;
  width: 240px;
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;

  .action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }
`;

const Input = styled.div`
  display: flex;

  .input-inner {
    background: ${colors.white};
    font-size: 18px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 10px;
    padding: 8px 10px;
    cursor: pointer;
    border-color: ${colors.dodgerBlue};
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    width: 100%;

    .value {
      font-size: 20px;
      color: ${colors.black};
    }
  }
`;

const Button = styled.button`
  border: 1px solid ${colors.dodgerBlue};
  background-color: ${colors.dodgerBlue};
  cursor: pointer;
  color: #fff;
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  width: 48%;
`;

const InputOfNumpad = ({
  value,
  ...rest
}) => (
  <Input {...rest}>
    <div className="input-inner">
      <div className="value">{value}%</div>
    </div>
  </Input>
);

InputOfNumpad.propTypes = {
  value: PropTypes.number.isRequired,
};

const DiscountModal = ({ t, visible, onClose, onOk }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (visible) setValue(0);
  }, [visible]);

  return (
    <Panel>
      <InputOfNumpad value={value} />
      <Numpad
        value={value}
        onChange={(number) => setValue(number ? parseFloat(number) : 0)}
      />
      <div className="action">
        <Button onClick={() => {
          onOk(value / 100);
        }}
        >
          {t("Ok")}
        </Button>
        <Button onClick={() => {
          onClose();
        }}
        >
          {t("Cancel")}
        </Button>
      </div>
    </Panel>
  );
};

DiscountModal.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default DiscountModal;
