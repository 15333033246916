import React, { useState } from "react";
import styled from "styled-components";
import {
  CrownOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  LeftOutlined,
  LogoutOutlined,
  PoweroffOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Col, Row, Button } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ConnectData from "containers/connect.container";
import CurrentUser from "containers/current-user";

import salemposLogo from "assets/salempos.png";
import pinBg from "assets/pin_bg.jpg";

import useFullscreen from "utils/useFullscreen";
import FullscreenModal from "../../components/FullscreenModal";
import PinCodeForm from "./components/PinCodeForm";

const Container = styled(FullscreenModal)`
  z-index: 99999;

  .image-container {
    height: 100vh;
    background: url(${pinBg}) center;
    background-size: cover;
  }

  .pinCode-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .buttons {
    width: 300px;
    height: 475px;

    button {
      height: 50px;
      text-align: left;
      margin-bottom: 10px;
      background-color: #00000060;
      color: white;
    }
  }

  .settings-button {
    position: absolute;
    right: 10px;
    top: 5px;
    background-color: #00000060;
    color: white;
  }

  .details {
    position: absolute;
    right: 10px;
    bottom: 5px;
    text-align: right;
    margin-left: 10px;
  }
`;

const Image = styled.img`
  height: 120px;
  margin-bottom: 32px;
`;

const PinCode = ({ version, onTerminalLogout, onQuitApp }) => {
  const { t } = useTranslation();
  const { merchant, terminal, cashiers } = ConnectData.useContainer();
  const { setUser } = CurrentUser.useContainer();
  const [showButtons, setShowButtons] = useState(false);
  const { isFullscreen, isEnabled: isFullscreenEnabled, setFullscreen } = useFullscreen();

  return (
    <Container>
      <Row>
        <Col className="image-container" sm={0} xs={0} md={10} lg={14} />
        <Col className="pinCode-container" xs={24} sm={24} md={14} lg={10}>
          <Button
            onClick={() => setShowButtons(!showButtons)}
            size="large"
            type="link"
            icon={<SettingOutlined />}
            className="settings-button"
          />
          <Image
            src={merchant.logo_url ? merchant.logo_url : salemposLogo}
            alt="logo"
          />
          {showButtons ? (
            <div className="buttons">
              {[isFullscreenEnabled ? {
                title: t("pinCode.Buttons.Fullscreen"),
                icon: isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />,
                action: () => setFullscreen(!isFullscreen),
              } : null, {
                title: t("pinCode.Buttons.Logout"),
                icon: <LogoutOutlined />,
                action: onTerminalLogout,
              }, onQuitApp ? {
                title: t("pinCode.Buttons.Quit"),
                icon: <PoweroffOutlined />,
                action: onQuitApp,
              } : null, {
                title: t("Cancel"),
                icon: <LeftOutlined />,
                action: () => setShowButtons(false),
              }].filter((v) => v).map(({ title, icon, action }) => (
                <Button type="link" block key={title} size="large" icon={icon} onClick={action}>
                  {title}
                </Button>
              ))}
            </div>
          ) : (
            <PinCodeForm users={cashiers} onSend={(user) => setUser(user)} />
          )}
          <span className="details">
            {terminal.main && <CrownOutlined />}
            &nbsp;
            {t("pinCode.Details", { version, name: terminal.name })}
          </span>
        </Col>
      </Row>
    </Container>
  );
};

PinCode.propTypes = {
  version: PropTypes.string.isRequired,
  onTerminalLogout: PropTypes.func.isRequired,
  onQuitApp: PropTypes.func,
};

export default PinCode;
