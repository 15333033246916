import { useEffect, useMemo, useState } from "react";
import { createContainer } from "unstated-next";
import _ from "lodash";
import { of } from "rxjs";
import { useObservable } from "rxjs-hooks";
import { map, switchMap } from "rxjs/operators";

import { ORDER_TYPE } from "constants/index";

import CurrentUser from "containers/current-user";
import ConnectData from "containers/connect.container";

import { useService } from "pos-service";

import Cart from "./cart.container";

const useCurrentOrder = (order) => {
  const { common_settings, terminal } = ConnectData.useContainer();
  const { user } = CurrentUser.useContainer();
  const { service } = useService();

  const { newOrderLines, ...cart } = Cart.useContainer();

  const orderPrice = useObservable((_s, inputs$) => inputs$.pipe(
    switchMap(([o]) => (o ? o.order_price_updates.observe() : of([]))),
    map((opuArr) => _.maxBy(opuArr, "id")),
  ), null, [order]);

  const [orderType, setOrderType] = useState(order?.type ?? ORDER_TYPE.IN_STORE);
  const [notes, setOrderNotes] = useState(order?.notes);
  const [tableId, setTableId] = useState(order?.table_id);
  const [discount, setDiscount] = useState(0);
  const [servicePercent, setServicePercent] = useState(0);

  useEffect(() => {
    if (orderPrice) {
      setDiscount(orderPrice.discount);
      setServicePercent(orderPrice.service_percent);
    }
  }, [orderPrice]);

  const orderLinesTotal = useMemo(() => {
    const currentOrderLinesTotal = orderPrice?.order_lines_total ?? 0;
    const newLinesPrice = newOrderLines.reduce((sum, ol) => sum + ol.total_price, 0);
    return currentOrderLinesTotal + newLinesPrice;
  }, [orderPrice, newOrderLines]);

  const serviceFee = orderLinesTotal * servicePercent;
  const deliveryPrice = orderPrice?.delivery_price ?? 0;
  const discountAmount = orderLinesTotal * discount;
  const listPrice = orderLinesTotal + deliveryPrice + serviceFee - discountAmount;

  const createOrder = (payments) => service.createOrder(user.id, {
    type: orderType,
    tableId,
    orderLinesTotal,
    discount,
    servicePercent,
    listPrice,
    orderLines: newOrderLines,
    notes,
  }, payments);

  const changeOrder = (payments) => service.changeOrder(user.id, order, {
    type: orderType,
    notes,
    tableId,
    orderLinesTotal,
    deliveryPrice,
    discount,
    servicePercent,
    listPrice,
    newOrderLines,
  }, payments);

  const clear = () => {
    cart.clear();
    setOrderType(ORDER_TYPE.IN_STORE);
    setTableId(null);
    setDiscount(0);
    setServicePercent(0);
    setOrderNotes(null);
  };

  useEffect(() => {
    if (!order) {
      clear();
      if (terminal.settings && terminal.settings.charge_service_percent_by_default) {
        setServicePercent(common_settings.service_percent);
      }
    }
  }, [order]);

  return {
    order,

    cart,

    discount,
    setDiscount,

    tableId,
    setTableId,

    servicePercent,
    setServicePercent,

    orderType,
    setOrderType,

    notes,
    setOrderNotes,

    orderLinesTotal,
    serviceFee,
    listPrice,
    deliveryPrice,
    discountAmount,

    createOrder,
    changeOrder,
    clear,
  };
};

export default createContainer(useCurrentOrder);
