import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Col, Row, Input, Button } from "antd";
import styled from "styled-components";

import ConnectData from "containers/connect.container";
import useKeyboard from "components/Keyboard/useKeyboard";

import CurrentOrder from "../../containers/currentOrder.container";
import { PANEL_STATE } from "../../constants";
import MenuItemPanel from "./components/MenuItemPanel";
import ModifierItemPanel from "./components/ModifierItemPanel";
import CategoryItems from "./components/CategoryItems";
import CategoriesSidebar from "./components/CategoriesSidebar";

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  .ant-input-clear-icon {
    font-size: 22px;
  }
`;

const InputSearch = styled(Input)`
  height: 50px;
  font-size: 20px;
`;

const CancelButton = styled(Button)`
  height: 50px;
  font-size: 16px;
  margin-left: 8px;
`;

const MenuPanel = ({
  state,
  onSelectCategory,
  onSaveMenuItem,
  onSelectMenuItem,
  onRemoveOrderLineAndReturnHome,
  onBackToCategory,
  t,
}) => {
  const keyboard = useKeyboard();
  const { menu_categories } = ConnectData.useContainer();
  const { cart } = CurrentOrder.useContainer();
  const inputSearch = useRef();

  const orderLine = cart.orderLines[state.cart_index];

  const categoryId = orderLine ? orderLine.category_id : state.menu_category_id;
  const category = useMemo(() =>
    menu_categories.find((mc) => mc.id === categoryId),
  [categoryId]);

  const menuItemGroupId = orderLine ? orderLine.menu_group_id : state.menu_group_id;
  const menuItemGroup = useMemo(() =>
    category?.menu_item_groups.find((mig) => mig.id === menuItemGroupId),
  [menuItemGroupId]);

  const onCancel = () => onBackToCategory(categoryId);

  const renderPanelContent = useMemo(() => {
    switch (state.state) {
      case PANEL_STATE.CATEGORY:
        return (
          <CategoryItems
            menuItemGroups={category.menu_item_groups}
            onMenuItemSelect={onSelectMenuItem}
            t={t}
          />
        );
      case PANEL_STATE.NEW:
        return (
          <MenuItemPanel
            menuItemGroup={menuItemGroup}
            onSave={onSaveMenuItem}
            onCancel={onCancel}
            t={t}
          />
        );
      case PANEL_STATE.NEW_MODIFIER:
        return (
          <ModifierItemPanel
            menuItemGroup={menuItemGroup}
            onSave={onSaveMenuItem}
            onCancel={onCancel}
            t={t}
          />
        );
      case PANEL_STATE.EDIT:
        return (
          <MenuItemPanel
            selectedMenuItemIndex={state.cart_index}
            menuItemGroup={menuItemGroup}
            orderLine={orderLine}
            onSave={onSaveMenuItem}
            onCancel={onCancel}
            onRemove={onRemoveOrderLineAndReturnHome}
            t={t}
          />
        );
      case PANEL_STATE.EDIT_MODIFIER:
        return (
          <ModifierItemPanel
            selectedMenuItemIndex={state.cart_index}
            menuItemGroup={menuItemGroup}
            orderLine={orderLine}
            onSave={onSaveMenuItem}
            onCancel={onCancel}
            onRemove={onRemoveOrderLineAndReturnHome}
            t={t}
          />
        );
      default:
        return null;
    }
  }, [state]);

  const isSearchInputFocused = inputSearch.current?.props.id === document.activeElement.id;

  const handleInputChange = (e) => {
    // handle clear button click, from https://github.com/ant-design/ant-design/issues/16862#issuecomment-507616942
    if (e.type === "click") {
      inputSearch.current.focus();
      keyboard.clearState();
    } else {
      keyboard.setState(e.target.value);
    }
  };

  const handleCancelSearch = () => {
    keyboard.hide();
    keyboard.clearState();
  };

  const preventInputBlur = (e) => {
    // relatedTarget exists only when focusable element clicked,
    // that`s why we set tabIndex property on Layout component in AppLayout.js
    const { relatedTarget } = e.nativeEvent;
    if (relatedTarget) {
      // ignore cancel search button to properly handle cancel search
      if (relatedTarget.id !== "cancel-search") {
        keyboard.hide();
      }
    } else {
      inputSearch.current.focus();
    }
  };

  return (
    <Col sm={24} md={14} lg={16} xl={17}>
      <Row gutter={8}>
        <Col sm={4}>
          <CategoriesSidebar
            onCategorySelect={onSelectCategory}
            menuCategories={menu_categories}
            selectedCategoryId={state.menu_category_id}
          />
        </Col>
        <Col sm={20}>
          <SearchContainer>
            <InputSearch
              id="search-input"
              ref={inputSearch}
              size="large"
              value={keyboard.state}
              placeholder={t("cashier.Cashier.MenuPanel.SearchPlaceholder")}
              allowClear
              onChange={handleInputChange}
              onFocus={keyboard.show}
              onBlur={preventInputBlur}
              autoComplete="off"
            />
            {(isSearchInputFocused || keyboard.state) && (
              <CancelButton id="cancel-search" onClick={handleCancelSearch} type="danger">
                {t("cashier.Cashier.MenuPanel.Cancel")}
              </CancelButton>
            )}
          </SearchContainer>
          {keyboard.state ? (
            <CategoryItems
              menuItemGroups={menu_categories.map((c) => c.menu_item_groups)
                .flat()
                .filter((mig) => mig.name.toLowerCase().startsWith(keyboard.state.toLowerCase()))}
              onMenuItemSelect={(item) => {
                keyboard.clearState();
                onSelectMenuItem(item);
              }}
              t={t}
            />
          ) : renderPanelContent}
        </Col>
      </Row>
    </Col>
  );
};

MenuPanel.propTypes = {
  state: PropTypes.oneOfType([
    PropTypes.shape({ state: PropTypes.string.isRequired }),
    PropTypes.shape({
      state: PropTypes.string.isRequired,
      menu_category_id: PropTypes.number,
    }),
    PropTypes.shape({
      state: PropTypes.string.isRequired,
      menu_group_id: PropTypes.number.isRequired,
    }),
    PropTypes.shape({
      state: PropTypes.string.isRequired,
      cart_index: PropTypes.number.isRequired,
    }),
  ]).isRequired,
  onSelectCategory: PropTypes.func,
  onSaveMenuItem: PropTypes.func,
  onSelectMenuItem: PropTypes.func,
  onRemoveOrderLineAndReturnHome: PropTypes.func,
  onBackToCategory: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default MenuPanel;
