import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import colors from "theme/colors";
import { useTranslation } from "react-i18next";
import { Switch } from "antd";

const PrintSwitchContainer = styled.div`
  background: ${colors.surface};
  border-radius: 4px;
  padding: 0 15px;
  margin-right: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SwitchWithMargin = styled(Switch)`
  margin-left: 15px;
`;

const PrintSwitch = ({ printSettings, onPrintSettingsChange, isNewOrder }) => {
  const { t } = useTranslation();
  const currentKey = isNewOrder ? "ifJustCreated" : "ifCreatedBefore";

  return (
    <PrintSwitchContainer
      onClick={() => onPrintSettingsChange({
        ...printSettings, [currentKey]: !printSettings[currentKey],
      })}
    >
      {t("cashier.Payment.PaymentPanel.PrintReceipt")}
      <SwitchWithMargin checked={printSettings[currentKey]} />
    </PrintSwitchContainer>
  );
};

PrintSwitch.propTypes = {
  printSettings: PropTypes.object,
  onPrintSettingsChange: PropTypes.func,
  isNewOrder: PropTypes.bool,
};

export default PrintSwitch;
