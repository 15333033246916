import React from "react";

import { Align } from "theme/globalStyles";
import { formatPrice } from "utils/currency";

import ColumnCounter from "./components/CartCounter";
import NameColumn from "./components/NameColumn";

import i18n from "../../i18n";

export const PANEL_STATE = {
  CATEGORY: "category",
  EDIT: "edit",
  NEW: "new",
  NEW_MODIFIER: "new_modifier",
  EDIT_MODIFIER: "edit_modifier",
};

export const getProductColumns = (onOrderLineNotes) => [
  {
    title: i18n.t("cashier.Cashier.Columns.ListName"),
    dataIndex: "name",
    key: "name",
    width: "55%",
    render: (_, orderLine, index) => (
      <NameColumn
        orderLine={orderLine}
        onClick={onOrderLineNotes && !orderLine.id ? (() => onOrderLineNotes(index)) : null}
      />
    ),
  },
  {
    title: i18n.t("cashier.Cashier.Columns.Qty"),
    dataIndex: "count",
    key: "count",
    align: "center",
    width: "25%",
    render: (count, orderLine, index) => (
      <Align align="center">
        {orderLine.id ? count : <ColumnCounter value={count} index={index} />}
      </Align>
    ),
  },
  {
    title: i18n.t("cashier.Cashier.Columns.Total"),
    dataIndex: "total_price",
    key: "total_price",
    width: "20%",
    render: formatPrice,
  },
];

export const CONTROL = {
  CLEAR: "CLEAR",
};
