import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Button, Modal, Menu, notification } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
  ORDER_PAYMENT_TYPE, ORDER_STATUS as OS, ORDER_STATUS_LABELS, ORDER_TYPE,
} from "constants/index";
import CurrentUser from "containers/current-user";

import { useService } from "pos-service";

import Timer from "./Timer";

const StatusDropdown = styled(Dropdown)`
  width: 210px;
  font-size: 18px;
  font-weight: 800;

  &.status-button {
    color: #000000a6;
    border-color: #000000a6;
  }
  .timer {
    font-size: 15px;
    font-weight: normal;
  }
`;

const MenuItem = styled(Menu.Item)`
  font-size: 15px;
  padding: 10px 15px;
`;

const OrderStatuses = [OS.PROCESSING, OS.READY, OS.SERVED, OS.COMPLETED];
const OrderStatusesDelivery = [OS.PROCESSING, OS.READY, OS.ON_DELIVERY, OS.SERVED, OS.COMPLETED];

const StatusWithTimer = ({ openOrder }) => {
  const { t } = useTranslation();
  const { user } = CurrentUser.useContainer();
  const { service } = useService();

  const confirmCompletion = (reason) => {
    Modal.confirm({
      title: `${reason} ${t("openOrders.StatusWithTimer.CompleteConfirmationMessage")}`,
      onOk() {
        service.changeOrderStatus(user.id, openOrder, OS.COMPLETED)
          .catch(notification.error);
      },
      onCancel() {},
    });
  };

  const handleUpdateStatus = (status) => {
    if (status === OS.COMPLETED) {
      openOrder.order_payments.fetch().then((orderPayments) => {
        const paid = orderPayments.reduce((amount, curr) =>
          amount + (curr.type === ORDER_PAYMENT_TYPE.PAYMENT ? curr.amount : -curr.amount), 0);
        const toPay = openOrder.list_price - paid;
        if (toPay > 0) {
          if (user.access_role.access_location_admin) {
            confirmCompletion(t("openOrders.StatusWithTimer.OrderNotPay"));
          } else {
            Modal.confirm({
              title: t("openOrders.StatusWithTimer.CompleteNoAdminAccess"),
              onOk() {},
            });
          }
        } else {
          service.changeOrderStatus(user.id, openOrder, OS.COMPLETED)
            .catch(notification.error);
        }
      });
    } else {
      service.changeOrderStatus(user.id, openOrder, status)
        .catch(notification.error);
    }
  };

  const statuses = openOrder.type === ORDER_TYPE.DELIVERY ? OrderStatusesDelivery : OrderStatuses;
  const statusesMenu = (
    <Menu
      onClick={(e) => {
        e.domEvent.stopPropagation();
        handleUpdateStatus(e.key);
      }}
    >
      {statuses.map((status, idx) => [
        <MenuItem key={status}>{ORDER_STATUS_LABELS[status]}</MenuItem>,
        idx < statuses.length - 1 && (
          <Menu.Divider key={`divider-${status}`} />
        ),
      ])}
    </Menu>
  );

  return (
    <StatusDropdown
      overlay={statusesMenu}
      trigger={["click"]}
      onClick={(e) => e.stopPropagation()}
    >
      <Button type="dashed" ghost size="large" className="status-button">
        {ORDER_STATUS_LABELS[openOrder.status]}
        &nbsp;&nbsp;&nbsp;
        <Timer
          start={openOrder.status_updated_at}
          textColor={(duration) => (duration >= 15 * 60 ? "red" : null)}
        />
      </Button>
    </StatusDropdown>
  );
};

StatusWithTimer.propTypes = {
  openOrder: PropTypes.object.isRequired,
};

export default StatusWithTimer;
