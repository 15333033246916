import { Model } from "@nozbe/watermelondb";
import {
  field, readonly, date, relation, children, json,
} from "@nozbe/watermelondb/decorators";

export default class Order extends Model {
  static table = "orders";

  static associations = {
    order_lines: { type: "has_many", foreignKey: "order_id" },
    courier_assigns: { type: "has_many", foreignKey: "order_id" },
    order_cancels: { type: "has_many", foreignKey: "order_id" },
    order_payments: { type: "has_many", foreignKey: "order_id" },
    order_price_updates: { type: "has_many", foreignKey: "order_id" },
    order_status_updates: { type: "has_many", foreignKey: "order_id" },
    cashier_shifts: { type: "belongs_to", key: "completed_shift_id" },
  };

  @field("location_id") location_id;
  @field("type") type;
  @date("time") time;
  @field("source") source;
  @field("number") number;
  @field("user_id") user_id;
  @field("api_key_id") api_key_id;
  @field("integration_id") integration_id;
  @field("list_user") list_user;
  @field("table_id") table_id;
  @field("list_table_name") list_table_name;
  @field("status") status;
  @date("status_updated_at") status_updated_at;
  @field("list_price") list_price;
  @field("notes") notes;
  @field("courier_id") courier_id;
  @readonly @date("created_at") created_at;
  @readonly @date("updated_at") updated_at;
  @json("customer", (v) => v) customer;
  @json("delivery_info", (v) => v) delivery_info;

  @relation("cashier_shifts", "completed_shift_id") completed_shift;

  @children("order_lines") order_lines;
  @children("courier_assigns") courier_assigns;
  @children("order_cancels") order_cancels;
  @children("order_payments") order_payments;
  @children("order_price_updates") order_price_updates;
  @children("order_status_updates") order_status_updates;

  get display_number() {
    switch (this.source) {
      case "callcenter": return `${this.number}C`;
      case "api": return `${this.number}A`;
      default: return this.number.toString();
    }
  }
}
