import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Badge, Col } from "antd";

import colors from "theme/colors";

import DeviceTabs from "./components/DeviceTabs";

const DeviceContainer = styled(Col)`
  padding-top: 20px;
`;

const DeviceHeader = styled.h2`
  flex: 1;
  margin: 0px;
`;

const DeviceHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 15px;
  padding-left: 15px;
`;

const Subtext = styled.div`
  color: ${colors.grey};
  padding-right: 15px;
  padding-left: 15px;
`;

const KDSSettings = ({ device, onDeviceChange, t, isConnected, forgetDevice }) => (
  <DeviceContainer sm={16}>
    <DeviceHeaderContainer>
      <DeviceHeader>{device.name}</DeviceHeader>
    </DeviceHeaderContainer>
    <Subtext>
      <Badge offset={[1]} status={isConnected ? "success" : "default"} />
      {t("devices.SelectedDevice.ConnectedVia", { connInfo: `LAN (${device.ip})` })}
    </Subtext>
    <DeviceTabs
      device={device}
      onDeviceChange={(changes) => onDeviceChange(device.id, changes)}
      forgetDevice={forgetDevice}
    />
  </DeviceContainer>
);

KDSSettings.propTypes = {
  device: PropTypes.object,
  onDeviceChange: PropTypes.func,
  t: PropTypes.func,
  isConnected: PropTypes.bool,
  forgetDevice: PropTypes.func,
};

export default KDSSettings;
