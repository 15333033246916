import { useState } from "react";
import { createContainer } from "unstated-next";
import { Q } from "@nozbe/watermelondb";
import { of } from "rxjs";
import { useObservable } from "rxjs-hooks";
import { map, switchMap } from "rxjs/operators";

import { useService } from "pos-service";
import { isModifiersEqual } from "utils/helpers";

const useCart = (orderId) => {
  const { service } = useService();
  const [newOrderLines, setNewOrderLines] = useState([]);

  const existingOrderLines = useObservable((_, inputs$) => inputs$.pipe(
    switchMap(([id]) => (id ? service.db.get("order_lines").query(
      Q.where("order_id", id),
    ).observeWithColumns("updated_at") : of([]))),
    map((arr) => arr.sort((lhs, rhs) => lhs.id.localeCompare(rhs.id))),
  ), [], [orderId]);

  const isEmpty = newOrderLines.length === 0 && existingOrderLines.length === 0;

  const addOrderLine = (selectedMenuItem) => {
    const foundIndex = newOrderLines.findIndex((orderLine) =>
      orderLine.menu_item_id === selectedMenuItem.menu_item_id && !orderLine.notes
          && isModifiersEqual(selectedMenuItem.modifiers, orderLine.modifiers));
    if (foundIndex > -1) {
      setNewOrderLines((previous) => previous.map((orderLine, idx) => (idx === foundIndex ? {
        ...orderLine,
        count: orderLine.count + selectedMenuItem.count,
        total_price: orderLine.total_price + (orderLine.price * selectedMenuItem.count),
      } : orderLine)));
    } else {
      setNewOrderLines((previous) => [...previous, selectedMenuItem]);
    }
  };

  const updateOrderLine = (index, updates) => {
    const realIdx = index - existingOrderLines.length;
    setNewOrderLines((previous) => previous.map((orderLine, idx) => (idx === realIdx ? {
      ...orderLine, ...updates,
    } : orderLine)));
  };

  const increment = (index) => {
    const realIdx = index - existingOrderLines.length;
    setNewOrderLines((previous) => previous.map((orderLine, idx) => (idx === realIdx ? {
      ...orderLine,
      count: orderLine.count + 1,
      total_price: orderLine.total_price + orderLine.price,
    } : orderLine)));
  };

  const removeOrderLine = (index) => {
    const realIdx = index - existingOrderLines.length;
    setNewOrderLines((previous) => previous.filter((i, idx) => idx !== realIdx));
  };

  const decrement = (index) => {
    const realIdx = index - existingOrderLines.length;
    setNewOrderLines((previous) => previous.map((orderLine, idx) => (idx === realIdx ? {
      ...orderLine,
      count: orderLine.count - 1,
      total_price: orderLine.total_price - orderLine.price,
    } : orderLine)));
  };

  const clear = () => setNewOrderLines([]);

  return {
    orderLines: existingOrderLines.concat(newOrderLines),
    isEmpty,
    newOrderLines,
    addOrderLine,
    updateOrderLine,
    increment,
    decrement,
    removeOrderLine,
    clear,
  };
};

export default createContainer(useCart);
