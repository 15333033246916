import { Model } from "@nozbe/watermelondb";
import { field, readonly, date, json, children } from "@nozbe/watermelondb/decorators";

export default class CashierShift extends Model {
  static table = "cashier_shifts";

  static associations = {
    orders: { type: "has_many", foreignKey: "completed_shift_id" },
    order_payments: { type: "has_many", foreignKey: "shift_id" },
    finance_transactions: { type: "has_many", foreignKey: "shift_id" },
  };

  @field("number") number;
  @field("open_user_id") open_user_id;
  @field("close_user_id") close_user_id;
  @json("open_cash_amounts", (v) => v) open_cash_amounts;
  @json("close_cash_amounts", (v) => v) close_cash_amounts;
  @json("actual_cash_amounts", (v) => v) actual_cash_amounts;
  @json("expected_cash_amounts", (v) => v) expected_cash_amounts;
  @date("opened_at") opened_at;
  @date("closed_at") closed_at;
  @readonly @date("created_at") created_at;
  @readonly @date("updated_at") updated_at;

  @children("orders") orders;
  @children("order_payments") order_payments;
  @children("finance_transactions") finance_transactions;
}
