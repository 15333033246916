export default {
  // semantic colors
  background: "#eceff1",
  surface: "rgb(255, 255, 255)",
  disabled: "rgba(0, 0, 0, 0.26)",
  border: "#D9D9D9",
  defaultCartItemColor: "#4682b4",
  disabledSurface: "#f5f5f5",
  disabledBorder: "#d9d9d9",

  // old
  white: "#ffffff",
  gainsboro: "#e0e0e0",
  nobel: "#999999",
  whisper: "#eeeeee",
  solitude: "#f0f2f5",
  lightGrey: "#d4d1d1",
  grey: "#888888",
  red: "#ff0000",
  green: "#008000",
  nightRider: "#313030",
  dodgerBlue: "#1890ff",
  dodgerBlueDark: "#0063cb",
  shuttleGrey: "#656667",
  aliceBlue: "#e6f7ff",
  lightSkyBlue: "#91d5ff",
  cornflowerBlue: "#4fa3f3",
  whiteSmoke: "#f5f5f5",
  black: "#222222",
  nevada: "#606467",

  statusColors: {
    received: "#e7707d",
    processing: "#28cacc",
    on_delivery: "#4682b4",
    ready: "#83d160",
    served: "#ffce45",
    completed: "#008000",
    cancelled: "#fb3e44",
  },
};
