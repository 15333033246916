/* global LocalDevices */

import { writeStorage } from "./useLocalStorage";

export const scanDevices = ({
  onProgress,
  skipRecognizeKDS,
} = {}) => {
  if (window.electron) {
    return window.electron.getDevices({
      skipRecognizeKDS,
      onProgress,
    });
  }
  if (window.cordova) {
    return LocalDevices.scan({
      onProgress: (devices) => onProgress?.(devices.filter((d) => d.type !== "unrecognized")),
    }).then((devices) => devices.filter((d) => d.type !== "unrecognized"));
  }
  return Promise.resolve([]);
};

export const getSavedPrinters = () => {
  const devices = JSON.parse(localStorage.getItem("devices")) || [];
  return devices.filter((device) => device.type.startsWith("printer"));
};

export const getSavedKDS = (kdsId) => {
  const devices = JSON.parse(localStorage.getItem("devices")) || [];
  return devices.find((device) => device.id === kdsId);
};

export const updateSavedDevice = (deviceId, data) => {
  const devices = JSON.parse(localStorage.getItem("devices")) || [];
  writeStorage("devices", devices.map((device) => ({
    ...device,
    ...(device.id === deviceId ? data : {}),
  })));
};

export const getSavedPOSTerminals = () => {
  const devices = JSON.parse(localStorage.getItem("devices")) || [];
  return devices.filter((device) => device.type.startsWith("pos"));
};
