import React from "react";
import _ from "lodash";
import { useObservable } from "rxjs-hooks";
import { map, switchMap } from "rxjs/operators";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DateTime } from "luxon";
import { Button, Row, Col, Space } from "antd";
import { Link } from "react-router-dom";

import { formatPrice } from "utils/currency";

import OrderLinesTable from "./OrderLinesTable";

const OrderDetailsTable = styled.table`
  width: 100%;
  font-size: 15px;
  line-height: 1.3;
  tr td:first-child {
    padding-left: 8px;
    font-weight: 500;
  }
`;

const PanelButton = styled(Button)`
  font-weight: 700;

  &.ant-btn {
    border-color: #000000a6;
    color: #000000a6;
  }
`;

const OrderDetailsPanel = ({ order, onCancelOrder, t, style }) => {
  const orderPrice = useObservable((_s, inputs$) => inputs$.pipe(
    switchMap(([o]) => o.order_price_updates.observe()),
    map((opuArr) => _.maxBy(opuArr, "id")),
  ), { order_lines_total: 0, service_percent: 0 }, [order]);
  const serviceFee = orderPrice.order_lines_total * orderPrice.service_percent;

  return (
    <Row style={style} gutter={[0, 8]} onClick={(e) => e.stopPropagation()}>
      <Col xs={24} lg={12}>
        <OrderLinesTable order={order} />
      </Col>
      <Col xs={12} lg={7}>
        <OrderDetailsTable>
          <colgroup>
            <col span="1" style={{ width: "45%" }} />
            <col span="1" style={{ width: "55%" }} />
          </colgroup>
          <tbody>
            <tr>
              <td>{t("OrderDetailsPanel.OrderTime")}:</td>
              <td>{DateTime.fromJSDate(order.time).toLocaleString(DateTime.TIME_SIMPLE)}</td>
            </tr>
            {orderPrice.discount > 0.000001 && (
              <tr>
                <td>{t("OrderDetailsPanel.Discount")}:</td>
                <td>{orderPrice.discount * 100}%</td>
              </tr>
            )}
            {orderPrice.service_percent > 0.000001 && (
              <tr>
                <td>{t("OrderDetailsPanel.ServicePercent")}:</td>
                <td>{formatPrice(serviceFee)} ({orderPrice.service_percent * 100}%)</td>
              </tr>
            )}
            {order.list_user && (
              <tr>
                <td>{t("OrderDetailsPanel.Cashier")}:</td>
                <td>{order.list_user}</td>
              </tr>
            )}
            {order.list_table_name && (
              <tr>
                <td>{t("OrderDetailsPanel.Table")}:</td>
                <td>{order.list_table_name}</td>
              </tr>
            )}
            {order.customer?.name && (
              <tr>
                <td>{t("OrderDetailsPanel.Name")}:</td>
                <td>{order.customer.name}</td>
              </tr>
            )}
            {order.customer?.phone_number && (
              <tr>
                <td>{t("OrderDetailsPanel.PhoneNumber")}:</td>
                <td>{order.customer.phone_number}</td>
              </tr>
            )}
          </tbody>
        </OrderDetailsTable>
      </Col>
      <Col xs={12} lg={5}>
        <Space direction="vertical">
          <Link to={`/cashier/${order.id}`}>
            <PanelButton ghost size="large">{t("OrderDetailsPanel.EditOrder")}</PanelButton>
          </Link>
          <PanelButton type="dashed" ghost size="large" onClick={() => onCancelOrder(order)}>
            {t("OrderDetailsPanel.CancelOrder")}
          </PanelButton>
        </Space>
      </Col>
    </Row>
  );
};

OrderDetailsPanel.propTypes = {
  order: PropTypes.object.isRequired,
  onCancelOrder: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default OrderDetailsPanel;
