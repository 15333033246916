import { useService } from "pos-service";
import { useEffect, useState } from "react";

const useSocketClients = () => {
  const { service } = useService();
  const [clients, setClients] = useState(service.kdsClientIds);

  useEffect(() => {
    const handleClients = (e) => setClients(e.detail);
    document.addEventListener("clients-changed", handleClients);
    return () => document.removeEventListener("clients-changed", handleClients);
  }, []);

  return clients;
};

export default useSocketClients;
