/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */

import React from "react";
import _ from "lodash";
import { DateTime } from "luxon";
import { Q } from "@nozbe/watermelondb";
import { notification } from "antd";
import IdGenerator from "@salempos/id-generator";
import { setGenerator } from "@nozbe/watermelondb/utils/common/randomId";
import { BehaviorSubject, from, of } from "rxjs";
import { switchMap, filter, distinct } from "rxjs/operators";

import i18n from "i18n";
import {
  ORDER_STATUS as OS,
  ORDER_LINE_STATUS as OLS,
  ORDER_PAYMENT_TYPE, ORDER_TYPE,
} from "constants/index";
import { getSavedKDS, getSavedPrinters, scanDevices, updateSavedDevice } from "utils/devices";
import { printKitchenCheck } from "utils/print";
import wss from "utils/webSocketServer";
import { doubleFetch } from "utils/helpers";

// eslint-disable-next-line no-unused-vars
import { Order } from "pos-service/db/models";

import createOfflinePosDb from "../db/createOfflinePosDb";

import SyncManager from "./SyncManager";
import logDebug from "./logDebug";
import GarbageCollector from "./GarbageCollector";

export default class LocalDbService {
  constructor(config, client) {
    this.db = createOfflinePosDb(config);
    this.config = config;
    this.idGenerator = new IdGenerator("pos", config.location.id);
    this.syncManager = new SyncManager(this, client);

    setGenerator(() => this.idGenerator.genId());

    if (process.env.NODE_ENV === "development") {
      window.db = this.db;
      window.Q = Q;
      window.DateTime = DateTime;
      window.client = client;
    }

    this.openOrders = new BehaviorSubject([]);
    this.shiftStatsOrders = new BehaviorSubject([]);
    this.openCashierShift = new BehaviorSubject();
    this.lastClosedShift = new BehaviorSubject();

    this.lastShiftOrderNumber = null;

    this.subscriptions = [];
    this.kdsClients = new Map();

    const cashPaymentMethodId = config.paymentMethods.find((pm) => pm.type === "cash")?.id;

    this.subscriber = this.db.get("cashier_shifts")
      .query()
      .observeWithColumns(["closed_at"])
      .subscribe((s) => {
        if (s.length === 0) {
          this.lastClosedShift.next(null);
          this.openCashierShift.next(null);
          return;
        }
        const shifts = _.orderBy(s, "id", "desc");
        const lastShift = shifts[0];
        if (lastShift.closed_at) {
          this.lastClosedShift.next(lastShift);
          this.openCashierShift.next(null);
        } else {
          this.openCashierShift.next(lastShift);
          const beforeShift = shifts[1];
          if (beforeShift?.closed_at) {
            this.lastClosedShift.next(beforeShift);
          } else if (beforeShift) {
            beforeShift.order_payments.fetch()
              .then((ops) => {
                const cashPayments = ops.filter((p) => p.payment_method_id === cashPaymentMethodId);
                const expectedCashAmounts = config.terminals.reduce((acc, terminal) => {
                  const cashRevenue = cashPayments.filter((p) => p.terminal_id === terminal.id)
                    .reduce((total, p) => total + (p.amount * (p.type === "payment" ? 1 : -1)), 0);
                  return {
                    ...acc,
                    [terminal.id]: `${cashRevenue + parseFloat(beforeShift.open_cash_amounts[terminal.id])}`,
                  };
                }, {});
                this.db.write(() => beforeShift.update((shift) => {
                  shift.closed_at = lastShift.opened_at;
                  shift.expected_cash_amounts = expectedCashAmounts;
                  shift.actual_cash_amounts = expectedCashAmounts;
                  shift.close_cash_amounts = lastShift.open_cash_amounts;
                  shift.close_user_id = lastShift.open_user_id;
                  shift._raw.updated_at = Date.now();
                })).then((shift) => this.lastClosedShift.next(shift));
              });
          }
        }
      });

    this.subscriber.add(this.openCashierShift$
      .pipe(
        switchMap((openShift) => (openShift ? this.db.get("orders").query(
          Q.or(
            Q.where("completed_shift_id", openShift.id),
            Q.where("completed_shift_id", null),
            Q.where("status", Q.notIn([OS.COMPLETED, OS.CANCELLED])),
          ),
        ).observeWithColumns(["list_price", "type"]) : of([]))),
      )
      .subscribe(this.shiftStatsOrders));

    this.subscriber.add(this.openCashierShift$
      .pipe(
        switchMap((openShift) => (openShift ? this.db.get("orders").query(
          Q.where("completed_shift_id", null),
          Q.where("status", OS.CANCELLED),
        ).observe() : of([]))),
      )
      .subscribe((orders) => {
        if (orders.length > 0) {
          const lastClosedAt = this.lastClosedShift.getValue()?.closed_at.getTime();
          this.db.write((writer) => writer.batch(
            orders.map((order) => {
              if (lastClosedAt && order.status_updated_at <= lastClosedAt) {
                return order.prepareDestroyPermanently();
              }
              return order.prepareUpdate((orderDoc) => {
                orderDoc.completed_shift.set(this.openCashierShift.getValue());
              });
            }),
          ));
        }
      }));

    this.subscriber.add(this.db.get("cashier_shifts")
      .query()
      .observeWithColumns(["closed_at"])
      .subscribe((shifts) => GarbageCollector.clean(shifts, this.db)));

    this.subscriber.add(
      this.db.get("orders").query(Q.where("completed_shift_id", null))
        .observeWithColumns(["updated_at"])
        .subscribe((v) => {
          const orders = v.sort((lhs, rhs) => lhs.created_at - rhs.created_at);
          this.openOrders.next(orders);
          this.subscriptions.forEach((s) => this.notifySubscriber(s.id, s.filters, s.conn));
          this.kdsClients.forEach((_filters, clientId) => this.notifyClientWSS(clientId));
        }),
    );

    this.subscriber.add(this.db.withChangesForTables(["orders"])
      .pipe(
        switchMap((changes) => from(changes?.map((c) => c.record) ?? [])),
        filter((order) => order.location_id === this.config.location.id),
        distinct((order) => `${order.id}-${order.updated_at}`),
        switchMap((order) => from(doubleFetch(order.order_lines).then((orderLines) => ({
          ...order._raw, // eslint-disable-line no-underscore-dangle
          display_number: order.display_number,
          order_lines: orderLines
            .sort((lhs, rhs) => lhs.id.localeCompare(rhs.id))
            .map((ol) => ({ ...ol._raw, modifiers: ol.modifiers })),
        })))),
      )
      .subscribe((order) => this.handleOrderChange(order)));

    if (config.terminal.main) {
      if (wss.available) {
        this.createWebSocketServer();
        scanDevices();
        setTimeout(() => {
          scanDevices();
        }, 20 * 60 * 1000);
      }
    }

    this.syncManager.start();
    LocalDbService.cleanUpOldDatabase();
  }

  get shiftStatsOrders$() {
    return this.shiftStatsOrders.asObservable();
  }

  get openOrders$() {
    return this.openOrders.asObservable();
  }

  get openCashierShift$() {
    return this.openCashierShift.asObservable();
  }

  get kdsClientIds() {
    return Array.from(this.kdsClients.keys());
  }

  get isShiftOpen() {
    return !!this.openCashierShift.getValue();
  }

  static cleanUpOldDatabase() {
    Object.keys(localStorage)
      .filter((key) => key.startsWith("_pouch_offline_pos_db"))
      .forEach((key) => {
        indexedDB.deleteDatabase(key);
        localStorage.removeItem(key);
      });
    localStorage.removeItem("_pouch_check_localstorage");
  }

  setClientFilters(clientId, filters) {
    this.kdsClients.set(clientId, filters);
  }

  shiftOrders$() {
    return this.openCashierShift.pipe(
      switchMap((shift) => (shift ? this.db.get("orders").query(
        Q.or(
          Q.where("completed_shift_id", shift.id),
          Q.where("completed_shift_id", null),
        ),
      ).observeWithColumns(["updated_at"]) : of([]))),
    );
  }

  static mapFilters(stations) {
    const { distribution, kitchen, products, receipt, ...workshop } = stations || {};
    const workshopIds = Object.entries(workshop)
      .filter(([_w, enabled]) => enabled) // eslint-disable-line no-unused-vars
      .map(([id]) => parseInt(id, 10));
    return {
      types: [kitchen && "kitchen", distribution && "distribution"].filter((e) => e),
      orderStatus: _.uniq([
        ...(kitchen ? ["received", "processing", "ready", "on_delivery", "served"] : []),
        ...(distribution ? ["received", "processing", "ready"] : []),
      ]),
      workshopIds,
      orderLineStatus: kitchen && !distribution
        ? ["received", "processing", "cancelled"] : null,
      hasOrderLineStatus: distribution && !kitchen
        ? ["processing", "ready", "served"] : null,
    };
  }

  createWebSocketServer() {
    wss.start(27123);
    wss.getClients().then((clients) => { // restore clients if server already started
      clients.forEach((client) => {
        const savedKDS = getSavedKDS(`kds-${client.id}`);
        const filters = LocalDbService.mapFilters(savedKDS.stations);
        this.kdsClients.set(client.id, filters);
      });
    });
    wss.subscribe("new-connection", ({ deviceId, ip }) => {
      // save client id and restore filters from local storage
      const savedKDS = getSavedKDS(`kds-${deviceId}`);
      const filters = LocalDbService.mapFilters(savedKDS.stations);
      this.kdsClients.set(deviceId, filters);

      // update kds ip if changed
      updateSavedDevice(`kds-${deviceId}`, { ip });

      // send orders to kds
      this.notifyClientWSS(deviceId);
      LocalDbService.sendKitchenSettings(deviceId);

      // emit 'clients changed' event we listening in device settings
      document.dispatchEvent(new CustomEvent("clients-changed", {
        detail: this.kdsClientIds,
      }));
    });
    wss.subscribe("close-connection", (closedClientId) => {
      this.kdsClients.delete(closedClientId);
      document.dispatchEvent(new CustomEvent("clients-changed", {
        detail: this.kdsClientIds,
      }));
    });
    wss.subscribe("order-lines-status-changed", (data) => {
      const { deviceId, actionId, payload: { orderId, orderLineIds, newStatus } } = data;
      this.changeOrderLinesStatus(orderId, orderLineIds, newStatus)
        .then(() => wss.send(deviceId, {
          action: "action-response",
          actionId,
          data: { status: "ok" },
        }))
        .catch((error) => wss.send(deviceId, {
          action: "action-response",
          actionId,
          data: { status: "error", message: error.message },
        }));
    });
  }

  cleanup() {
    this.syncManager.stop();
    this.subscriber.unsubscribe();
    if (wss.available) {
      wss.stop();
    }
  }

  clearDB() {
    this.db.write(() => this.db.unsafeResetDatabase());
  }

  getFilteredOrders(filters) {
    return Promise.all(this.openOrders.getValue().map((order) => {
      if (filters.orderStatus && !filters.orderStatus.includes(order.status)) {
        return null;
      }

      return doubleFetch(order.order_lines)
        .then((orderLines) => {
          const filteredOrderLines = orderLines.filter((orderLine) => {
            if (filters.orderLineStatus && !filters.orderLineStatus.includes(orderLine.status)) {
              return false;
            }
            if (!filters.types.includes("distribution") && filters.workshopIds) {
              const techCard = this.config.menuItemsById[orderLine.menu_item_id].tech_card;
              return techCard && filters.workshopIds.includes(techCard.workshop_id);
            }
            return true;
          });

          const hasLines = filteredOrderLines.some((orderLine) => (filters.hasOrderLineStatus
            ? filters.hasOrderLineStatus.includes(orderLine.status) : true));

          if (!hasLines) { return null; }

          const noLinesCanBeServedOrCooked = !filteredOrderLines.some((orderLine) => {
            let canBeCooked = false;
            if (filters.types.includes("kitchen")) {
              const techCard = this.config.menuItemsById[orderLine.menu_item_id].tech_card;
              const canCook = techCard && filters.workshopIds.includes(techCard.workshop_id);
              canBeCooked = canCook && ["received", "processing"].includes(orderLine.status);
            }
            let canBeServed = false;
            if (filters.types.includes("distribution")) {
              canBeServed = orderLine.status === "ready";
            }
            return canBeServed || canBeCooked;
          });

          if (noLinesCanBeServedOrCooked) { return null; }

          return {
            ...order._raw,
            display_number: order.display_number,
            order_lines: filteredOrderLines
              .sort((lhs, rhs) => lhs.id.localeCompare(rhs.id))
              .map((ol) => ({ ...ol._raw, modifiers: ol.modifiers })),
          };
        });
    })).then((orders) => orders.filter((o) => o));
  }

  notifySubscriber(id, filters, conn) {
    this.getFilteredOrders(filters)
      .then((orders) => conn.send({ id, data: orders }));
  }

  notifyClientWSS(deviceId) {
    const filters = this.kdsClients.get(deviceId);
    this.getFilteredOrders(filters).then((orders) => {
      const ordersWithAbilities = orders.map((order) => ({
        ...order,
        order_lines: order.order_lines.map((ol) => {
          const techCard = this.config.menuItemsById[ol.menu_item_id].tech_card;
          return {
            ...ol,
            canCook: filters.workshopIds.includes(techCard?.workshop_id),
            process_duration: techCard?.process_duration,
          };
        }),
      }));
      wss.send(deviceId, { action: "orders", data: ordersWithAbilities });
    });
  }

  static sendKitchenSettings(deviceId) {
    const savedKDS = getSavedKDS(`kds-${deviceId}`);
    if (savedKDS.settings) {
      wss.send(deviceId, {
        action: "settings",
        data: savedKDS.settings,
      });
    }
  }

  notifyKDSSettingsUpdate(id) {
    if (this.config.terminal.main) {
      LocalDbService.sendKitchenSettings(id.replace("kds-", ""));
    }
  }

  notifyKDSStationsUpdate(id) {
    if (this.config.terminal.main) {
      const savedKDS = getSavedKDS(id);
      const clientId = id.replace("kds-", "");
      const filters = LocalDbService.mapFilters(savedKDS.stations);
      this.setClientFilters(clientId, filters);
      this.notifyClientWSS(clientId);
    }
  }

  getOrderCancelNotificationData(order, filters) {
    if (filters.types.includes("distribution")) {
      return Promise.resolve({ shouldRepeat: false, order });
    }
    if (filters.types.includes("kitchen") && filters.workshopIds) {
      const filteredLines = order.order_lines.filter((orderLine) => {
        const techCard = this.config.menuItemsById[orderLine.menu_item_id].tech_card;
        return techCard && filters.workshopIds.includes(techCard.workshop_id);
      });

      if (filteredLines.length === 0) {
        return Promise.resolve(null);
      }

      return this.db.get("order_line_status_updates").query(
        Q.where("order_line_id", Q.oneOf(filteredLines.map((l) => l.id))),
        Q.where("status", Q.notEq(OLS.CANCELLED)),
      ).fetch().then((statusUpdates) => {
        const lastStatuses = _(statusUpdates)
          .groupBy("order_line_id").mapValues((arr) => _.maxBy(arr, "id")?.status)
          .value();
        const lines = filteredLines
          .map((ol) => ({ ...ol, lastStatus: lastStatuses[ol.id] || ol.status }))
          .filter((ol) => filters.orderLineStatus?.includes(ol.lastStatus) ?? true);

        if (lines.length > 0) {
          const shouldRepeat = lines.some((ol) => ol.lastStatus === OLS.PROCESSING);
          return { shouldRepeat, order: { ...order, order_lines: lines } };
        }
        return null;
      });
    }
    return Promise.resolve(null);
  }

  notifyKitchensOrderCancel(order) {
    this.subscriptions.forEach(({ id, filters, conn }) =>
      this.getOrderCancelNotificationData(order, filters).then((data) => {
        if (data) {
          conn.send({
            id: `${id}:messages`,
            data: {
              duration: data.shouldRepeat ? 0 : 10,
              type: "error",
              sound: "cancelOrderSound",
              message: i18n.t("posService.KitchenMessages.OrderCancelled", {
                number: data.order.display_number,
                orderLines: data.order.order_lines
                  .map((ol) => `  ${ol.count}x ${ol.display_name}`)
                  .join("\n"),
              }),
            },
          });
        }
      }));
  }

  notifyKitchensOrderCancelWSS(order) {
    Array.from(this.kdsClients.entries()).forEach(([clientId, filters]) =>
      this.getOrderCancelNotificationData(order, filters).then((data) => {
        if (data) {
          wss.send(clientId, {
            action: "notification",
            data: {
              shouldRepeat: data.shouldRepeat,
              type: "error",
              sound: "order_cancelled",
              message: i18n.t("posService.KitchenMessages.OrderCancelled", {
                number: data.order.display_number,
                orderLines: data.order.order_lines
                  .map((ol) => `  ${ol.count}x ${ol.display_name}`)
                  .join("\n"),
              }),
            },
          });
        }
      }));
  }

  static notifyPosOrderCancel(order) {
    notification.warning({
      message: i18n.t("posService.PosMessages.OrderCancelled", {
        number: order.display_number,
      }),
      description: <small>{i18n.t("posService.PosMessages.OrderCancelledDismiss")}</small>,
      key: `${order.id}-cancel`,
      top: 70,
      duration: 0,
      onClick: () => notification.close(`${order.id}-cancel`),
    });
  }

  orderLinesForKitchenPrinter(printer, order) {
    const orderLinesPromise = order.order_lines.fetch?.() ?? Promise.resolve(order.order_lines);
    return orderLinesPromise.then((orderLines) => orderLines.filter((orderLine) => {
      const menuItem = this.config.menuItemsById[orderLine.menu_item_id];
      return menuItem.product_id
        ? printer.stations.products
        : printer.stations[menuItem.tech_card.workshop_id];
    }));
  }

  handleOrderChange(order) {
    if (Date.now() - order.updated_at > 15 * 60 * 1000) return;

    if (order.status_updated_at === order.updated_at && order.status === OS.CANCELLED) {
      this.notifyKitchensOrderCancel(order);
      this.notifyKitchensOrderCancelWSS(order);

      this.db.get("order_cancels").query(Q.where("order_id", order.id)).fetch().then(([oc]) => {
        if (oc?.source !== "pos") {
          LocalDbService.notifyPosOrderCancel(order);
        }
      });
    }

    getSavedPrinters().filter((printer) => printer.stations?.kitchen).forEach((printer) => {
      this.orderLinesForKitchenPrinter(printer, order).then((printerOrderLines) => {
        if (printerOrderLines.length === 0) return;
        const printerOrder = { ...order, order_lines: printerOrderLines };

        if (order.created_at === order.updated_at) {
          printKitchenCheck(printer, printerOrder);
          return;
        }

        if (order.status_updated_at === order.updated_at && order.status === OS.CANCELLED) {
          printKitchenCheck(printer, printerOrder, "cancelled");
          return;
        }

        const hasChanges = printerOrderLines.find((ol) => ol.updated_at === order.updated_at
            && ((ol.status === OLS.CANCELLED && ol.status_updated_at === ol.updated_at) // cancelled
            || (ol.created_at === ol.updated_at))); // added
        if (hasChanges) {
          printKitchenCheck(printer, printerOrder, "changed");
        }
      });
    });
  }

  /**
   * Creates a new order with created status
   * @param {number} userId - Who accepted the order?
   * @param {string} orderData.type
   * @param {number} [orderData.tableId]
   * @param {number} orderData.discount
   * @param {number} orderData.servicePercent
   * @param {number} orderData.orderLinesTotal
   * @param {number} orderData.listPrice
   * @param {string} [orderData.notes]
   * @param {Object[]} orderData.orderLines
   * @param {number} orderData.orderLines[].menu_item_id
   * @param {string} orderData.orderLines[].display_name
   * @param {number} orderData.orderLines[].count
   * @param {number} orderData.orderLines[].price
   * @param {number} orderData.orderLines[].total_price
   * @param {string} [orderData.orderLines[].notes]
   * @param {Object[]} payments
   * @param {number} payments.amount
   * @param {number} payments.paymentMethodId
   *
   * @returns Promise<RxDocument>
   */
  createOrder(userId, orderData, payments = []) {
    logDebug("Creating new order", userId, orderData);

    return this.db.write((writer) => this.db.get("orders").query(
      Q.or(
        Q.where("completed_shift_id", this.openCashierShift.getValue()?.id),
        Q.where("completed_shift_id", null),
      ),
      Q.where("source", "pos"), Q.where("number", Q.gte(this.lastShiftOrderNumber || 0)),
    ).fetch().then((lastOrders) => {
      const lastOrder = _.maxBy(lastOrders, "id");
      if (lastOrder && Math.abs(lastOrder.list_price - orderData.listPrice) < 0.001
        && Date.now() - lastOrder.time < 1000) {
        return lastOrder;
      }

      const { tableId, notes } = orderData;
      const user = this.config.users.find((u) => u.id === userId);
      const table = tableId && this.config.tables.find((t) => t.id === tableId);
      const number = lastOrder ? lastOrder.number + 1 : 1;

      const ts = Date.now();

      const orderLines = orderData.orderLines.map((orderLine) => {
        const menuItem = this.config.menuItemsById[orderLine.menu_item_id];
        const status = menuItem.product_id || menuItem.tech_card.product_id
          ? OLS.READY : OLS.RECEIVED;
        return {
          ...(_.pick(orderLine,
            ["menu_item_id", "display_name", "count", "price",
              "total_price", "notes", "unit", "modifiers"])),
          status,
        };
      });

      const newOrderStatus = orderLines
        .every((ol) => [OLS.CANCELLED, OLS.READY].includes(ol.status)) ? OS.READY : OS.RECEIVED;

      const order = this.db.get("orders").prepareCreate((orderDoc) => {
        orderDoc.time = ts;
        orderDoc.type = orderData.type;
        orderDoc.location_id = this.config.location.id;
        orderDoc.source = "pos";
        orderDoc.number = number;
        orderDoc.user_id = userId;
        orderDoc.list_user = user.name;
        orderDoc.table_id = tableId;
        orderDoc.list_table_name = table && table.name;
        orderDoc.status = newOrderStatus;
        orderDoc.status_updated_at = ts;
        orderDoc.list_price = orderData.listPrice;
        orderDoc.notes = notes;
        orderDoc._raw.created_at = ts;
        orderDoc._raw.updated_at = ts;
      });

      return writer.batch([
        order,
        ...orderLines.flatMap((olData) => {
          const orderLine = this.db.get("order_lines").prepareCreate((orderLineDoc) => {
            orderLineDoc.order.set(order);
            ["menu_item_id", "display_name", "count", "price",
              "total_price", "notes", "unit", "modifiers"].forEach((key) => {
              orderLineDoc[key] = olData[key];
            });
            orderLineDoc.status = olData.status;
            orderLineDoc.status_updated_at = ts;
            orderLineDoc._raw.created_at = ts;
            orderLineDoc._raw.updated_at = ts;
          });
          return [
            orderLine,
            this.db.get("order_line_status_updates").prepareCreate((olsu) => {
              olsu.order_line.set(orderLine);
              olsu.status = olData.status;
              olsu.user_id = userId;
              olsu.terminal_id = this.config.terminal.id;
              olsu._raw.created_at = ts;
            }),
          ];
        }),
        this.db.get("order_status_updates").prepareCreate((osu) => {
          osu.order.set(order);
          osu.status = newOrderStatus;
          osu.user_id = userId;
          osu.terminal_id = this.config.terminal.id;
          osu._raw.created_at = ts;
        }),
        this.db.get("order_price_updates").prepareCreate((opu) => {
          opu.order.set(order);
          opu.message = newOrderStatus;
          opu.order_lines_total = orderData.orderLinesTotal;
          opu.discount = orderData.discount;
          opu.service_percent = orderData.servicePercent;
          opu.delivery_price = 0;
          opu.list_price = orderData.listPrice;
          opu.user_id = userId;
          opu._raw.created_at = ts;
        }),
        ...payments.map((payment) => this.db.get("order_payments").prepareCreate((op) => {
          op.order.set(order);
          op.amount = payment.amount;
          op.payment_method_id = payment.paymentMethodId;
          op.type = ORDER_PAYMENT_TYPE.PAYMENT;
          op.user_id = userId;
          op.terminal_id = this.config.terminal.id;
          op.metadata = payment.metadata;
          op.shift.set(this.openCashierShift.getValue());
          op._raw.created_at = ts;
        })),
      ])
        .then(() => this.db.get("orders").find(order.id))
        .then((newOrder) => {
          this.lastShiftOrderNumber = newOrder.number;
          return newOrder;
        });
    }));
  }

  /**
   * Adds a payments to the order
   * @param {number} userId - Who accepted the payment?
   * @param {Order} order order document
   * @param {Object[]} payments
   * @param {number} payments.amount
   * @param {number} payments.paymentMethodId
   *
   * @returns Promise<RxDocument>
   */
  payOrder(userId, order, payments) {
    logDebug("New order payment", userId, order, payments);

    return this.db.write((writer) => {
      if (order.status === OS.COMPLETED || order.status === OS.CANCELLED) {
        throw new Error(i18n.t("posService.Errors.OrderAlreadyCompleted"));
      }

      return order.order_payments.fetch().then((currentOrderPayments) => {
        const newLast = _.last(payments);
        const existingLast = _.maxBy(currentOrderPayments, "id");

        if (newLast && existingLast && Math.abs(newLast.amount - existingLast.amount) < 0.001
          && newLast.paymentMethodId === existingLast.payment_method_id
          && Date.now() - existingLast.created_at < 1000) {
          return order;
        }

        const ts = Date.now();
        return writer.batch([
          ...payments.map((payment) => this.db.get("order_payments").prepareCreate((op) => {
            op.order.set(order);
            op.amount = payment.amount;
            op.payment_method_id = payment.paymentMethodId;
            op.type = ORDER_PAYMENT_TYPE.PAYMENT;
            op.user_id = userId;
            op.terminal_id = this.config.terminal.id;
            op.metadata = payment.metadata;
            op.shift.set(this.openCashierShift.getValue());
            op._raw.created_at = ts;
          })),
          payments.length > 0 && order.prepareUpdate((orderDoc) => {
            orderDoc._raw.updated_at = ts;
          }),
        ])
          .then(() => this.db.get("orders").find(order.id));
      });
    });
  }

  /**
   * Refund order
   * @param {number} userId - Who accepted the payment?
   * @param {Order} order order document
   * @param {Object[]} refunds
   * @param {number} refunds.amount
   * @param {number} refunds.paymentMethodId
   *
   * @returns Promise<RxDocument>
   */
  refundOrder(userId, order, refunds) {
    logDebug("Refunding the order", userId, order, refunds);

    return this.db.write((writer) => order.order_payments.fetch().then((currentOrderPayments) => {
      const newLast = _.last(refunds);
      const existingLast = _.maxBy(currentOrderPayments, "id");

      if (newLast && existingLast && Math.abs(newLast.amount - existingLast.amount) < 0.001
          && newLast.paymentMethodId === existingLast.payment_method_id
          && Date.now() - existingLast.created_at < 1000) {
        return order;
      }

      const ts = Date.now();
      return writer.batch([
        ...refunds.map((payment) => this.db.get("order_payments").prepareCreate((op) => {
          op.order.set(order);
          op.amount = payment.amount;
          op.payment_method_id = payment.paymentMethodId;
          op.type = ORDER_PAYMENT_TYPE.REFUND;
          op.user_id = userId;
          op.terminal_id = this.config.terminal.id;
          op.shift.set(this.openCashierShift.getValue());
          op._raw.created_at = ts;
        })),
        refunds.length > 0 && order.prepareUpdate((orderDoc) => {
          orderDoc._raw.updated_at = ts;
        }),
      ]);
    }));
  }

  /**
   * Creates a new order with created status
   * @param {number} userId - Who is making the change?
   * @param {Order} order order document
   * @param {Object} changes changes object
   * @param {string} changes.type
   * @param {string} [changes.notes]
   * @param {number} [changes.tableId]
   * @param {number} changes.discount
   * @param {number} changes.servicePercent
   * @param {number} changes.orderLinesTotal
   * @param {number} changes.deliveryPrice
   * @param {number} changes.listPrice
   * @param {Object[]} changes.newOrderLines // might be empty
   * @param {number} changes.newOrderLines[].menu_item_id
   * @param {string} changes.newOrderLines[].display_name
   * @param {number} changes.newOrderLines[].count
   * @param {number} changes.newOrderLines[].price
   * @param {number} changes.newOrderLines[].total_price
   * @param {string} [changes.newOrderLines[].notes]
   * @param {Object[]} payments
   * @param {number} payments.amount
   * @param {number} payments.paymentMethodId
   *
   * @returns Promise<RxDocument>
   */
  changeOrder(userId, order, changes, payments) {
    logDebug("Changing the order", userId, order, changes, payments);

    return this.db.write((writer) => {
      if (order.status === OS.COMPLETED || order.status === OS.CANCELLED) {
        throw new Error(i18n.t("posService.Errors.OrderAlreadyCompleted"));
      }

      return Promise.all([order.order_price_updates.fetch(), order.order_payments.fetch()])
        .then(([priceUpdates, orderPayments]) => {
          const orderPrice = _.maxBy(priceUpdates, "id");

          const { discount, service_percent, list_price } = orderPrice;

          const messages = [order.status];
          if (Math.abs(discount - changes.discount) > 0.001) messages.push("discount");
          if (Math.abs(service_percent - changes.servicePercent) > 0.001) {
            messages.push("service percent");
          }
          if (changes.newOrderLines.length > 0) messages.push("added order lines");
          else if (Math.abs(list_price - changes.listPrice) > 0.0001) messages.push("list price");

          const justChanged = Date.now() - orderPrice.created_at < 1000;
          // if it's duplicated, then list price won't change
          const hasMessages = messages.length > (justChanged ? 2 : 1);
          const hasChanges = hasMessages || changes.type !== order.type
            || changes.tableId !== order.table_id || changes.notes !== order.notes;

          const newLastPayment = _.last(payments);
          const existingLastPayment = _.maxBy(orderPayments, "id");

          const hasNewPayments = newLastPayment && (!existingLastPayment || !(
            Math.abs(newLastPayment.amount - existingLastPayment.amount) < 0.001
              && newLastPayment.paymentMethodId === existingLastPayment.payment_method_id
              && Date.now() - existingLastPayment.created_at < 1000
          ));

          if (!(hasChanges || hasNewPayments)) {
            return order;
          }

          const newOrderLines = changes.newOrderLines.map((orderLine) => {
            const menuItem = this.config.menuItemsById[orderLine.menu_item_id];
            const status = menuItem.product_id || menuItem.tech_card.product_id
              ? OLS.READY : OLS.RECEIVED;
            return {
              ...(_.pick(orderLine,
                ["menu_item_id", "display_name", "count", "price",
                  "total_price", "notes", "unit", "modifiers"])),
              status,
            };
          });

          let newOrderStatus = null;
          if (newOrderLines.length > 0 && order.status !== OS.PROCESSING) {
            const allLinesReady = newOrderLines.every((ol) => ol.status === OLS.READY);
            if (allLinesReady && order.status === OS.SERVED) {
              newOrderStatus = OS.READY;
            }
            if (!allLinesReady && [OS.READY, OS.SERVED].includes(order.status)) {
              newOrderStatus = OS.PROCESSING;
            }
          }

          const { tableId, notes } = changes;
          const table = tableId && this.config.tables.find((t) => t.id === tableId);

          const ts = Date.now();

          return writer.batch([
            ...newOrderLines.flatMap((olData) => {
              const orderLine = this.db.get("order_lines").prepareCreate((orderLineDoc) => {
                orderLineDoc.order.set(order);
                ["menu_item_id", "display_name", "count", "price",
                  "total_price", "notes", "unit", "modifiers"].forEach((key) => {
                  orderLineDoc[key] = olData[key];
                });
                orderLineDoc.status = olData.status;
                orderLineDoc.status_updated_at = ts;
                orderLineDoc._raw.created_at = ts;
                orderLineDoc._raw.updated_at = ts;
              });
              return [
                orderLine,
                this.db.get("order_line_status_updates").prepareCreate((olsu) => {
                  olsu.order_line.set(orderLine);
                  olsu.status = olData.status;
                  olsu.user_id = userId;
                  olsu.terminal_id = this.config.terminal.id;
                  olsu._raw.created_at = ts;
                }),
              ];
            }),
            newOrderStatus && this.db.get("order_status_updates").prepareCreate((osu) => {
              osu.order.set(order);
              osu.status = newOrderStatus;
              osu.user_id = userId;
              osu.terminal_id = this.config.terminal.id;
              osu._raw.created_at = ts;
            }),
            messages.length > 1 && this.db.get("order_price_updates").prepareCreate((opu) => {
              opu.order.set(order);
              opu.message = messages.join(", ");
              opu.order_lines_total = changes.orderLinesTotal;
              opu.discount = changes.discount;
              opu.service_percent = changes.servicePercent;
              opu.delivery_price = changes.deliveryPrice;
              opu.list_price = changes.listPrice;
              opu.user_id = userId;
              opu._raw.created_at = ts;
            }),
            ...payments.map((payment) => this.db.get("order_payments").prepareCreate((op) => {
              op.order.set(order);
              op.amount = payment.amount;
              op.payment_method_id = payment.paymentMethodId;
              op.type = ORDER_PAYMENT_TYPE.PAYMENT;
              op.user_id = userId;
              op.terminal_id = this.config.terminal.id;
              op.shift.set(this.openCashierShift.getValue());
              op._raw.created_at = ts;
            })),
            order.prepareUpdate((orderDoc) => {
              orderDoc.type = changes.type;
              orderDoc.table_id = tableId;
              orderDoc.notes = notes;
              orderDoc.list_table_name = table && table.name;
              if (messages.length > 1) {
                orderDoc.list_price = changes.listPrice;
              }
              if (newOrderStatus) {
                orderDoc.status = newOrderStatus;
                orderDoc.status_updated_at = ts;
              }
              orderDoc._raw.updated_at = ts;
            }),
          ])
            .then(() => this.db.get("orders").find(order.id));
        });
    });
  }

  /**
   * Change the status of the order
   * @param {number} userId - Who changed order status?
   * @param {Order} order order document
   * @param {string} newStatus
   *
   * @returns Promise<RxDocument>
   */
  changeOrderStatus(userId, order, newStatus) {
    logDebug("Changing order status", userId, order, newStatus);

    return this.db.write((writer) => {
      if (order.status === OS.COMPLETED || order.status === OS.CANCELLED) {
        throw new Error(i18n.t("posService.Errors.OrderAlreadyCompleted"));
      }

      if (order.status === newStatus) {
        return order;
      }

      const ts = Date.now();
      return writer.batch([
        this.db.get("order_status_updates").prepareCreate((osu) => {
          osu.order.set(order);
          osu.status = newStatus;
          osu.user_id = userId;
          osu.terminal_id = this.config.terminal.id;
          osu._raw.created_at = ts;
        }),
        order.prepareUpdate((orderDoc) => {
          orderDoc.status = newStatus;
          orderDoc.status_updated_at = ts;
          orderDoc._raw.updated_at = ts;
          if ([OS.COMPLETED, OS.CANCELLED].includes(newStatus)) {
            orderDoc.completed_shift.set(this.openCashierShift.getValue());
          }
        }),
      ]);
    });
  }

  /**
   * Cancel order line
   * @param {number} userId - Who accepted the payment?
   * @param {Order} order order document
   * @param {string} orderLineId
   * @param {Object} cancelData
   * @param {string} cancelData.reason
   * @param {Object[]} cancelData.refunds
   * @param {number} cancelData.refunds.amount
   * @param {number} cancelData.refunds.paymentMethodId
   *
   * @returns Promise<RxDocument>
   */
  cancelOrderLine(userId, order, orderLineId, cancelData) {
    logDebug("Cancelling order line", userId, order, orderLineId, cancelData);

    return this.db.write((writer) => {
      if (order.status === OS.COMPLETED || order.status === OS.CANCELLED) {
        throw new Error(i18n.t("posService.Errors.OrderAlreadyCompleted"));
      }

      return order.order_lines.fetch().then((orderLines) => {
        const orderLine = orderLines.find((ol) => ol.id === orderLineId);
        if (orderLine.status === OLS.CANCELLED) {
          return order;
        }

        return order.order_price_updates.fetch().then((priceUpdates) => {
          const orderPrice = _.maxBy(priceUpdates, "id");

          const ts = Date.now();

          const isProduct = !!this.config.menuItemsById[orderLine.menu_item_id].product_id;
          const notSpent = orderLine.status === OLS.RECEIVED
            || (isProduct && orderLine.status === OLS.READY);

          const allRemainingLinesReady = orderLines
            .filter((ol) => ol.id !== orderLineId)
            .every((ol) => [OLS.READY, OLS.CANCELLED].includes(ol.status));

          const newListPrice = orderPrice.list_price - orderLine.total_price
            + orderLine.total_price * orderPrice.service_percent
            - orderLine.total_price * orderPrice.discount;

          const makeOrderReady = [OS.RECEIVED, OS.PROCESSING].includes(order.status)
            && allRemainingLinesReady;

          return writer.batch([
            this.db.get("order_line_cancels").prepareCreate((olc) => {
              olc.order_line.set(orderLine);
              olc.reason = cancelData.reason;
              olc.refund = cancelData.refunds.length > 0;
              olc.write_off = !notSpent;
              olc.user_id = userId;
              olc._raw.created_at = ts;
            }),
            this.db.get("order_line_status_updates").prepareCreate((olsu) => {
              olsu.order_line.set(orderLine);
              olsu.status = OLS.CANCELLED;
              olsu.user_id = userId;
              olsu.terminal_id = this.config.terminal.id;
              olsu._raw.created_at = ts;
            }),
            orderLine.prepareUpdate((orderLineDoc) => {
              orderLineDoc.status = OLS.CANCELLED;
              orderLineDoc.status_updated_at = ts;
              orderLineDoc._raw.updated_at = ts;
            }),
            this.db.get("order_price_updates").prepareCreate((opu) => {
              opu.order.set(order);
              opu.message = [order.status, `removed: ${orderLine.display_name}`].join(", ");
              opu.order_lines_total = orderPrice.order_lines_total - orderLine.total_price;
              opu.discount = orderPrice.discount;
              opu.service_percent = orderPrice.service_percent;
              opu.delivery_price = orderPrice.delivery_price;
              opu.list_price = newListPrice;
              opu.user_id = userId;
              opu._raw.created_at = ts;
            }),
            ...cancelData.refunds.map((refund) => this.db.get("order_payments")
              .prepareCreate((op) => {
                op.order.set(order);
                op.amount = refund.amount;
                op.payment_method_id = refund.paymentMethodId;
                op.type = ORDER_PAYMENT_TYPE.REFUND;
                op.user_id = userId;
                op.terminal_id = this.config.terminal.id;
                op.shift.set(this.openCashierShift.getValue());
                op._raw.created_at = ts;
              })),
            makeOrderReady && this.db.get("order_status_updates").prepareCreate((osu) => {
              osu.order.set(order);
              osu.status = OS.READY;
              osu.user_id = userId;
              osu.terminal_id = this.config.terminal.id;
              osu._raw.created_at = ts;
            }),
            order.prepareUpdate((orderDoc) => {
              if (makeOrderReady) {
                orderDoc.status = OS.READY;
                orderDoc.status_updated_at = ts;
              }
              orderDoc.list_price = newListPrice;
              orderDoc._raw.updated_at = ts;
            }),
          ]);
        });
      });
    });
  }

  /**
   * Cancel order
   * @param {number} userId - Who accepted the payment?
   * @param {Order} order order document
   * @param {Object} cancelData
   * @param {string} cancelData.reason
   * @param {Object[]} cancelData.refunds
   * @param {number} cancelData.refunds.amount
   * @param {number} cancelData.refunds.paymentMethodId
   *
   * @returns Promise<RxDocument>
   */
  cancelOrder(userId, order, cancelData) {
    logDebug("Cancelling the order", userId, order, cancelData);

    return this.db.write((writer) => {
      if (order.status === OS.COMPLETED || order.status === OS.CANCELLED) {
        throw new Error(i18n.t("posService.Errors.OrderAlreadyCompleted"));
      }

      const ts = Date.now();

      return order.order_lines.fetch().then((orderLines) => writer.batch([
        ...orderLines.flatMap((orderLine) => {
          if (orderLine.status === OLS.CANCELLED) {
            return [];
          }

          const isProduct = !!this.config.menuItemsById[orderLine.menu_item_id].product_id;
          const notSpent = orderLine.status === OLS.RECEIVED
            || (isProduct && orderLine.status === OLS.READY);

          return [
            this.db.get("order_line_cancels").prepareCreate((olc) => {
              olc.order_line.set(orderLine);
              olc.reason = "order cancelled";
              olc.refund = cancelData.refunds.length > 0;
              olc.write_off = !notSpent;
              olc.user_id = userId;
              olc._raw.created_at = ts;
            }),
            this.db.get("order_line_status_updates").prepareCreate((olsu) => {
              olsu.order_line.set(orderLine);
              olsu.status = OLS.CANCELLED;
              olsu.user_id = userId;
              olsu.terminal_id = this.config.terminal.id;
              olsu._raw.created_at = ts;
            }),
            orderLine.prepareUpdate((orderLineDoc) => {
              orderLineDoc.status = OLS.CANCELLED;
              orderLineDoc.status_updated_at = ts;
              orderLineDoc._raw.updated_at = ts;
            }),
          ];
        }),
        ...cancelData.refunds.map((refund) => this.db.get("order_payments").prepareCreate((op) => {
          op.order.set(order);
          op.amount = refund.amount;
          op.payment_method_id = refund.paymentMethodId;
          op.type = ORDER_PAYMENT_TYPE.REFUND;
          op.user_id = userId;
          op.terminal_id = this.config.terminal.id;
          op.shift.set(this.openCashierShift.getValue());
          op._raw.created_at = ts;
        })),
        this.db.get("order_cancels").prepareCreate((oc) => {
          oc.order.set(order);
          oc.reason = cancelData.reason;
          oc.refund = cancelData.refunds.length > 0;
          oc.user_id = userId;
          oc.source = "pos";
          oc._raw.created_at = ts;
        }),
        this.db.get("order_status_updates").prepareCreate((osu) => {
          osu.order.set(order);
          osu.status = OS.CANCELLED;
          osu.user_id = userId;
          osu.terminal_id = this.config.terminal.id;
          osu._raw.created_at = ts;
        }),
        order.prepareUpdate((orderDoc) => {
          orderDoc.status = OS.CANCELLED;
          orderDoc.status_updated_at = ts;
          orderDoc._raw.updated_at = ts;
          orderDoc.completed_shift.set(this.openCashierShift.getValue());
        }),
      ]))
        .then(() => this.db.get("orders").find(order.id));
    });
  }

  /**
   * Change the status of the order line
   * @param {string} orderId
   * @param {string} orderLineId
   * @param {string} newStatus
   *
   * @returns Promise<RxDocument>
   */
  changeOrderLinesStatus(orderId, orderLineIds, newStatus) {
    logDebug("Changing order lines status", orderId, orderLineIds, newStatus);

    return this.db.write((writer) => this.db.get("orders").find(orderId).then((order) => {
      if (order.status === OS.COMPLETED || order.status === OS.CANCELLED) {
        throw new Error(i18n.t("posService.Errors.OrderAlreadyCompleted"));
      }

      const ts = Date.now();

      return order.order_lines.fetch().then((orderLines) => {
        const orderLineStatusUpdates = [];
        const orderStatusUpdates = [];

        switch (newStatus) {
          case OLS.PROCESSING: {
            const startedLines = orderLines.filter((orderLine) =>
              orderLineIds.includes(orderLine.id) && orderLine.status === OLS.RECEIVED);
            orderLineStatusUpdates.push(...startedLines.map((orderLine) => ({
              orderLineId: orderLine.id, newStatus,
            })));
            if (startedLines.length > 0 && order.status === OS.RECEIVED) {
              orderStatusUpdates.push(OS.PROCESSING);
            }
            break;
          }
          case OLS.READY: {
            const readyLines = orderLines.filter((orderLine) =>
              orderLineIds.includes(orderLine.id) && orderLine.status === OLS.PROCESSING);
            orderLineStatusUpdates.push(...readyLines.map((orderLine) => ({
              orderLineId: orderLine.id, newStatus,
            })));
            const otherStatuses = _.difference(orderLines, readyLines)
              .map((orderLine) => orderLine.status);
            const allLinesReady = otherStatuses
              .every((s) => [OLS.READY, OLS.SERVED, OLS.CANCELLED].includes(s));
            if (order.status === OS.PROCESSING && allLinesReady) {
              orderStatusUpdates.push(OS.READY);
            }
            break;
          }
          case OLS.SERVED: {
            const servedLines = orderLines.filter((orderLine) =>
              orderLineIds.includes(orderLine.id) && orderLine.status === OLS.READY);
            orderLineStatusUpdates.push(...servedLines.map((orderLine) => ({
              orderLineId: orderLine.id, newStatus,
            })));
            const otherStatuses = _.difference(orderLines, servedLines)
              .map((orderLine) => orderLine.status);
            const allLinesServed = otherStatuses
              .every((s) => [OLS.SERVED, OLS.CANCELLED].includes(s));
            if (order.status === OS.READY && allLinesServed) {
              orderStatusUpdates.push(order.type === ORDER_TYPE.DELIVERY
                ? OS.ON_DELIVERY : OS.SERVED);
            }
            break;
          }
          default: break;
        }

        if (orderStatusUpdates[0] === OS.SERVED) {
          return order.order_payments.fetch().then((orderPayments) => {
            const paid = orderPayments.reduce((amount, curr) =>
              amount + (curr.type === ORDER_PAYMENT_TYPE.PAYMENT ? curr.amount : -curr.amount), 0);
            const toPay = order.list_price - paid;
            if (Math.abs(toPay) < 0.0001) {
              orderStatusUpdates.push(OS.COMPLETED);
            }
            return { orderLines, orderStatusUpdates, orderLineStatusUpdates };
          });
        }
        return { orderLines, orderStatusUpdates, orderLineStatusUpdates };
      }).then(({ orderLines, orderStatusUpdates, orderLineStatusUpdates }) => writer.batch([
        // eslint-disable-next-line no-shadow
        ...orderLineStatusUpdates.flatMap(({ orderLineId, newStatus }) => {
          const orderLine = orderLines.find((ol) => ol.id === orderLineId);
          return [
            this.db.get("order_line_status_updates").prepareCreate((olsu) => {
              olsu.order_line.set(orderLine);
              olsu.status = newStatus;
              olsu.terminal_id = this.config.terminal.id;
              olsu._raw.created_at = ts;
            }),
            orderLine.prepareUpdate((orderLineDoc) => {
              orderLineDoc.status = newStatus;
              orderLineDoc.status_updated_at = ts;
              orderLineDoc._raw.updated_at = ts;
            }),
          ];
        }),
        ...orderStatusUpdates.map((status) => this.db.get("order_status_updates").prepareCreate(
          (osu) => {
            osu.order.set(order);
            osu.status = status;
            osu.terminal_id = this.config.terminal.id;
            osu._raw.created_at = ts;
          },
        )),
        (orderStatusUpdates.length > 0 || orderLineStatusUpdates.length > 0) && order.prepareUpdate(
          (orderDoc) => {
            if (orderStatusUpdates.length > 0) {
              const newOrderStatus = _.last(orderStatusUpdates);
              orderDoc.status = newOrderStatus;
              orderDoc.status_updated_at = ts;
              if (newOrderStatus === OS.COMPLETED) {
                orderDoc.completed_shift.set(this.openCashierShift.getValue());
              }
            }
            orderDoc._raw.updated_at = ts;
          },
        ),
      ]));
    }));
  }

  /**
   * Assign courier to the order
   * @param {Order} order order document
   * @param {number} courierId
   *
   * @returns Promise<RxDocument>
   */
  assignCourier(userId, order, courierId) {
    logDebug("Assigning new courier", order, courierId);

    return this.db.write((writer) => {
      if (order.status === OS.COMPLETED || order.status === OS.CANCELLED) {
        throw new Error(i18n.t("posService.Errors.OrderAlreadyCompleted"));
      }

      if (order.courier_id === courierId) {
        return order;
      }

      const ts = Date.now();
      return writer.batch([
        this.db.get("courier_assigns").prepareCreate((ca) => {
          ca.order.set(order);
          ca.courier_id = courierId;
          ca.user_id = userId;
          ca._raw.created_at = ts;
        }),
        order.prepareUpdate((orderDoc) => {
          orderDoc.courier_id = courierId;
          orderDoc._raw.updated_at = ts;
        }),
      ]);
    });
  }

  /**
   * Open shift
   * @param {number} userId user id
   * @param {Object} openCashAmounts open cash amounts by terminals
   *
   * @returns Promise<RxDocument>
   */
  openShift(userId, openCashAmounts, correction) {
    return this.db.write((writer) => {
      const shiftCollection = this.db.get("cashier_shifts");
      const ts = Date.now();
      return shiftCollection.query()
        .fetch()
        .then((shifts) => writer.batch([
          Math.abs(correction.amount) > 0.001 && this.db.get("finance_transactions").prepareCreate((t) => {
            t.type = correction.amount > 0 ? "expense" : "income";
            t.description = correction.reason;
            t.category_id = this.config.merchant.corrections_finance_category_id;
            t.account_id = this.config.location.cash_payment_account_id;
            t.amount = Math.abs(correction.amount);
            t.time = ts;
            t.user_id = userId;
            t.terminal_id = this.config.terminal.id;
            t._raw.created_at = ts;
            t._raw.updated_at = ts;
          }),
          shiftCollection.prepareCreate((shift) => {
            const lastShift = _.maxBy(shifts, "id");
            shift.number = (lastShift?.number || 0) + 1;
            shift.open_user_id = userId;
            shift.open_cash_amounts = openCashAmounts;
            shift.opened_at = ts;
            shift._raw.updated_at = ts;
            shift._raw.created_at = ts;
          }),
        ]));
    });
  }

  /**
   * Close shift
   * @param {number} userId user id
   * @param {Object} actualCashAmounts actual cash amounts by terminals
   * @param {Object} closeCashAmounts close cash amounts by terminals
   *
   * @returns Promise<RxDocument>
   */
  closeShift(
    userId, actualCashAmounts, closeCashAmounts,
    expectedCashAmounts, incassation, correction,
  ) {
    const ts = Date.now();
    return this.db.write((writer) => writer.batch([
      this.openCashierShift.getValue().prepareUpdate((shift) => {
        shift.closed_at = ts;
        shift.close_user_id = userId;
        shift.actual_cash_amounts = actualCashAmounts;
        shift.close_cash_amounts = closeCashAmounts;
        shift.expected_cash_amounts = expectedCashAmounts;
        shift._raw.updated_at = ts;
      }),
      Math.abs(correction.amount) > 0.001 && this.db.get("finance_transactions").prepareCreate((t) => {
        t.type = correction.amount > 0 ? "expense" : "income";
        t.description = correction.reason;
        t.category_id = this.config.merchant.corrections_finance_category_id;
        t.account_id = this.config.location.cash_payment_account_id;
        t.amount = Math.abs(correction.amount);
        t.time = ts;
        t.user_id = userId;
        t.terminal_id = this.config.terminal.id;
        t.shift.set(this.openCashierShift.getValue());
        t._raw.created_at = ts;
        t._raw.updated_at = ts;
      }),
      incassation > 0 && this.db.get("finance_transactions").prepareCreate((t) => {
        t.type = "transfer";
        t.account_id = this.config.location.cash_payment_account_id;
        t.amount = incassation;
        t.transfer_to_account_id = this.config.location.incassation_account_id;
        t.transfer_to_amount = incassation;
        t.time = ts;
        t.user_id = userId;
        t.terminal_id = this.config.terminal.id;
        t.shift.set(this.openCashierShift.getValue());
        t._raw.created_at = ts;
        t._raw.updated_at = ts;
      }),
    ]));
  }

  createTransaction(userId, type, categoryId, description, amount) {
    return this.db.write(() => this.db.get("finance_transactions").create((t) => {
      const ts = Date.now();
      t.type = type;
      t.category_id = categoryId;
      t.description = description;
      t.account_id = this.config.location.cash_payment_account_id;
      t.amount = amount;
      t.time = ts;
      t.user_id = userId;
      t.terminal_id = this.config.terminal.id;
      t.shift.set(this.openCashierShift.getValue());
      t._raw.created_at = ts;
      t._raw.updated_at = ts;
    }));
  }
}
