import React from "react";
import PropTypes from "prop-types";
import { formatPrice } from "utils/currency";
import styled from "styled-components";
import { Col, Row } from "antd";
import colors from "theme/colors";

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  margin-top: 8px;
  background: ${colors.surface};
  padding: 10px 10px 8px;
  border-radius: 8px;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 500;
  margin-right: 6px;
`;

const Price = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

const PriceBlock = ({
  t,
  orderLinesTotal,
  toPay,
  serviceFee,
  discountAmount,
  deliveryPrice,
  discount,
  servicePercent,
  paid,
}) => (
  <Container>
    {(serviceFee > 0 || discountAmount > 0 || paid > 0) && (
      <Row style={{ width: "100%" }}>
        <Col xs={12}>
          <Title>{t("cashier.Cashier.CartPanel.ButtonsBlock.Amount")}:</Title>
        </Col>
        <Col xs={12} style={{ textAlign: "end" }}>
          <Price>{formatPrice(orderLinesTotal)}</Price>
        </Col>
      </Row>
    )}
    {serviceFee > 0 && (
      <Row style={{ width: "100%" }}>
        <Col xs={16}>
          <Title>
            {t("cashier.Cashier.CartPanel.ButtonsBlock.ServicePercent")}{" "}
            ({(Math.round(servicePercent * 100 * 100) / 100)}%):
          </Title>
        </Col>
        <Col xs={8} style={{ textAlign: "end" }}>
          <Price>+{formatPrice(serviceFee)}</Price>
        </Col>
      </Row>
    )}
    {discountAmount > 0 && (
      <Row style={{ width: "100%" }}>
        <Col xs={13}>
          <Title>
            {t("cashier.Cashier.CartPanel.ButtonsBlock.Discount")}{" "}
            ({(Math.round(discount * 100 * 100) / 100)}%):
          </Title>
        </Col>
        <Col xs={11} style={{ textAlign: "end" }}>
          <Price>-{formatPrice(discountAmount)}</Price>
        </Col>
      </Row>
    )}
    {deliveryPrice > 0 && (
      <Row style={{ width: "100%" }}>
        <Col xs={13}>
          <Title>
            {t("cashier.Cashier.CartPanel.ButtonsBlock.DeliveryPrice")}:
          </Title>
        </Col>
        <Col xs={11} style={{ textAlign: "end" }}>
          <Price>+{formatPrice(deliveryPrice)}</Price>
        </Col>
      </Row>
    )}
    {paid > 0 && (
      <Row style={{ width: "100%" }}>
        <Col xs={13}>
          <Title>
            {t("cashier.Cashier.CartPanel.ButtonsBlock.Paid")}:
          </Title>
        </Col>
        <Col xs={11} style={{ textAlign: "end" }}>
          <Price>{formatPrice(paid)}</Price>
        </Col>
      </Row>
    )}
    <Row style={{ width: "100%" }}>
      <Col xs={11}>
        <Title style={{ fontSize: 18 }}>{t("cashier.Cashier.CartPanel.ButtonsBlock.ToPay")}:</Title>
      </Col>
      <Col xs={13} style={{ textAlign: "end" }}>
        <Price style={{ fontSize: 18 }}>{formatPrice(toPay < 0 ? 0 : toPay)}</Price>
      </Col>
    </Row>
  </Container>
);

PriceBlock.propTypes = {
  t: PropTypes.func,
  toPay: PropTypes.number,
  serviceFee: PropTypes.number,
  discountAmount: PropTypes.number,
  discount: PropTypes.number,
  orderLinesTotal: PropTypes.number,
  deliveryPrice: PropTypes.number,
  paid: PropTypes.number,
  servicePercent: PropTypes.number,
};

export default PriceBlock;
