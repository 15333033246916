import React from "react";
import { Typography } from "antd";
import PropTypes from "prop-types";
import { useObservable } from "rxjs-hooks";
import { map, switchMap } from "rxjs/operators";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { WalletOutlined } from "@ant-design/icons";

import ConnectData from "containers/connect.container";

import { formatPrice } from "utils/currency";
import { ORDER_PAYMENT_TYPE, PAYMENT_METHOD_NAME, PAYMENT_METHOD_TYPE } from "constants/index";

const PaymentLabel = styled.p`
  padding: 0;
  margin: 0;
  font-size: 13px;
`;

const NotPaidLabel = ({ t }) => (
  <Typography.Paragraph strong style={{ margin: 0, color: "red" }}>
    <WalletOutlined /> {t("openOrders.PaymentColumn.NotPaid")}
  </Typography.Paragraph>
);

NotPaidLabel.propTypes = {
  t: PropTypes.func.isRequired,
};

const DeliveryPaymentMethod = ({ t, deliveryInfo, paymentMethods }) => {
  const { payment_method_id, cash_return } = deliveryInfo;
  const paymentMethod = paymentMethods.find((p) => p.id === payment_method_id);
  const method = paymentMethod.type === PAYMENT_METHOD_TYPE.CUSTOM
    ? paymentMethod.name : PAYMENT_METHOD_NAME[paymentMethod.name];
  return (
    <Typography.Paragraph style={{ margin: 0, lineHeight: 1.1, fontSize: 13 }}>
      {t("deliveryOrders.PaymentColumn.Info", { method })}
      {cash_return > 0
        && ` (${t("deliveryOrders.PaymentColumn.CashReturn")} ${formatPrice(cash_return)})`}
    </Typography.Paragraph>
  );
};

DeliveryPaymentMethod.propTypes = {
  t: PropTypes.func.isRequired,
  deliveryInfo: PropTypes.object.isRequired,
  paymentMethods: PropTypes.array.isRequired,
};

const PaymentColumn = ({ order }) => {
  const { t } = useTranslation();
  const { payment_methods } = ConnectData.useContainer();
  const orderPayments = useObservable((_, inputs$) => inputs$.pipe(
    switchMap(([o]) => o.order_payments.observe()),
    map((payments) => payments.sort((lhs, rhs) => lhs.created_at - rhs.created_at)),
  ), [], [order]);

  const paid = orderPayments.reduce((amount, curr) =>
    amount + (curr.type === ORDER_PAYMENT_TYPE.PAYMENT ? curr.amount : -curr.amount), 0);
  const toPay = order.list_price - paid;

  return (
    <>
      {toPay > 0 && <NotPaidLabel t={t} />}
      {toPay > 0 && order.delivery_info?.payment_method_id && (
        <DeliveryPaymentMethod
          t={t}
          deliveryInfo={order.delivery_info}
          paymentMethods={payment_methods}
        />
      )}
      {orderPayments.map((op) => {
        const paymentMethod = payment_methods.find((p) => p.id === op.payment_method_id);
        return (
          <PaymentLabel key={op.id}>
            {paymentMethod.type === PAYMENT_METHOD_TYPE.CUSTOM
              ? paymentMethod.name : PAYMENT_METHOD_NAME[paymentMethod.name] }
            :{" "}
            {op.type === ORDER_PAYMENT_TYPE.PAYMENT
              ? formatPrice(op.amount) : `(-${formatPrice(op.amount)})`}
          </PaymentLabel>
        );
      })}
    </>
  );
};

PaymentColumn.propTypes = {
  order: PropTypes.object.isRequired,
};

export default PaymentColumn;
