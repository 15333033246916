/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Button } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import { CloseOutlined } from "@ant-design/icons";

import { formatPrice } from "utils/currency";
import { Align } from "theme/globalStyles";

import { getSavedPOSTerminals } from "utils/devices";
import PrintSwitch from "./PrintSwitch";

const TopBlock = styled.div`
  height: 71vh;
  margin-bottom: 25px;
  padding-top: 100px;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 45px;
  font-weight: bolder;
`;

const TextPrice = styled(Align)`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 15px;
  width: 500px;
`;

const CloseButton = styled(CloseOutlined)`
  display: flex;
  font-size: 30px;
  cursor: pointer;
  justify-content: flex-end;
`;

const CustomPaymentPanel = ({
  paymentAmount,
  paymentMethod,
  onOrderPayment,
  onCancel,
  printSettings,
  onPrintSettingsChange,
  t,
  isNewOrder,
  onClose,
}) => {
  const canPayWithPOS = getSavedPOSTerminals().find((d) => d.settings?.[paymentMethod.id]);

  const handleSendPayment = () => {
    const payment = {
      paymentMethodId: paymentMethod.id,
      amount: paymentAmount,
    };
    onOrderPayment([payment], canPayWithPOS, payment);
  };

  return (
    <div>
      <CloseButton type="close" onClick={onClose} />
      <TopBlock>
        <Title>{paymentMethod.name}</Title>
        <Align align="center">
          <TextPrice align="space-between">
            <label>{t("cashier.Payment.PaymentPanel.PaymentAmount")}:</label>
            <span>{formatPrice(paymentAmount)}</span>
          </TextPrice>
        </Align>
      </TopBlock>
      <Align align="space-between">
        <Button
          onClick={onCancel}
          size="large"
          type="primary"
        >
          {t("cashier.Payment.PaymentPanel.GoBack")}
        </Button>
        <div style={{ display: "flex" }}>
          <PrintSwitch
            printSettings={printSettings}
            onPrintSettingsChange={onPrintSettingsChange}
            isNewOrder={isNewOrder}
          />
          <Button size="large" type="primary" onClick={handleSendPayment}>
            {t(`cashier.Payment.PaymentPanel.${canPayWithPOS ? "SendToPOS" : "Payment"}`)}
          </Button>
        </div>
      </Align>
    </div>
  );
};

CustomPaymentPanel.propTypes = {
  paymentAmount: PropTypes.number.isRequired,
  paymentMethod: PropTypes.object.isRequired,
  onOrderPayment: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  printSettings: PropTypes.object,
  onPrintSettingsChange: PropTypes.func,
  isNewOrder: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CustomPaymentPanel;
