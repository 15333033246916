import React from "react";
import { Button, Dropdown, Menu, Space } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { DollarOutlined, PrinterOutlined } from "@ant-design/icons";

import { useService } from "pos-service";
import { printCheck, printKitchenCheck } from "utils/print";
import ConnectData from "containers/connect.container";
import { usePrinters } from "utils/useDevices";

const MenuItem = styled(Menu.Item)`
  font-size: 15px;
  padding: 10px 15px;
`;

const ActionsColumn = ({ order, onClickPay }) => {
  const { t } = useTranslation();
  const { check_settings, payment_methods } = ConnectData.useContainer();
  const { service } = useService();
  const printers = usePrinters();

  const showKitchenPrint = printers.some((printer) => printer.stations?.kitchen);

  const handleDropdownPrint = (type) => {
    if (type === "receipt") printCheck(order, check_settings, payment_methods);
    if (type === "kitchen") {
      printers.filter((printer) => printer.stations?.kitchen).forEach((printer) => {
        service.orderLinesForKitchenPrinter(printer, order).then((printerOrderLines) => {
          if (printerOrderLines.length > 0) {
            printKitchenCheck(printer, {
              ...order._raw, // eslint-disable-line no-underscore-dangle
              display_number: order.display_number,
              order_lines: printerOrderLines.sort((lhs, rhs) => lhs.id.localeCompare(rhs.id)),
            });
          }
        });
      });
    }
  };

  const handlePrintReceipt = () => {
    printCheck(order, check_settings, payment_methods);
  };

  const printMenu = (
    <Menu onClick={(e) => handleDropdownPrint(e.key)}>
      <MenuItem key="receipt">{t("openOrders.ActionColumn.PrintReceipt")}</MenuItem>
      <Menu.Divider key="divider-receipt" />
      <MenuItem key="kitchen">{t("openOrders.ActionColumn.PrintKitchenCheck")}</MenuItem>
    </Menu>
  );

  return (
    <Space size={6} onClick={(e) => e.stopPropagation()}>
      <Button
        size="large"
        icon={<DollarOutlined />}
        onClick={onClickPay}
      />
      {showKitchenPrint ? (
        <Dropdown overlay={printMenu} trigger={["click"]}>
          <Button size="large" icon={<PrinterOutlined />} />
        </Dropdown>
      ) : (
        <Button
          size="large"
          icon={<PrinterOutlined />}
          onClick={handlePrintReceipt}
        />
      )}
    </Space>
  );
};

ActionsColumn.propTypes = {
  order: PropTypes.object.isRequired,
  onClickPay: PropTypes.func,
};

export default ActionsColumn;
