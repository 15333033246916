import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Alert, Col, Input, Row } from "antd";

import colors from "theme/colors";
import { formatPrice } from "utils/currency";

const InfoBlock = styled.div`
  background: ${colors.surface};
  border-radius: 5px;
  padding: 15px;
  font-size: 18px;

  b {
    font-weight: 600;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const OpenShiftReport = ({
  amounts, terminals, lastShiftCloseAmounts, correctionAmount, keyboard,
}) => {
  const { t } = useTranslation();

  const terminalsAmountsNotMatching = terminals
    .filter(({ id }) => amounts[id] !== lastShiftCloseAmounts[id])
    .map(({ id }) => id);

  return (
    <div>
      <Row gutter={[10, 10]}>
        <Col span={12}>
          <InfoBlock>
            <b>{t("ShiftManager.OpenShiftReport.CloseAmounts")}:</b>
            {terminals.map(({ id, name }) => (
              <p key={id}>
                {name}:&nbsp;
                {formatPrice(lastShiftCloseAmounts[id])}
              </p>
            ))}
          </InfoBlock>
        </Col>
        <Col span={12}>
          <InfoBlock>
            <b>{t("ShiftManager.OpenShiftReport.OpenAmounts")}:</b>
            {terminals.map(({ id, name }) => (
              <p key={id}>
                {name}:&nbsp;
                <span
                  style={{
                    color: terminalsAmountsNotMatching.includes(id) ? colors.red : colors.black,
                  }}
                >
                  {formatPrice(amounts[id])}
                </span>
              </p>
            ))}
          </InfoBlock>
        </Col>
      </Row>
      {Math.abs(correctionAmount) > 0.001 && (
        <>
          <Alert
            style={{ fontSize: 18, marginTop: 10 }}
            message={t("ShiftManager.OpenShiftReport.WarningSumsNotMatching")}
            type="warning"
            showIcon
          />
          <Alert
            style={{ fontSize: 18, marginTop: 10 }}
            message={(
              <>
                {t("ShiftManager.OpenShiftReport.WarningCorrectionWillBeCreated", {
                  sum: formatPrice(correctionAmount),
                })}
                <Input
                  style={{ marginTop: 5 }}
                  placeholder={t("ShiftManager.ShiftReport.DifferenceReason")}
                  size="large"
                  onFocus={keyboard.show}
                  onBlur={keyboard.onBlur}
                  value={keyboard.state}
                  onChange={(e) => keyboard.setState(e.target.value)}
                />
              </>
            )}
            type="warning"
            showIcon
          />
        </>
      )}
    </div>
  );
};

OpenShiftReport.propTypes = {
  amounts: PropTypes.object.isRequired,
  lastShiftCloseAmounts: PropTypes.object.isRequired,
  terminals: PropTypes.array.isRequired,
  correctionAmount: PropTypes.number,
  keyboard: PropTypes.object,
};

export default OpenShiftReport;
