import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useService } from "pos-service";

import Context from "./context";
import OpenShift from "./components/OpenShift";
import CloseShift from "./components/CloseShift";

export const useShiftManager = () => useContext(Context);

const ShiftManager = ({ visible, onClose }) => {
  const { service } = useService();

  if (!visible) return null;

  return service.isShiftOpen
    ? <CloseShift onClose={onClose} />
    : <OpenShift onClose={onClose} />;
};

ShiftManager.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ShiftManager;
