import React from "react";
import { Row, Col, Typography } from "antd";
import PropTypes from "prop-types";

const OrderListHeader = ({ t }) => (
  <Row
    justify="space-between"
    align="middle"
    style={{ padding: "0px 8px 8px" }}
  >
    <Col order={1} xs={4} lg={2} style={{ textAlign: "center" }}>
      <Typography.Text strong style={{ margin: 0 }}>#</Typography.Text>
    </Col>
    <Col xs={{ order: 3, span: 8 }} lg={{ order: 2, span: 4 }} style={{ textAlign: "center" }}>
      <Typography.Text strong style={{ margin: 0 }}>
        {t("openOrders.Columns.StartOrder")}
      </Typography.Text>
    </Col>
    <Col xs={{ order: 2 }} lg={{ order: 3 }} flex="210px" style={{ textAlign: "center" }}>
      <Typography.Text strong style={{ margin: 0 }}>
        {t("openOrders.Columns.Status")}
      </Typography.Text>
    </Col>
    <Col order={4} xs={5} lg={3} style={{ textAlign: "center" }}>
      <Typography.Text strong style={{ margin: 0 }}>
        {t("openOrders.Columns.Amount")}
      </Typography.Text>
    </Col>
    <Col order={5} xs={10} lg={5}>
      <Typography.Text strong style={{ margin: 0 }}>
        {t("openOrders.Columns.Payment")}
      </Typography.Text>
    </Col>
    <Col order={6} flex="140px" />
  </Row>
);

OrderListHeader.propTypes = {
  t: PropTypes.func,
};

export default OrderListHeader;
