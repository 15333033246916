import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Menu, Button } from "antd";
import styled from "styled-components";
import { EllipsisOutlined } from "@ant-design/icons";

import colors from "theme/colors";
import ConnectData from "containers/connect.container";

import CurrentOrder from "../../../../../containers/currentOrder.container";

const MenuItem = styled(Menu.Item)`
  font-size: 18px;
  padding: 15px 30px;

  background: ${(props) => (props.disabled ? colors.whisper : colors.white)};
`;

const LargeButton = styled(Button)`
  min-width: 45px;
`;

const ControllerDropdown = ({ t, onResetOrder }) => {
  const { order, servicePercent, setServicePercent } = CurrentOrder.useContainer();
  const { common_settings } = ConnectData.useContainer();

  const hasServiceFee = common_settings.service_percent > 0.0001;
  const servicePercentController = servicePercent > 0 ? (
    <MenuItem key="remove-service" onClick={() => setServicePercent(0)}>
      {t(
        "cashier.Cashier.CartPanel.ButtonsBlock.ControllerDropdown.RemoveServicePercent",
        { percent: servicePercent * 100 },
      )}
    </MenuItem>
  ) : (
    <MenuItem key="add-service" onClick={() => setServicePercent(common_settings.service_percent)}>
      {t(
        "cashier.Cashier.CartPanel.ButtonsBlock.ControllerDropdown.AddServicePercent",
        { percent: common_settings.service_percent * 100 },
      )}
    </MenuItem>
  );

  const menuController = (
    <Menu>
      {hasServiceFee && servicePercentController}
      <MenuItem key="reset" onClick={() => onResetOrder()} disabled={!!order}>
        {t("cashier.Cashier.CartPanel.ButtonsBlock.ControllerDropdown.ClearOrder")}
      </MenuItem>
    </Menu>
  );

  return (
    <Dropdown overlay={menuController} trigger={["click"]}>
      <LargeButton size="large" icon={<EllipsisOutlined />} style={{ width: "100%" }} />
    </Dropdown>
  );
};

ControllerDropdown.propTypes = {
  t: PropTypes.func.isRequired,
  onResetOrder: PropTypes.func,
};

export default ControllerDropdown;
