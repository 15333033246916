import { useEffect, useState } from "react";
import { useLocalStorage } from "utils/useLocalStorage";
import _ from "lodash";

import { scanDevices } from "./devices";

const useDevices = (options = {}) => {
  const [availableDevices, setAvailableDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [savedDevices, setSavedDevices] = useLocalStorage("devices", []);

  const fetch = () => {
    setError(null);
    setLoading(true);
    scanDevices({ onProgress: setAvailableDevices, ...options })
      .then(setAvailableDevices)
      .catch(setError)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetch();
  }, []);

  const devices = [
    ...savedDevices.map((device) => ({
      ...device,
      ...availableDevices.find((savedDevice) => savedDevice.id === device.id),
    })),
    ..._.differenceBy(availableDevices, savedDevices, "id"),
  ];

  const updateDevice = (id, changes) =>
    setSavedDevices(devices.map((device) => {
      if (device.id === id) {
        return { ...device, ...changes };
      }
      return device;
    }));

  const deleteDevice = (id) =>
    setSavedDevices(devices.filter((device) => device.id !== id));

  return {
    devices,
    loading,
    error,
    refetch: fetch,
    updateDevice,
    deleteDevice,
  };
};

export const usePrinters = () => {
  const [savedDevices] = useLocalStorage("devices", []);
  return savedDevices.filter((device) => device.type.startsWith("printer"));
};

export default useDevices;
