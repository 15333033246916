import { Button, Modal, Switch } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";

import colors from "theme/colors";

const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
`;

const SettingRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const SettingText = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 18px;
  transition: all 0.36s;
  align-items: center;
  color: ${({ disabled }) => (disabled ? colors.lightGrey : colors.black)};
`;

const DeviceSettings = ({ device, settings, onSettingChange, forgetDevice }) => {
  const { t } = useTranslation();

  const handleForgetDevice = () => {
    Modal.confirm({
      title: t("devices.ForgetDevice.Title", { name: device.name }),
      okText: t("devices.ForgetDevice.Forget"),
      cancelText: t("Cancel"),
      onOk: () => forgetDevice(device.id),
    });
  };

  return (
    <Container>
      <SettingRow
        key="repeatUntilAkc"
        onClick={() => onSettingChange("noRepeatSignal", !settings.noRepeatSignal)}
      >
        <SettingText>
          {t("devices.SelectedDevice.Settings.NoRepeatSignal")}
        </SettingText>
        <Switch checked={settings.noRepeatSignal} />
      </SettingRow>
      {device && (
        <Button
          onClick={handleForgetDevice}
          danger
          block
        >
          {t("devices.ForgetDevice.Button")}
        </Button>
      )}
    </Container>
  );
};

DeviceSettings.propTypes = {
  device: PropTypes.object,
  settings: PropTypes.object,
  onSettingChange: PropTypes.func,
  forgetDevice: PropTypes.func,
};

export default DeviceSettings;
