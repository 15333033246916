import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

const DateTimeBlock = () => {
  const { t } = useTranslation();
  const [time, setTime] = useState(
    DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
  );
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS));
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <span>{t("ShiftManager.Time")}: {time}</span>
  );
};

export default DateTimeBlock;
