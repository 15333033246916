import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import { PrinterOutlined } from "@ant-design/icons";
import {
  Row, Col, Descriptions, Table, Typography, Button, notification,
} from "antd";
import { DateTime } from "luxon";
import { Q } from "@nozbe/watermelondb";
import { useObservable } from "rxjs-hooks";
import { map, switchMap } from "rxjs/operators";

import { useService } from "pos-service";
import ConnectData from "containers/connect.container";
import CurrentUser from "containers/current-user";
import { formatPrice } from "utils/currency";
import { printCheck } from "utils/print";
import colors from "theme/colors";
import {
  PAYMENT_METHOD_TYPE, PAYMENT_METHOD_NAME, ORDER_PAYMENT_TYPE,
  ORDER_LINE_STATUS as OLS,
  ORDER_TYPE,
} from "constants/index";
import i18n from "i18n";

import CancelModal from "components/CancelModal";

import { ORDER_SOURCE_ICONS } from "../constants";

const { Text, Title } = Typography;

const Container = styled.div`
  padding: 15px;

  .order-line-cancelled {
    background-color: ${colors.whiteSmoke};
    opacity: 0.5;
  }

  .lines-footer-table {
    width: 400px;
    .bold {
      font-weight: bold;
    }

    tr > td {
      padding: 2px 8px;
    }

    tr > td:last-of-type {
      text-align: right;
    }
  }

  .refund-block {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0 10px;

    & > * {
      margin-bottom: 8px;
    }
  }
`;

const OrderLinesColumns = [
  {
    title: i18n.t("archive.OrderDetails.OrderLines.Table.Name"),
    dataIndex: "display_name",
    render: (name, { notes, status }) => (
      <Text>
        {name}
        {notes && (<Text type="secondary">&nbsp;<i>{notes}</i></Text>)}
        {status === OLS.CANCELLED && (
          <Text type="danger">&nbsp;<i>{i18n.t("const:order_line_status.cancelled")}</i></Text>
        )}
      </Text>
    ),
  },
  {
    title: i18n.t("archive.OrderDetails.OrderLines.Table.Price"),
    dataIndex: "price",
    render: formatPrice,
  },
  {
    title: i18n.t("archive.OrderDetails.OrderLines.Table.Count"),
    align: "center",
    dataIndex: "count",
  },
  {
    title: i18n.t("archive.OrderDetails.OrderLines.Table.TotalPrice"),
    dataIndex: "total_price",
    align: "right",
    render: formatPrice,
  },
];

const OrderDetails = ({ t, order }) => {
  const { payment_methods, check_settings } = ConnectData.useContainer();
  const { user } = CurrentUser.useContainer();
  const { service } = useService();

  const orderPayments = useObservable((_s, inputs$) => inputs$.pipe(
    switchMap(([o]) => o.order_payments.observe()),
    map((payments) => payments.sort((lhs, rhs) => lhs.created_at - rhs.created_at)),
  ), [], [order]);
  const orderLines = useObservable((_s, inputs$) => inputs$.pipe(
    switchMap(([orderId]) => service.db.get("order_lines").query(
      Q.where("order_id", orderId),
    ).observeWithColumns("updated_at")),
    map((arr) => arr.sort((lhs, rhs) => lhs.id.localeCompare(rhs.id))),
  ), [], [order.id]);
  const orderPrice = useObservable((_s, inputs$) => inputs$.pipe(
    switchMap(([o]) => o.order_price_updates.observe()),
    map((opuArr) => _.maxBy(opuArr, "id")),
  ), { order_lines_total: 0, service_percent: 0, discount: 0 }, [order]);

  const { order_lines_total, discount, service_percent } = orderPrice;

  const [isRefunding, setRefunding] = useState(false);
  const [selectedOrderLines, setSelectedOrderLines] = useState([]);
  const [cancelAmount, setCancelAmount] = useState(null);

  useEffect(() => {
    setRefunding(false);
    setSelectedOrderLines([]);
    setCancelAmount(null);
  }, [order]);

  const userBalance = orderPayments.reduce((amount, curr) =>
    amount + (curr.type === ORDER_PAYMENT_TYPE.PAYMENT ? curr.amount : -curr.amount), 0);
  const selectedAmount = (1 - discount + service_percent) * orderLines
    .filter((orderLine) => selectedOrderLines.includes(orderLine.id))
    .reduce((acc, orderLine) => acc + orderLine.total_price, 0);

  const handleCheckPrint = () => printCheck(order, check_settings, payment_methods);
  const handleToggleRefund = () => setRefunding(!isRefunding);
  const handleRefundAll = () => setCancelAmount(userBalance);
  const handleRefundSelected = () => setCancelAmount(selectedAmount);

  const handleSubmitRefund = ({ refunds }) => service.refundOrder(user.id, order, refunds)
    .then(() => {
      setRefunding(false);
      setSelectedOrderLines([]);
      setCancelAmount(null);
    })
    .catch(notification.error);

  const discountAmount = order_lines_total * discount;
  const serviceFee = order_lines_total * service_percent;

  const OrderSourceIcon = ORDER_SOURCE_ICONS[order.source];

  return (
    <Container>
      <Row type="flex" justify="space-between" style={{ marginBottom: "20px" }}>
        <Col>
          <Title level={3} style={{ display: "inline" }}>
            {t("archive.OrderDetails.Details.Title", { number: order.display_number })}
          </Title>
          &nbsp;
          <Text code>#{order.id}</Text>
        </Col>
        <Col>
          <Button
            type={isRefunding ? "default" : "danger"}
            disabled={userBalance < 0.001}
            onClick={handleToggleRefund}
          >
            {isRefunding ? t("Cancel") : t("archive.Refund")}
          </Button>
          <Button
            icon={<PrinterOutlined />}
            type="primary"
            onClick={handleCheckPrint}
            style={{ marginLeft: "6px" }}
          >
            {t("archive.PrintOrder")}
          </Button>
        </Col>
      </Row>
      <Row type="flex" gutter={[8, 8]}>
        <Col span={24}>
          <Descriptions size="small">
            <Descriptions.Item label={t("archive.OrderDetails.Details.Time")}>
              {DateTime.fromJSDate(order.time).toLocaleString(DateTime.DATETIME_MED)}
            </Descriptions.Item>
            <Descriptions.Item label={t("archive.OrderDetails.Details.Type")}>
              {t(`const:order_type.${order.type}`)}
            </Descriptions.Item>
            <Descriptions.Item label={t("archive.OrderDetails.Details.User")}>
              <OrderSourceIcon />&nbsp;{order.list_user}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={24}>
          <Table
            columns={OrderLinesColumns}
            dataSource={orderLines}
            rowSelection={isRefunding ? {
              selectedRowKeys: selectedOrderLines,
              onChange: setSelectedOrderLines,
            } : null}
            size="small"
            rowKey="id"
            pagination={false}
            rowClassName={(orderLine) => `order-line-${orderLine.status}`}
            footer={() => (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {isRefunding ? (
                  <div className="refund-block">
                    <Text>
                      {t("archive.OrderDetails.Refund.Available", { balance: formatPrice(userBalance) })}
                    </Text>
                    <Button
                      type="danger"
                      disabled={!selectedAmount || selectedAmount > userBalance}
                      onClick={handleRefundSelected}
                    >
                      {t("archive.OrderDetails.Refund.RefundPartially", {
                        count: selectedOrderLines.length, amount: formatPrice(selectedAmount),
                      })}
                    </Button>
                    <Button type="danger" onClick={handleRefundAll}>
                      {t("archive.OrderDetails.Refund.RefundAll", {
                        amount: formatPrice(userBalance),
                      })}
                    </Button>
                  </div>
                ) : <div />}
                <table className="lines-footer-table">
                  <tbody>
                    {[
                      [
                        t("archive.OrderDetails.Price.OrderLinesTotal"),
                        formatPrice(order_lines_total),
                      ],
                      [
                        t("archive.OrderDetails.Price.ServicePercent",
                          { servicePercent: `${service_percent * 100}%` }),
                        formatPrice(serviceFee),
                      ],
                      [
                        t("archive.OrderDetails.Price.Discount", { discount: `${discount * 100}%` }),
                        formatPrice(discountAmount),
                      ],
                      order.type === ORDER_TYPE.DELIVERY && [
                        t("archive.OrderDetails.Price.Delivery"),
                        formatPrice(orderPrice.delivery_price),
                      ],
                      [t("archive.OrderDetails.Price.ListPrice"), formatPrice(order.list_price), "bold"],
                      ...(orderPayments.length > 0 ? [
                        [""],
                        [t("archive.OrderDetails.Payments.Title"), "", "bold"],
                        ...orderPayments.map((payment) => {
                          const paymentMethod = payment_methods
                            .find((pm) => pm.id === payment.payment_method_id);
                          const name = paymentMethod.type === PAYMENT_METHOD_TYPE.CUSTOM
                            ? paymentMethod.name : PAYMENT_METHOD_NAME[paymentMethod.name];
                          const suffix = payment.type === ORDER_PAYMENT_TYPE.REFUND
                            ? t("archive.OrderDetails.Payments.Refund") : "";
                          return [
                            `${name} ${suffix}`,
                            formatPrice(payment.amount),
                            null,
                            payment.id,
                          ];
                        }),
                      ] : []),
                    ].filter((v) => v).map(([label, value, className, key]) => (
                      <tr key={key || label} className={className}>
                        <td><Text>{label}</Text></td>
                        <td><Text>{value}</Text></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          />
        </Col>
      </Row>
      {cancelAmount && (
        <CancelModal
          t={t}
          order={order}
          partialRefundAmount={cancelAmount}
          onSubmitCancel={handleSubmitRefund}
          onCancel={() => setCancelAmount(null)}
        />
      )}
    </Container>
  );
};

OrderDetails.propTypes = {
  t: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};

export default OrderDetails;
