import { createContainer } from "unstated-next";
import { useState, useEffect } from "react";

import { useLocalStorage } from "utils/useLocalStorage";

const fiveMinute = 3 * 60 * 1000;
let timeout;

const useCurrentUser = () => {
  const [token] = useLocalStorage("terminal_token");

  const [user, setUser] = useState(null);

  const resetTimeout = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setUser(null);
    }, fiveMinute);
  };

  useEffect(() => {
    resetTimeout();

    window.addEventListener("click", resetTimeout);
    window.addEventListener("keydown", resetTimeout);

    return () => {
      window.removeEventListener("click", resetTimeout);
      window.removeEventListener("keydown", resetTimeout);
    };
  }, []);

  useEffect(() => {
    setUser(null);
  }, [token]);

  return {
    user,
    setUser,
  };
};

export default createContainer(useCurrentUser);
