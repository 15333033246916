import styled, { css } from "styled-components";

import Card from "components/Card";

export default styled(Card)`
  display: flex;
  min-height: 50px;
  text-align: center;
  align-items: center;
  justify-content: center;

  transition: opacity 0.2s linear;
  ${(props) => (props.disabled ? css`
    opacity: 0.26;
    pointer-events: none;
  ` : css`
    opacity: 1;
    pointer-events: all;
  `)}
`;
