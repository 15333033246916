import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  CarOutlined,
  PlusOutlined,
  SettingOutlined,
  UnlockOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { DateTime } from "luxon";
import { Layout, Badge, Button, Popover } from "antd";
import PropTypes from "prop-types";
import { useObservable } from "rxjs-hooks";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import colors from "theme/colors";
import useWindowSize from "utils/useWindowSize";
import { useService } from "pos-service";
import { SYNC_STATUS } from "pos-service/adapters/SyncManager";

const LayoutHeader = styled(Layout.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.white};
  padding: 0 12px;
  border-bottom: 1px solid ${colors.gainsboro};
`;

const StyledNavLink = styled(NavLink)`
  margin-right: 10px;
  align-items: center;

  &.active > .ant-btn {
    background-color: #d9363e;
    border-color: #d9363e;
  }
`;

const StyledBadge = styled(Badge)`
  padding-left: 5px;
  padding-bottom: 1px;

  .ant-badge-count {
    box-shadow: none;
    background-color: ${colors.white};
    color: ${colors.red};
  }
`;

const SyncBlock = styled.div`
  height: 64px;
  padding: 0 15px;

  .ant-badge-status-dot {
    height: 10px;
    width: 10px;
  }

  .ant-badge-status-text {
    margin: 0;
  }
`;

const UserBlock = styled.div`
  display: flex;
  orientation: column;
  align-items: center;
  cursor: pointer;
  font-size: 1.1em;
  line-height: 1.3;
  height: 64px;
  padding: 0 10px;
  margin: 0 10px;

  div {
    width: 90px;
  }

  :hover {
    background: ${colors.whiteSmoke};
  }
`;

const SyncStatusPopoverContent = ({ t, syncStatus }) => {
  const [ts, setTs] = useState(Date.now());

  useEffect(() => {
    const timerId = setInterval(() => setTs(Date.now()), 500);
    return () => clearInterval(timerId);
  }, []);

  const syncMessage = useMemo(() => {
    switch (syncStatus?.status) {
      case SYNC_STATUS.SYNCED:
        return t("Header.SyncPopoverContent.Synced", {
          lastSync: DateTime.fromMillis(syncStatus.lastSync)
            .toLocaleString(DateTime.TIME_WITH_SECONDS),
        });
      case SYNC_STATUS.IN_PROGRESS:
        return t("Header.SyncPopoverContent.InProgress");
      case SYNC_STATUS.ERROR:
        return t("Header.SyncPopoverContent.Error", {
          error: syncStatus.error.message,
          nextSync: Math.round((syncStatus.nextSync - ts) / 1000),
        });
      default: return null;
    }
  }, [syncStatus, ts]);

  return (
    <div style={{ width: 320 }}>
      {syncMessage}
    </div>
  );
};

SyncStatusPopoverContent.propTypes = {
  t: PropTypes.func.isRequired,
  syncStatus: PropTypes.object,
};

const Header = ({ onOpenSettingPanel, onLogout, user, orderCounts }) => {
  const { t } = useTranslation();
  const { service } = useService();
  const { width } = useWindowSize();
  const [forceVisible, setForceVisible] = useState(false);
  const syncStatus = useObservable(() => service.syncManager.status.asObservable(), null, []);

  useEffect(() => {
    if (syncStatus?.status === SYNC_STATUS.ERROR && forceVisible !== syncStatus.error.message) {
      setForceVisible(true);
      const timerId = setTimeout(() => setForceVisible(syncStatus.error.message), 5000);
      return () => clearTimeout(timerId);
    }
    if (syncStatus?.status === SYNC_STATUS.SYNCED) {
      setForceVisible(false);
    }
    return () => {};
  }, [syncStatus?.status]);

  const syncBadgeStatus = useMemo(() => {
    switch (syncStatus?.status) {
      case SYNC_STATUS.SYNCED: return "success";
      case SYNC_STATUS.IN_PROGRESS: return "processing";
      case SYNC_STATUS.ERROR: return "error";
      default: return "default";
    }
  }, [syncStatus?.status]);

  return (
    <LayoutHeader>
      <div>
        <StyledNavLink id="new-order-btn" to="/cashier">
          <Button type="danger" icon={<PlusOutlined />} size="large" disabled={!service.isShiftOpen}>
            {width > 935 && t("Header.AddOrder")}
          </Button>
        </StyledNavLink>
        <StyledNavLink id="open-orders-btn" to="/open-orders">
          <Button type="danger" icon={<UnorderedListOutlined />} size="large" disabled={!service.isShiftOpen}>
            {width > 935 && t("Header.OpenOrders")}
            {orderCounts.openOrder > 0 && <StyledBadge count={orderCounts.openOrder} />}
          </Button>
        </StyledNavLink>
        <StyledNavLink id="delivery-orders-btn" to="/delivery-orders">
          <Button type="danger" icon={<CarOutlined />} size="large" disabled={!service.isShiftOpen}>
            {width > 935 && t("Header.Deliveries")}
            {orderCounts.delivery > 0 && <StyledBadge count={orderCounts.delivery} />}
          </Button>
        </StyledNavLink>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <Button onClick={onOpenSettingPanel} type="danger" icon={<SettingOutlined />} size="large" />
        <UserBlock onClick={onLogout}>
          <UnlockOutlined />
          &nbsp;&nbsp;
          <div className="cap-2-line">{user?.name}</div>
        </UserBlock>
        <Popover
          placement="bottomRight"
          content={() => <SyncStatusPopoverContent t={t} syncStatus={syncStatus} />}
          visible={forceVisible === true || undefined}
        >
          <SyncBlock>
            <Badge status={syncBadgeStatus} />
          </SyncBlock>
        </Popover>
      </div>
    </LayoutHeader>
  );
};

Header.propTypes = {
  onOpenSettingPanel: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  orderCounts: PropTypes.object.isRequired,
};

export default Header;
