/* eslint-disable no-alert */
/* global LocalDevices, cordova */
import React from "react";
import { DateTime } from "luxon";
import { renderToStaticMarkup } from "react-dom/server";
import { printComponent as browserPrintComponent } from "react-print-tool";
import i18n from "i18n";
import * as Sentry from "@sentry/react";

import Receipt from "@salempos/check";
import KitchenCheck from "components/KitchenCheck";

import { ORDER_TYPE_LABELS, PAYMENT_METHOD_NAME } from "constants/index";

import { getSavedPrinters } from "./devices";

export const printOptionsSingleton = {
  checkLang: {
    ...i18n.t("const:check", { returnObjects: true }),
    currency: "",
    paymentMethods: PAYMENT_METHOD_NAME,
    orderTypes: ORDER_TYPE_LABELS,
  },
  getCheckLang() {
    return this.checkLang;
  },
  setCurrency(currency) {
    this.checkLang = { ...this.checkLang, currency };
  },
};

const PAPER_WIDTH_TO_PIXELS = {
  80: 274,
  72: 243,
  58: 192,
  48: 164,
};
const INCH_TO_MM = 25.4;
const PAPER_PADDING = 4;

const paperWidthToPixels = (width = 80, dpi = 203) => {
  if (window.electron) {
    return PAPER_WIDTH_TO_PIXELS[width];
  }
  return Math.round((width - (PAPER_PADDING * 2)) * (dpi / INCH_TO_MM));
};

const generateHtml = (component, width) => `
  <html>
    <head>
      <meta charset="utf-8">
      <title>${Date.now()}</title>
      <style>
        body {
          margin: 0;
        }
        @page {
          size: auto;
          margin: 0mm;
        }
        #salempos-print-content {
          width: ${paperWidthToPixels(width)}px;
        }
      </style>
    </head>
    <body>
      <div id="salempos-print-content">
        ${renderToStaticMarkup(component)}
      </div>
    </body>
  </html>
`;

export const printComponentOnPrinter = (component, printer) => {
  const html = generateHtml(component, printer.settings?.paper_width || 80);

  if (window.electron) return window.electron.print(html, printer);
  if (window.cordova) {
    if (printer.type === "printer-escpos") {
      return LocalDevices.printEscPosHtml({ html, printer });
    }
    return new Promise((resolve) => cordova.plugins.printer.print(html, {}, resolve));
  }
  return Promise.reject(new Error("No electron or cordova."));
};

export const printComponent = (component) => {
  if (window.electron || window.cordova) {
    const printers = getSavedPrinters();
    if (printers.length > 0) {
      const printer = printers.find((p) => p.stations?.receipt);
      printComponentOnPrinter(component, printer).catch((err) => Sentry.captureException(err, {
        tags: { section: "print", print_target: "component" },
        extra: { device: printer },
      }));
    } else {
      alert(i18n.t("devices.PrintersNotConfigured"));
    }
  } else {
    browserPrintComponent(component);
  }
};

export const printKitchenCheck = (printer, order, status) => printComponentOnPrinter(
  <KitchenCheck
    order={order}
    status={status}
    width={paperWidthToPixels(printer.settings?.paper_width)}
  />,
  printer,
).catch((err) => Sentry.captureException(err, {
  tags: { section: "print", print_target: "kitchen" },
  extra: { device: printer },
}));

export const printCheck = (order, checkSettings, paymentMethods) => {
  Promise.all([
    order.order_lines.fetch(),
    order.order_price_updates.fetch(),
    order.order_payments.fetch(),
  ]).then(([orderLines, opus, order_payments]) => {
    const orderData = {
      ...order._raw, // eslint-disable-line no-underscore-dangle
      display_number: order.display_number,
      display_time: DateTime.fromJSDate(order.time)
        .toLocaleString(DateTime.DATETIME_SHORT).replace(",", ""),
      order_lines: orderLines.sort((lhs, rhs) => lhs.id.localeCompare(rhs.id)),
      order_payments,
      customer: order.customer,
      delivery_info: order.delivery_info,
      order_price_updates: opus.sort((lhs, rhs) => lhs.created_at - rhs.created_at),
    };
    if (window.electron || window.cordova) {
      const printers = getSavedPrinters();
      if (printers.length > 0) {
        printers.filter((printer) => printer.stations?.receipt).forEach(
          (printer) => printComponentOnPrinter(
            <Receipt
              order={orderData}
              checkSettings={checkSettings}
              paymentMethods={paymentMethods}
              lang={printOptionsSingleton.getCheckLang()}
              width={paperWidthToPixels(printer.settings?.paper_width)}
            />,
            printer,
          ).catch((err) => Sentry.captureException(err, {
            tags: { section: "print", print_target: "check" },
            extra: { device: printer },
          })),
        );
      } else {
        alert(i18n.t("devices.PrintersNotConfigured"));
      }
    } else {
      browserPrintComponent(
        <Receipt
          order={orderData}
          checkSettings={checkSettings}
          paymentMethods={paymentMethods}
          lang={printOptionsSingleton.getCheckLang()}
        />,
      );
    }
  });
};
