import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Q } from "@nozbe/watermelondb";
import { useObservable } from "rxjs-hooks";
import { map, switchMap } from "rxjs/operators";

import { ORDER_LINE_STATUS_LABELS, ORDER_LINE_STATUS_ICONS } from "constants/index";
import { formatPrice } from "utils/currency";
import { stringifyModifiers } from "utils/helpers";
import { useService } from "pos-service";

const Table = styled.table`
  width: 100%;
  tr:nth-child(even) {
    background-color: ${({ colored }) =>
    (colored ? "rgba(255, 255, 255, 0.2)" : "unset")};
  }
`;

const OrderLinesTable = ({ order }) => {
  const { service } = useService();
  const orderLines = useObservable((_, inputs$) => inputs$.pipe(
    switchMap(([orderId]) => service.db.get("order_lines").query(
      Q.where("order_id", orderId),
    ).observeWithColumns("updated_at")),
    map((arr) => arr.sort((lhs, rhs) => lhs.id.localeCompare(rhs.id))),
  ), [], [order.id]);
  return (
    <Table colored={orderLines.length >= 3}>
      <colgroup>
        <col span="1" style={{ width: "55%" }} />
        <col span="1" style={{ width: "7%" }} />
        <col span="1" style={{ width: "21%" }} />
        <col span="1" style={{ width: "21%" }} />
      </colgroup>
      <tbody>
        {orderLines.map((orderLine) => {
          const StatusIcon = ORDER_LINE_STATUS_ICONS[orderLine.status];
          return (
            <tr key={orderLine.id}>
              <td>
                <div>
                  {orderLine.display_name}
                  {orderLine.notes && (<i> ({orderLine.notes})</i>)}
                </div>
                {orderLine.modifiers?.filter((m) => m.value.length > 0).map((modifier) => (
                  <div key={modifier.modifier_group_id} style={{ marginLeft: 10 }}>
                    {modifier.list_group_name}: {stringifyModifiers(modifier.value)}
                  </div>
                ))}
              </td>
              <td align="center">
                {orderLine.count}
              </td>
              <td align="center">
                {formatPrice(orderLine.total_price)}
              </td>
              <td>
                <StatusIcon /> {ORDER_LINE_STATUS_LABELS[orderLine.status]}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

OrderLinesTable.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderLinesTable;
