import { useEffect } from "react";
import { createContainer } from "unstated-next";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import i18n from "../i18n";

const generatedNames = i18n.t("const:generated", { returnObjects: true });

const CONNECT_DATA_QUERY = gql`
  query GetPosData @hasura {
    terminals {
      id
      name
      location_id
      settings
      main
    }
    other_terminals(where: { type: { _eq: "pos" } }) {
      id
      name
      location_id
      settings
      main
    }
    locations {
      id
      name
      cash_payment_account_id
      card_payment_account_id
      incassation_account_id
    }
    menu_categories(order_by: {position: asc_nulls_last}) {
      name
      id
      icon
      position
      menu_item_groups(
        where: {archived_at: {_is_null: true}}
      ) {
        id
        name
        category_id
        archived_at
        color
        menu_items(
          order_by: {id: asc}
          where: {archived_at: {_is_null: true}}
        ) {
          id
          name
          price
          product_id
          archived_at
          limited_location_availability
          tech_card {
            id
            workshop_id
            product_id
            unit
            process_duration
          }
          product {
            unit
          }
          menu_item_modifier_groups(order_by: {id: asc}) {
            modifier_group {
              id
              description
              created_at
              archived_at
              max_selection
              merchant_id
              min_selection
              name
              updated_at
              modifier_items(order_by: {id: asc}) {
                id
                default_value
                created_at
                archived_at
                modifier_group_id
                name
                price
                updated_at
              }
            }
          }
        }
      }
    }
    tables(order_by: {id: asc}) {
      id
      name
      location_id
    }
    merchants {
      id
      company_name
      plan
      favicon_url
      logo_url
      corrections_finance_category_id
    }
    common_settings {
      id
      currency_code
      timezone
      shift_offset
      service_percent
    }
    users {
      id
      name
      pin_code
      access_role {
        access_cashier
        access_courier
        access_location_admin
      }
    }
    check_settings {
      id
      name
      field_top
      field_bottom
      font_size
      number_display_type
      print_delivery_info
      print_order_notes
      qr_content
      qr_description
    }
    workshops {
      id
      name
    }
    payment_methods {
      id
      name
      type
      show_in_pos
    }
    delivery_zones {
      id
      name
      price
    }
    finance_categories {
      id
      name
      allow_expenses
      allow_income
      merchant_id
      created_at
      updated_at
    }
  }
`;

const useConnect = () => {
  const { data, error, loading, fetchMore, refetch } = useQuery(CONNECT_DATA_QUERY);

  useEffect(() => {
    if (data) { // we're using cached data, let's update it
      fetchMore({
        updateQuery: (previousResult, { fetchMoreResult }) => fetchMoreResult,
      });
    }
  }, []);

  const location = data?.locations[0];

  const filteredMenu = data?.menu_categories
    .map((category) => ({
      ...category,
      menu_item_groups: category.menu_item_groups
        .map((menuItemGroup) => ({
          ...menuItemGroup,
          menu_items: menuItemGroup.menu_items
            .map(({ menu_item_modifier_groups: mimgs, ...menuItem }) => ({
              ...menuItem,
              menu_item_modifier_groups: mimgs
                .map(({ modifier_group }) => ({
                  modifier_group: {
                    ...modifier_group,
                    modifier_items: modifier_group.modifier_items.filter((mi) => !mi.archived_at),
                  },
                }))
                .filter(({ modifier_group: { archived_at, modifier_items } }) =>
                  !archived_at && modifier_items.length > 0),
            }))
            .filter(({ archived_at, limited_location_availability: lla }) =>
              !archived_at && (lla === null || lla.includes(location.id))),
        }))
        .filter(({ archived_at, menu_items }) => !archived_at && menu_items.length > 0)
        .sort((lhs, rhs) => {
          const lhsPrice = lhs.menu_items[0].price;
          const rhsPrice = rhs.menu_items[0].price;
          if (lhsPrice === rhsPrice) {
            return lhs.name.toLowerCase().localeCompare(rhs.name.toLowerCase());
          }
          return lhsPrice - rhsPrice;
        }),
    }))
    .filter((category) => category.menu_item_groups.length > 0);

  const menuItems = data?.menu_categories
    .flatMap((m) => m.menu_item_groups)
    .flatMap((m) => m.menu_items);

  const menuWorkshopIds = new Set(menuItems?.map((mi) => mi.tech_card?.workshop_id));
  const workshops = data?.workshops.filter((w) => menuWorkshopIds.has(w.id));

  const posData = data && {
    merchant: data.merchants[0],
    terminal: data.terminals[0],
    terminals: [
      ...data.terminals,
      ...data.other_terminals,
    ],
    location: data.locations[0],
    workshops,
    common_settings: data.common_settings[0],
    check_settings: data.check_settings[0],
    menu_categories: filteredMenu,
    delivery_zones: data.delivery_zones,
    tables: data.tables,
    users: data.users,
    cashiers: data.users
      .filter((user) => user.access_role.access_cashier)
      .map((user) => ({ ...user, isAdmin: user.access_role.access_location_admin })),
    couriers: data.users.filter((user) => user.access_role.access_courier),
    payment_methods: data.payment_methods,
    pos_payment_methods: data.payment_methods.filter((pm) => pm.show_in_pos),
    finance_categories: data.finance_categories.map(({ name, ...category }) => ({
      ...category, name: generatedNames[name] ?? name,
    })),
  };

  const menuItemsById = menuItems?.reduce(
    (obj, { id, tech_card, product_id }) => ({ ...obj, [id]: { tech_card, product_id } }),
    {},
  );

  const posServiceConfig = posData && {
    merchant: posData.merchant,
    terminal: posData.terminal,
    terminals: posData.terminals,
    location: posData.location,
    tables: posData.tables,
    commonSettings: posData.common_settings,
    users: data.users,
    paymentMethods: posData.payment_methods,
    menuItemsById,
  };

  return {
    ...posData,
    posServiceConfig,
    refetch,
    error,
    loading,
  };
};

export default createContainer(useConnect);
