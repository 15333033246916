import { useState, useEffect } from "react";
import { createContainer } from "unstated-next";

import { useService } from "pos-service";
import { ORDER_TYPE } from "constants/index";

import CurrentUser from "./current-user";

const countStats = (orders) => {
  const salesSum = orders
    .map((o) => o.list_price).reduce((acc, price) => acc + price, 0);

  return {
    count: orders.length,
    sum: salesSum,
    avg: salesSum > 0 ? (salesSum / orders.length).toFixed(2) : 0,
  };
};

const useOrders = () => {
  const { user } = CurrentUser.useContainer();
  const { service } = useService();
  const [openOrders, setOpenOrders] = useState([]);
  const [statsData, setStatsData] = useState([]);

  useEffect(() => {
    let subscriber;
    if (service) {
      subscriber = service.openOrders$.subscribe(setOpenOrders);
      subscriber.add(service.shiftStatsOrders$.subscribe(setStatsData));
    } else if (openOrders.length > 0) {
      setOpenOrders([]);
    }

    return () => {
      if (subscriber) subscriber.unsubscribe();
    };
  }, [service]);

  const userStatsData = user && statsData
    .filter((o) => (user.isAdmin || o.user_id === user.id) && o.type === ORDER_TYPE.IN_STORE);
  const deliveryStatsData = statsData
    .filter((o) => o.type === ORDER_TYPE.PICKUP || o.type === ORDER_TYPE.DELIVERY);

  return {
    openOrders,
    userStats: userStatsData && countStats(userStatsData),
    deliveryStats: countStats(deliveryStatsData),
  };
};

export default createContainer(useOrders);
