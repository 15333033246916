import React, { useState } from "react";
import { PercentageOutlined } from "@ant-design/icons";
import { Popover, Button } from "antd";
import PropTypes from "prop-types";

import CurrentOrder from "../../../../../containers/currentOrder.container";
import DiscountModal from "./DiscountModal";

const DiscountPopover = ({ t }) => {
  const { discount, setDiscount } = CurrentOrder.useContainer();
  const [visiblePopover, setVisiblePopover] = useState(false);

  return (
    <Popover
      visible={visiblePopover}
      onVisibleChange={() => setVisiblePopover(!visiblePopover)}
      trigger="click"
      placement="top"
      content={(
        <DiscountModal
          t={t}
          onClose={() => setVisiblePopover(false)}
          onOk={(value) => {
            setDiscount(value);
            setVisiblePopover(false);
          }}
          visible={visiblePopover}
        />
      )}
    >
      <Button
        id="discount-btn"
        size="large"
        icon={<PercentageOutlined />}
        type={discount > 0 ? "primary" : "default"}
        style={{ width: "100%" }}
      />
    </Popover>
  );
};

DiscountPopover.propTypes = {
  t: PropTypes.func.isRequired,
};

export default DiscountPopover;
