import React from "react";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import { useApolloClient } from "@apollo/client";
import PropTypes from "prop-types";
import { Spin } from "antd";
import { Settings } from "luxon";

import currencySingleton from "utils/currency";

import ConnectData from "containers/connect.container";
import Orders from "containers/orders.container";
import CurrentUser from "containers/current-user";
import KeyboardProvider from "components/Keyboard";

import { Provider as PosServiceProvider } from "./pos-service";

import AppLayout from "./AppLayout";
import ErrorPage from "./pages/error-page";

const Loader = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const setupFavicon = (merchant) => {
  if (merchant && merchant.favicon_url) {
    const link = document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = merchant.favicon_url;
    document.getElementsByTagName("head")[0].appendChild(link);
  }
};

const FactoryContainers = ({ onLogout }) => {
  const client = useApolloClient();
  const {
    merchant,
    location,
    terminal,
    common_settings,
    posServiceConfig,
    error,
    loading,
    refetch,
  } = ConnectData.useContainer();

  if (error) {
    return (
      <ErrorPage
        onReload={() => refetch()}
        onLogout={onLogout}
        error={error.message}
      />
    );
  }

  if (loading) {
    return <Loader />;
  }

  Sentry.setUser({
    id: merchant.id,
    username: merchant.company_name,
    plan: merchant.plan,
    location_id: location.id,
    location_name: location.name,
    terminal_id: terminal.id,
    main: terminal.main,
  });

  setupFavicon(merchant);
  currencySingleton.setCurrency(
    common_settings.currency_code,
  );
  Settings.defaultZone = common_settings.timezone;

  return (
    <PosServiceProvider config={posServiceConfig} client={client}>
      <CurrentUser.Provider>
        <Orders.Provider>
          <KeyboardProvider>
            <AppLayout onLogout={onLogout} />
          </KeyboardProvider>
        </Orders.Provider>
      </CurrentUser.Provider>
    </PosServiceProvider>
  );
};

FactoryContainers.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default FactoryContainers;
