import { Model } from "@nozbe/watermelondb";
import { field, readonly, date, relation } from "@nozbe/watermelondb/decorators";

export default class FinanceTransaction extends Model {
  static table = "finance_transactions";

  static associations = {
    cashier_shifts: { type: "belongs_to", key: "shift_id" },
  };

  @field("type") type;
  @field("category_id") category_id;
  @field("transfer_to_account_id") transfer_to_account_id;
  @field("transfer_to_amount") transfer_to_amount;
  @field("description") description;
  @field("account_id") account_id;
  @field("amount") amount;
  @date("time") time;
  @field("user_id") user_id;
  @field("terminal_id") terminal_id;
  @readonly @date("created_at") created_at;
  @readonly @date("updated_at") updated_at;

  @relation("cashier_shifts", "shift_id") shift;
}
