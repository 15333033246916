import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import colors from "theme/colors";

const COOPERS = [100, 200, 500, 1000, 2000, 5000];

const Container = styled.div`
  max-width: 205px;
  width: 100%;
  height: 320px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Cooper = styled.button`
  background-color: ${colors.white};
  border-style: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  height: 75px;
  max-width: 100px;
  width: 100%;
  margin-bottom: 4px;
`;

const Coopers = ({ onSelect, paymentAmount, ...rest }) => {
  const roundedPaymentAmount = Math.round(paymentAmount * 100) / 100;
  const paymentAmountCeiled = Math.ceil(paymentAmount / 100) * 100;
  return (
    <Container {...rest}>
      <Cooper onClick={() => onSelect(roundedPaymentAmount)} key="full_payment">
        +{roundedPaymentAmount}
      </Cooper>
      <Cooper onClick={() => onSelect(paymentAmountCeiled)} key="ceiled">
        +{paymentAmountCeiled}
      </Cooper>
      {COOPERS.map((cooper) => (
        <Cooper onClick={() => onSelect(cooper)} key={cooper}>
          +{cooper}
        </Cooper>
      ))}
    </Container>
  );
};

Coopers.propTypes = {
  onSelect: PropTypes.func.isRequired,
  paymentAmount: PropTypes.number,
};

export default Coopers;
