import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { stringifyModifiers } from "utils/helpers";

import { INIT_LANG } from "./constants";

const getOrderLineNamePrefix = (order, orderLine, status) => {
  if (status === "changed" && order.updated_at === orderLine.created_at) {
    return "+ ";
  }
  return "";
};

const KitchenCheck = ({ order, status, width = 274, lang = INIT_LANG }) => (
  <table
    id="check"
    style={{ fontFamily: "sans-serif", width, fontSize: 14, lineHeight: 1.15, color: "#000" }}
  >
    <tbody>
      <tr style={{ height: 30 }} />
      <tr><td colSpan={2}>{lang.cashier}: {order.list_user}</td></tr>
      <tr>
        <td colSpan={2}>
          {lang.time}: {DateTime.fromMillis(order.time).toLocaleString(DateTime.DATETIME_SHORT)}
        </td>
      </tr>
      <tr><td colSpan={2}>{lang.type}: {lang.orderType[order.type]}</td></tr>
      <tr><td colSpan={2}>{lang.notes}: <i>{order.notes || "-"}</i></td></tr>
      <tr style={{ height: 15 }} />
      <tr style={{ fontSize: 24 }}>
        <td colSpan={2}>
          {order.display_number} <span style={{ fontSize: 18 }}>{lang.orderStatus[status] || ""}</span>
        </td>
      </tr>
      <tr style={{ height: 15 }} />
      {order.order_lines.map((orderLine) => (
        <React.Fragment key={orderLine.id}>
          <tr key={orderLine.id}>
            <td align="left">
              <div style={orderLine.status === "cancelled" ? { textDecorationLine: "line-through" } : {}}>
                {getOrderLineNamePrefix(order, orderLine, status)}{orderLine.display_name}
              </div>
              {orderLine.modifiers?.filter((m) => m.value.length > 0)
                .map((modifier) => (
                  <div key={modifier.modifier_group_id} style={{ marginLeft: 10, fontSize: 12 }}>
                    {modifier.list_group_name}: {stringifyModifiers(modifier.value)}
                  </div>
                ))}
              <div style={{ fontStyle: "italic", marginLeft: 10, wordBreak: "break-word", fontSize: 12 }}>
                <div>{orderLine.notes}</div>
                {orderLine.status === "cancelled" && <div>{lang.orderLineCancelled}</div>}
              </div>
            </td>
            <td align="right" style={{ verticalAlign: "top" }}>
              {orderLine.count}
            </td>
          </tr>
          <tr style={{ height: 10 }}>
            <td colSpan={2}>
              <div style={{ width: "100%", borderTop: "1px dashed #666" }} />
            </td>
          </tr>
        </React.Fragment>
      ))}
    </tbody>
  </table>
);

KitchenCheck.propTypes = {
  order: PropTypes.object.isRequired,
  status: PropTypes.string,
  lang: PropTypes.object,
  width: PropTypes.number,
};

export default KitchenCheck;
