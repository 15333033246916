import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Numpad from "@salempos/numpad";
import colors from "theme/colors";

const Container = styled.div`
  width: 300px;
`;

const CellContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  @keyframes pinErrorJump {
    from  { transform: translateY(0);     }
    to    { transform: translateY(-10px); }
  }

  && > div {
    width: 70px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-size: 20px;
    background-color: white;
    border: 1px solid ${({ error }) => (error ? colors.red : colors.white)};

    ${({ error }) => error && [0, 1, 2, 3].map((n) => `
      &:nth-child(${n + 1}) {
        animation: pinErrorJump 0.12s reverse;
        animation-delay: ${n}00ms;
      }
    `).join("\n")}
  }
`;

const PinCodeForm = ({ onSend, users }) => {
  const [pinCode, setPinCode] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = (number) => {
    const user = users.find((u) => u.pin_code === number);
    if (user) {
      onSend(user);
    } else {
      setPinCode("");
      setError(true);
    }
  };

  const onChange = (number) => {
    if (number.length === 1) {
      setError(false);
    }

    setPinCode(number);

    if (number.length === 4) {
      handleSubmit(number);
    }
  };

  const darts = pinCode.split("").map(() => "*");

  return (
    <Container>
      <CellContainer error={error}>
        {[0, 1, 2, 3].map((n) => <div key={n}>{darts[n]}</div>)}
      </CellContainer>
      <Numpad
        value={pinCode}
        onConditions={(code) => code.length === 4}
        delimiter={false}
        isDelete
        onChange={onChange}
        styleButton={{ width: 95, height: 95, outline: "none" }}
      />
    </Container>
  );
};

PinCodeForm.propTypes = {
  users: PropTypes.array.isRequired,
  onSend: PropTypes.func.isRequired,
};

export default PinCodeForm;
