import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Q } from "@nozbe/watermelondb";
import { useTranslation } from "react-i18next";
import { useObservable } from "rxjs-hooks";
import { distinctUntilChanged, filter, map } from "rxjs/operators";
import { Alert, Col, Input, Popover, Row } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";
import _ from "lodash";

import { useService } from "pos-service";
import ConnectData from "containers/connect.container";
import colors from "theme/colors";
import { formatPrice } from "utils/currency";
import { SYNC_STATUS } from "pos-service/adapters/SyncManager";
import { PAYMENT_METHOD_TYPE, PAYMENT_METHOD_NAME } from "constants/index";

const InfoBlock = styled.div`
  background: ${colors.surface};
  border-radius: 5px;
  padding: 15px;
  font-size: 18px;

  b {
    font-weight: 600;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const CloseShiftReport = ({
  amounts,
  terminals,
  cashRevenueByTerminal,
  revenueByPaymentMethods,
  expectedCashAmounts,
  correctionAmount,
  incassationAmount,
  keyboard,
}) => {
  const { payment_methods } = ConnectData.useContainer();
  const { service } = useService();
  const { t } = useTranslation();

  const onlinePayments = useObservable(() => service.db.get("order_payments")
    .query(
      Q.where("shift_id", null),
      Q.where("created_at", Q.gt(service.lastClosedShift.getValue()?.closed_at.getTime() ?? 0)),
    )
    .observe()
    .pipe(
      map((payments) => payments.reduce((acc, { payment_method_id, amount, type }) => ({
        ...acc,
        [payment_method_id]: (acc[payment_method_id] || 0) + (amount * (type === "payment" ? 1 : -1)),
      }), {})),
    ), {});

  const sortedPaymentMethods = useMemo(() => _.sortBy(payment_methods, "id"), [payment_methods]);

  const paymentMethodsWithoutCash = useMemo(() =>
    sortedPaymentMethods.filter((pm) => pm.type !== "cash" && revenueByPaymentMethods[pm.id] > 0),
  [sortedPaymentMethods, revenueByPaymentMethods]);

  const onlinePaymentMethods = useMemo(() =>
    sortedPaymentMethods.filter((pm) => onlinePayments[pm.id] > 0),
  [sortedPaymentMethods, onlinePayments]);

  const terminalsNotMatchingRevenue = useMemo(() => terminals
    .filter(({ id }) =>
      (expectedCashAmounts[id].total || 0) !== parseInt(amounts.actual_amounts[id], 10))
    .map(({ id }) => id),
  [cashRevenueByTerminal, expectedCashAmounts]);

  const syncStatus = useObservable(() => service.syncManager.status$.pipe(
    filter(({ status }) => status !== SYNC_STATUS.IN_PROGRESS),
    distinctUntilChanged(undefined, (s) => s.status),
  ), null);

  return (
    <div>
      <Row gutter={[10, 10]}>
        <Col span={12}>
          <InfoBlock>
            <b>{t("ShiftManager.ShiftReport.ExpectedCashAmounts")}:</b>
            {terminals.map(({ id, name }) => {
              const {
                total, openCashAmounts, cashRevenue, transactionsTotal,
              } = expectedCashAmounts[id];
              return (
                <p key={id}>
                  {name}:&nbsp;
                  {formatPrice(expectedCashAmounts[id].total)}&nbsp;
                  {total !== 0 && (
                    <Popover
                      content={(
                        <>
                          {openCashAmounts !== 0 && (
                            <>
                              {t("ShiftManager.ShiftReport.OpenCashAmount")}&nbsp;
                              {formatPrice(openCashAmounts)}
                              <br />
                            </>
                          )}
                          {cashRevenue !== 0 && (
                            <>
                              {t("ShiftManager.ShiftReport.CashRevenue")}&nbsp;
                              {formatPrice(cashRevenue)}
                              <br />
                            </>
                          )}
                          {transactionsTotal !== 0 && (
                            <>
                              {t("ShiftManager.ShiftReport.TransactionsTotal")}&nbsp;
                              {formatPrice(transactionsTotal)}
                              <br />
                            </>
                          )}
                        </>
                      )}
                      trigger="click"
                    >
                      <InfoCircleOutlined style={{ color: colors.dodgerBlue }} />
                    </Popover>
                  )}
                </p>
              );
            })}
          </InfoBlock>
        </Col>
        <Col span={12}>
          <InfoBlock>
            <b>{t("ShiftManager.ShiftReport.CashAmounts")}:</b>
            {terminals.map(({ id, name }) => (
              <p key={id}>
                <span>
                  {name}:&nbsp;
                </span>
                <span
                  style={{
                    color: terminalsNotMatchingRevenue.includes(id) ? colors.red : colors.black,
                  }}
                >
                  {formatPrice(amounts.actual_amounts[id])}
                </span>
              </p>
            ))}
          </InfoBlock>
        </Col>
        <Col span={12}>
          <InfoBlock>
            <b>{t("ShiftManager.ShiftReport.CashAmountsAfterIncassation")}:</b>
            {terminals.map(({ id, name }) => (
              <p key={id}>
                {name}:&nbsp;
                {formatPrice(amounts.close_amounts[id])}
              </p>
            ))}
          </InfoBlock>
        </Col>
        <Col span={12}>
          <InfoBlock>
            <b>{t("ShiftManager.ShiftReport.Income")}:</b>
            {terminals.map(({ id, name }) => (
              <p key={id}>
                {name}:&nbsp;
                {formatPrice(cashRevenueByTerminal[id])}
              </p>
            ))}
          </InfoBlock>
        </Col>
        {paymentMethodsWithoutCash.length > 0 && (
          <Col span={onlinePaymentMethods.length > 0 ? 12 : 24}>
            <InfoBlock>
              <b>{t("ShiftManager.ShiftReport.OtherRevenue")}:</b>
              {paymentMethodsWithoutCash.map((pm) => (
                <p key={pm.id}>
                  {pm.type === PAYMENT_METHOD_TYPE.CUSTOM ? pm.name : PAYMENT_METHOD_NAME[pm.name]}:
                  &nbsp;
                  {formatPrice(revenueByPaymentMethods[pm.id])}
                </p>
              ))}
            </InfoBlock>
          </Col>
        )}
        {onlinePaymentMethods.length > 0 && (
          <Col span={paymentMethodsWithoutCash.length > 0 ? 12 : 24}>
            <InfoBlock>
              <b>{t("ShiftManager.ShiftReport.OnlinePaymentsRevenue")}:</b>
              {onlinePaymentMethods.map((pm) => (
                <p key={pm.id}>
                  {pm.type === PAYMENT_METHOD_TYPE.CUSTOM ? pm.name : PAYMENT_METHOD_NAME[pm.name]}:
                  &nbsp;
                  {formatPrice(onlinePayments[pm.id])}
                </p>
              ))}
            </InfoBlock>
          </Col>
        )}
      </Row>
      {terminalsNotMatchingRevenue.length > 0 && (
        <Alert
          style={{ fontSize: 18, marginTop: 10 }}
          message={t("ShiftManager.ShiftReport.WarningSumsNotMatching")}
          type="warning"
          showIcon
        />
      )}
      {Math.abs(correctionAmount) > 0.001 && (
        <Alert
          style={{ fontSize: 18, marginTop: 10 }}
          message={(
            <>
              {t("ShiftManager.ShiftReport.WarningCorrectionWillBeCreated", {
                sum: formatPrice(correctionAmount),
              })}
              <Input
                style={{ marginTop: 5 }}
                placeholder={t("ShiftManager.ShiftReport.DifferenceReason")}
                size="large"
                onFocus={keyboard.show}
                onBlur={keyboard.onBlur}
                value={keyboard.state}
                onChange={(e) => keyboard.setState(e.target.value)}
              />
            </>
            )}
          type="warning"
          showIcon
        />
      )}
      {incassationAmount > 0.001 && (
        <Alert
          style={{ fontSize: 18, marginTop: 10 }}
          message={t("ShiftManager.ShiftReport.InfoIncassationWillBeCreated", {
            sum: formatPrice(incassationAmount),
          })}
          type="info"
          showIcon
        />
      )}
      {syncStatus?.status === SYNC_STATUS.ERROR && (
        <Alert
          style={{ fontSize: 18, marginTop: 10 }}
          message={
            `${t("ShiftManager.ShiftReport.WarningSyncFailed")} ${syncStatus.lastSync
              ? `${t("ShiftManager.ShiftReport.LastSyncAt")} ${DateTime.fromMillis(syncStatus.lastSync)
                .toLocaleString(DateTime.DATETIME_SHORT)}`
              : ""}`
          }
          type="warning"
          showIcon
        />
      )}
      {syncStatus?.status === SYNC_STATUS.STOPPED && (
        <Alert
          style={{ fontSize: 18, marginTop: 10 }}
          message={
            `${t("ShiftManager.ShiftReport.WarningSyncStopped")} ${syncStatus.lastSync
              ? `${t("ShiftManager.ShiftReport.LastSyncAt")} ${DateTime.fromMillis(syncStatus.lastSync)
                .toLocaleString(DateTime.DATETIME_SHORT)}`
              : ""}`
          }
          type="warning"
          showIcon
        />
      )}
    </div>
  );
};

CloseShiftReport.propTypes = {
  amounts: PropTypes.object.isRequired,
  terminals: PropTypes.array.isRequired,
  cashRevenueByTerminal: PropTypes.object.isRequired,
  revenueByPaymentMethods: PropTypes.object.isRequired,
  expectedCashAmounts: PropTypes.object,
  correctionAmount: PropTypes.number,
  incassationAmount: PropTypes.number,
  keyboard: PropTypes.object,
};

export default CloseShiftReport;
