import { Model } from "@nozbe/watermelondb";
import {
  field, readonly, date, relation, children, json,
} from "@nozbe/watermelondb/decorators";

export default class OrderLine extends Model {
  static table = "order_lines";

  static associations = {
    orders: { type: "belongs_to", key: "order_id" },
    order_line_cancels: { type: "has_many", foreignKey: "order_line_id" },
    order_line_status_updates: { type: "has_many", foreignKey: "order_line_id" },
  };

  @field("menu_item_id") menu_item_id;
  @field("display_name") display_name;
  @field("count") count;
  @field("notes") notes;
  @field("price") price;
  @field("total_price") total_price;
  @field("unit") unit;
  @json("modifiers", (v) => v) modifiers;
  @field("status") status;
  @date("status_updated_at") status_updated_at;
  @readonly @date("created_at") created_at;
  @readonly @date("updated_at") updated_at;

  @relation("orders", "order_id") order;

  @children("order_line_cancels") order_line_cancels;
  @children("order_line_status_updates") order_line_status_updates;
}
