import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { EditOutlined } from "@ant-design/icons";
import { Col, notification, Row, Typography } from "antd";
import styled from "styled-components";
import _ from "lodash";
import { of } from "rxjs";
import { useObservable } from "rxjs-hooks";
import { distinctUntilChanged, map, switchMap } from "rxjs/operators";

import { useService } from "pos-service";
import {
  ORDER_TYPE,
  ORDER_LINE_STATUS,
  ORDER_PAYMENT_TYPE,
} from "constants/index";

import CurrentUser from "containers/current-user";

import CancelModal from "components/CancelModal";
import Card from "components/Card";
import PaymentModal from "components/PaymentModal";
import ScrollableContainer from "components/ScrollableContainer";
import colors from "theme/colors";

import CancelRowPopover from "./components/CancelRowPopover";
import ButtonsBlock from "./components/ButtonsBlock";
import NotesModal from "../NotesModal";
import CartItem from "./components/CartItem";
import PriceBlock from "./components/PriceBlock";

import CurrentOrder from "../../containers/currentOrder.container";

const AddOrderNotesButton = styled(Card)`
  display: flex;
  align-items: center;
  height: 50px;
  min-height: 50px;
  padding: 10px;
  line-height: 1;
`;

const PageContainer = styled(Col)`
  .highlight {
    animation: highlight 1s;
  }

  @keyframes highlight {
    0% {
      background: ${colors.shuttleGrey};
    }
    100% {
      background: none;
    }
  }
`;

const OrderNotesBlock = ({ notes, onClick, t, ...props }) => (
  <AddOrderNotesButton onClick={onClick} {...props}>
    <i>
      <Typography.Paragraph ellipsis={{ rows: 2 }} style={{ margin: 0 }}>
        <EditOutlined />
        &nbsp;
        {notes || t("cashier.Cashier.CartPanel.NotesModal.Order.ButtonTitle")}
      </Typography.Paragraph>
    </i>
  </AddOrderNotesButton>
);

OrderNotesBlock.propTypes = {
  notes: PropTypes.string,
  onClick: PropTypes.func,
  t: PropTypes.func,
};

const CartPanel = ({
  history,
  onChooseOrderLine,
  onResetOrder,
  onShowTablePanel,
  t,
  onOrderLineDeleted,
}) => {
  const currentOrder = CurrentOrder.useContainer();
  const { user } = CurrentUser.useContainer();
  const { service } = useService();

  const paid = useObservable((_s, inputs$) => inputs$.pipe(
    switchMap(([o]) => (o ? o.order_payments.observe() : of([]))),
    map((payments) => payments.reduce((amount, curr) =>
      amount + (curr.type === ORDER_PAYMENT_TYPE.PAYMENT ? curr.amount : -curr.amount), 0)),
    distinctUntilChanged(),
  ), 0, [currentOrder.order]);
  const orderPrice = useObservable((_s, inputs$) => inputs$.pipe(
    switchMap(([o]) => (o ? o.order_price_updates.observe() : of([]))),
    map((opuArr) => _.maxBy(opuArr, "id")),
  ), null, [currentOrder.order]);

  const toPay = currentOrder.listPrice - paid;

  const [cancelPopover, setCancelPopover] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedOrderLine, setSelectedOrderLine] = useState(null);
  const [orderLineNotesIndex, setOrderLineNotesIndex] = useState(null);
  const [showOrderNotesModal, setShowOrderNotesModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const showPopover = (e) => {
    const rowGeometry = e.currentTarget.getBoundingClientRect();
    setCancelPopover({
      coordinates: {
        left: rowGeometry.left + rowGeometry.width / 2,
        top: rowGeometry.top + rowGeometry.height - 20,
      },
    });
  };

  const handleCancelSelectedRow = () => {
    setCancelPopover(null);
    setShowCancelModal(true);
  };

  const handleCancelSubmit = (values) =>
    service.cancelOrderLine(user.id, currentOrder.order, selectedOrderLine.id, values)
      .then(() => setShowCancelModal(false))
      .catch(notification.error);

  const handleProcessOrder = (payments = []) => {
    const action = currentOrder.order ? currentOrder.changeOrder : currentOrder.createOrder;
    return action(payments)
      .then((order) => {
        history.push(order.type === ORDER_TYPE.IN_STORE ? "/open-orders" : "/delivery-orders");
        return order;
      })
      .catch(notification.error);
  };

  const handleOrderLineNotes = (notes) => {
    currentOrder.cart.updateOrderLine(orderLineNotesIndex, { notes });
    setOrderLineNotesIndex(null);
  };

  const handleOrderNotes = (notes) => {
    currentOrder.setOrderNotes(notes);
    setShowOrderNotesModal(false);
  };

  const currentNotesOrderLine = currentOrder.cart.orderLines[orderLineNotesIndex];

  const [bufferedOrderLines, setBufferedOrderLines] = useState(currentOrder.cart.orderLines);

  useEffect(() => {
    const changedOrderLines = _.difference(currentOrder.cart.orderLines, bufferedOrderLines);
    const indexOfChangedOrderLine = _.indexOf(currentOrder.cart.orderLines, changedOrderLines[0]);
    const changedRow = document.querySelector(`[id="order-line-open-${indexOfChangedOrderLine}"]`);
    if (changedRow) {
      changedRow.scrollIntoView({ block: "nearest" });
      changedRow.classList.add("highlight");
      setTimeout(() => changedRow.classList.remove("highlight"), 1000);
    }
    setBufferedOrderLines(currentOrder.cart.orderLines);
  }, [currentOrder.cart.orderLines]);

  const heightOffset = useMemo(() => {
    const BASE_OFFSET = 307;
    const PRICE_LINE_HEIGHT = 25;
    const linesCount = [
      paid,
      currentOrder.serviceFee > 0,
      currentOrder.discountAmount > 0,
      currentOrder.deliveryPrice > 0,
    ].filter((v) => v).length;
    return BASE_OFFSET + PRICE_LINE_HEIGHT * (linesCount > 0 ? linesCount + 1 : 0);
  }, [currentOrder.serviceFee, currentOrder.discountAmount, paid, currentOrder.deliveryPrice]);

  return (
    <PageContainer sm={24} md={10} lg={8} xl={7}>
      <ScrollableContainer deps={[currentOrder.cart.orderLines, heightOffset]}>
        {({ containerRef, showScrollButtons, UpButton, DownButton }) => (
          <>
            <div
              ref={containerRef}
              className="no-scroll-bar"
              style={{
                height: `calc(100vh - ${heightOffset}px)`,
                background: colors.surface,
                padding: 10,
                borderRadius: 8,
                overflowY: "scroll",
              }}
            >
              {currentOrder.cart.orderLines.map((orderLine, index, ols) => (
                <CartItem
                  t={t}
                  index={index}
                  id={`order-line-${orderLine.id || `open-${index}`}`}
                  key={orderLine.id || `open-${index}`}
                  isLast={index === (ols.length - 1)}
                  disabled={orderLine.id}
                  orderLine={orderLine}
                  onChangeOrderLineNote={() => setOrderLineNotesIndex(index)}
                  onOrderLineDeleted={onOrderLineDeleted}
                  onChangeOrderLine={(e) => {
                    if (orderLine.status === ORDER_LINE_STATUS.CANCELLED) return;
                    if (orderLine.id) {
                      setSelectedOrderLine(orderLine);
                      showPopover(e);
                    } else {
                      onChooseOrderLine(index, !!orderLine.modifiers);
                    }
                  }}
                />
              ))}
            </div>
            <Row gutter={8} style={{ marginTop: 8 }}>
              {showScrollButtons && (
                <>
                  <Col xs={4}>
                    <UpButton />
                  </Col>
                  <Col xs={4}>
                    <DownButton />
                  </Col>
                </>
              )}
              <Col xs={showScrollButtons ? 16 : 24}>
                <OrderNotesBlock
                  notes={currentOrder.notes}
                  onClick={() => setShowOrderNotesModal(true)}
                  t={t}
                />
              </Col>
            </Row>
          </>
        )}
      </ScrollableContainer>
      <PriceBlock
        t={t}
        toPay={toPay}
        serviceFee={currentOrder.serviceFee}
        discountAmount={currentOrder.discountAmount}
        orderLinesTotal={currentOrder.orderLinesTotal}
        discount={currentOrder.discount}
        servicePercent={currentOrder.servicePercent}
        deliveryPrice={currentOrder.deliveryPrice}
        paid={paid}
      />
      <ButtonsBlock
        onShowTablePanel={onShowTablePanel}
        onWithoutPayment={handleProcessOrder}
        onPay={() => setShowPaymentModal(true)}
        onResetOrder={onResetOrder}
        t={t}
        style={{ marginTop: 8 }}
      />
      {cancelPopover && (
        <CancelRowPopover
          onHide={() => setCancelPopover(null)}
          popover={cancelPopover}
          selectedOrderLine={selectedOrderLine}
          onCancelSelectedRow={handleCancelSelectedRow}
          t={t}
        />
      )}
      {showCancelModal && (
        <CancelModal
          order={currentOrder.order}
          partialRefundAmount={(1 - orderPrice.discount + orderPrice.service_percent)
            * selectedOrderLine.total_price}
          onSubmitCancel={handleCancelSubmit}
          onCancel={() => setShowCancelModal(false)}
          t={t}
        />
      )}
      {currentNotesOrderLine && (
        <NotesModal
          title={t("cashier.Cashier.CartPanel.NotesModal.OrderLine.Title", { subject: currentNotesOrderLine.display_name })}
          savedNotesKey={`orderLineNotes-${currentNotesOrderLine.category_id}`}
          initialValue={currentNotesOrderLine.notes}
          onCancel={() => setOrderLineNotesIndex(null)}
          onSubmit={handleOrderLineNotes}
          placeholder={t("cashier.Cashier.CartPanel.NotesModal.OrderLine.Placeholder")}
        />
      )}
      {showOrderNotesModal && (
        <NotesModal
          title={t("cashier.Cashier.CartPanel.NotesModal.Order.Title")}
          savedNotesKey="orderNotes"
          initialValue={currentOrder.notes}
          onCancel={() => setShowOrderNotesModal(false)}
          onSubmit={handleOrderNotes}
          placeholder={t("cashier.Cashier.CartPanel.NotesModal.Order.Placeholder")}
        />
      )}
      {showPaymentModal && (
        <PaymentModal
          onCancel={() => setShowPaymentModal(false)}
          history={history}
          order={currentOrder.order}
          listPrice={currentOrder.listPrice}
          onPay={handleProcessOrder}
          t={t}
        />
      )}
    </PageContainer>
  );
};

CartPanel.propTypes = {
  history: PropTypes.object.isRequired,
  onResetOrder: PropTypes.func,
  onChooseOrderLine: PropTypes.func,
  onShowTablePanel: PropTypes.func,
  t: PropTypes.func.isRequired,
  onOrderLineDeleted: PropTypes.func,
};

export default CartPanel;
