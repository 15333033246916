function getWebSocketServer() {
  if (window.electron) {
    return {
      ...window.electron.webSocketServer,
      available: true,
      subscribe: (action, callback) => window.electron.subscribe(`slmp-wss-${action}`, callback),
    };
  }
  return { available: false };
}

const {
  start, send, stop, subscribe, available, getClients,
} = getWebSocketServer();

export default {
  start, send, stop, subscribe, available, getClients,
};
