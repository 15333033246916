import React, { useEffect, useState } from "react";
import { useFullscreen } from "@straw-hat/react-fullscreen";

const useElectronFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(window.electron.isFullscreen());

  useEffect(() => window.electron.subscribe("slmp-fullscreen-changed", setIsFullscreen));

  return { isFullscreen, setFullscreen: window.electron.setFullscreen };
};

export default () => {
  const isInStandaloneMode = window.matchMedia("(display-mode: standalone)").matches
    || window.navigator.standalone
    || document.referrer.includes("android-app://")
    || window.cordova;

  if (isInStandaloneMode) {
    return { isEnabled: false, isFullscreen: true, setFullscreen: () => "no_op" };
  }

  const target = React.useRef(window.document.body);
  const {
    isFullscreen, setFullscreen,
  } = window.electron ? useElectronFullscreen() : useFullscreen(target);

  return { isEnabled: true, isFullscreen, setFullscreen };
};
