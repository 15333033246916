import React, { useState } from "react";
import PropTypes from "prop-types";

import Context from "./context";
import ShiftManager from ".";

const Provider = ({ children }) => {
  const [visible, setVisible] = useState();

  return (
    <Context.Provider value={{ show: () => setVisible(true) }}>
      {children}
      <ShiftManager
        visible={visible}
        onClose={() => setVisible(false)}
      />
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;
