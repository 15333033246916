import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Row } from "antd";
import colors from "theme/colors";
import PropTypes from "prop-types";

import DeviceStations from "./DeviceStations";
import DeviceSettings from "./DeviceSettings";

const DeviceTab = styled.div`
  flex: 1;
  padding: 10px 0px;
  text-align: center;
  border-width: 0px 0px 2px 0px;
  border-style: solid;
  font-size: 15px;
  cursor: pointer;
  border-color: ${(({ selected }) => (selected ? colors.dodgerBlue : colors.lightGrey))};
  color: ${(({ selected }) => (selected ? colors.dodgerBlue : colors.grey))};
`;

const TabsContainer = styled(Row)`
  display: flex;
  flex-direction: row;
  padding-right: 15px;
  padding-left: 15px;
`;

const DeviceTabs = ({ device, onDeviceChange }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("stations");
  return (
    <>
      <TabsContainer>
        <DeviceTab
          selected={selectedTab === "stations"}
          onClick={() => setSelectedTab("stations")}
          sm={12}
        >
          {t("devices.SelectedDevice.Stations.Title")}
        </DeviceTab>
        <DeviceTab
          selected={selectedTab === "settings"}
          onClick={() => setSelectedTab("settings")}
          sm={12}
        >
          {t("devices.SelectedDevice.Settings.Title")}
        </DeviceTab>
      </TabsContainer>
      {selectedTab === "stations" && (
        <DeviceStations
          stations={device.stations || {}}
          onStationChange={(key, checked) => onDeviceChange({
            stations: {
              ...device.stations || {},
              [key]: checked,
            },
          })}
        />
      )}
      {selectedTab === "settings" && (
        <DeviceSettings
          isEscPos={device.type === "printer-escpos"}
          settings={device.settings || {}}
          onSettingChange={(key, checked) => onDeviceChange({
            settings: {
              ...device.settings || {},
              [key]: checked,
            },
          })}
        />
      )}
    </>
  );
};

DeviceTabs.propTypes = {
  device: PropTypes.object,
  onDeviceChange: PropTypes.func,
};

export default DeviceTabs;
