import { DateTime } from "luxon";

export const INIT_LANG = {
  time: "Время",
  cashier: "Кассир",
  type: "Тип",
  notes: "Комментарий",
  orderType: {
    in_store: "В заведении",
    pickup: "С собой",
    delivery: "Доставка",
  },
  orderStatus: {
    changed: "изменен",
    cancelled: "отменен",
  },
  orderLineCancelled: "ОТМЕНЕН",
};

const getOrderLineNamePrefix = (order, orderLine, status) => {
  if (orderLine.status === "cancelled") return "- ";
  if (status === "changed" && Math.abs(order.updated_at - orderLine.created_at) < 1000) {
    return "+ ";
  }
  return "";
};

export const ESCPOS = (order, status, lang = INIT_LANG) => ([
  ["size"],
  ["text", `${lang.time}: ${DateTime.fromMillis(order.time).toLocaleString(DateTime.DATETIME_SHORT)}`],
  ["text", `${lang.cashier}: ${order.list_user}`],
  ["text", `${lang.type}: ${lang.orderType[order.type]}`],
  ["feed", 2],
  ["size", 1, 1],
  ["text", `${order.display_number} ${lang.orderStatus[status] || ""}`],
  ["size"],
  ["feed", 2],
  ...order.order_lines.flatMap((orderLine) => [
    ["tableCustom",
      [
        { text: `${getOrderLineNamePrefix(order, orderLine, status)}${orderLine.display_name}`, align: "LEFT", width: 0.9 },
        { text: orderLine.count, align: "RIGHT", width: 0.1 },
      ],
    ],
    ...(orderLine.notes ? [
      ["style", "I"],
      ["text", `   ${orderLine.notes}`],
      ["style", "NORMAL"],
    ] : []),
    ...(orderLine.status === "cancelled" ? [
      ["text", `   ${lang.orderCancelled}`],
    ] : []),
    ["lineSpace", 30],
    ["feed", 1],
    ["lineSpace"],
  ]),
  ["feed", 2],
]);
