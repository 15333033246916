/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { CloseOutlined, CreditCardOutlined, WalletOutlined } from "@ant-design/icons";

import colors from "theme/colors";
import { formatPrice } from "utils/currency";
import { PAYMENT_METHOD_TYPE } from "constants/index";

const Price = styled.span`
  font-size: 20px;
  margin-right: 10px;
  color: ${colors.black};
`;

const ContainerPrice = styled.div`
  background: ${(props) => (props.disabled ? colors.disabledSurface : colors.white)};
  color: ${(props) => (props.disabled ? colors.disabled : colors.black)};
  font-size: 18px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 8px 10px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border-color: ${(props) => {
    if (props.disabled) return colors.disabledBorder;
    return (props.isActive ? colors.dodgerBlue : "white");
  }};
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  width: 100%;
  align-items: flex-end;
`;

const ClearPaymentButton = styled(Button)`
  margin-left: 5px;
  height: 50px;
  width: 50px;
  font-size: 20px;
  border: 1px solid white;
`;

const DisabledText = styled.span`
  font-style: italic;
  font-weight: 500;
  margin: 2px;
`;

const PanelPaymentMethod = ({
  label,
  price,
  isActive,
  type,
  onClear,
  disabled,
  onClick,
  ...rest
}) => {
  const { t } = useTranslation();
  const PaymentMethodIcon = type === PAYMENT_METHOD_TYPE.CARD ? CreditCardOutlined : WalletOutlined;
  return (
    <div style={{ display: "flex" }}>
      <ContainerPrice
        disabled={disabled}
        isActive={isActive}
        onClick={disabled ? () => {} : onClick}
        {...rest}
      >
        <div style={{ display: "flex" }}>
          <PaymentMethodIcon style={{ marginRight: 7, fontSize: 26 }} />
          <label>{label}</label>
        </div>
        <div style={{ display: "flex" }}>
          {disabled
            ? <DisabledText>{t("cashier.Payment.PaymentPanel.PaymentMethodNotConfigured")}</DisabledText>
            : <Price>{formatPrice(price)}</Price>}
        </div>
      </ContainerPrice>
      <ClearPaymentButton
        onClick={() => onClear(type)}
        icon={<CloseOutlined style={{ fontSize: 20 }} />}
        disabled={disabled}
      />
    </div>
  );
};

PanelPaymentMethod.propTypes = {
  label: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClear: PropTypes.func.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default PanelPaymentMethod;
