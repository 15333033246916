import React, { createContext, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import SimpleKeyboard from "react-simple-keyboard";
import styled from "styled-components";

export const getKeyboardLayout = (language) => {
  if (language === "ru") {
    return {
      default: [
        "ё 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
        "{lock} й ц у к е н г ш щ з х ъ \\",
        "{shift} ф ы в а п р о л д ж э !",
        "{lang} \\ я ч с м и т ь б ю , .",
        "{space}",
      ],
      shift: [
        "Ё ! \" № ; % : ? * ( ) _ + {bksp}",
        "{lock} Й Ц У К Е Н Г Ш Щ З Х Ъ /",
        "{shift} Ф Ы В А П Р О Л Д Ж Э !",
        "{lang} / Я Ч С М И Т Ь Б Ю , .",
        "{space}"],
    };
  }
  return {
    default: [
      "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
      "{lock} q w e r t y u i o p [ ] \\",
      "{shift} a s d f g h j k l ; ' {enter}",
      "{lang} z x c v b n m , . / {shift}",
      "{space}",
    ],
    shift: [
      "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
      "{lock} Q W E R T Y U I O P { } |",
      "{shift} A S D F G H J K L : \" {enter}",
      "{lang} Z X C V B N M < > ? {shift}",
      "{space}",
    ],
  };
};

const KeyboardContainer = styled.div`
  .simple-keyboard.hg-theme-default {
    position: absolute;
    bottom: 0;
    z-index: 999999;
    background: white;
  }

  .simple-keyboard .hg-button {
    background: #606467;
    color: white;
    font-size: 18px;
    box-shadow: none;
    border-bottom: 0px;
    height: 45px;
  }

  .simple-keyboard .hg-button.hg-activeButton {
    background: #1890ff;
  }
`;

const KeyboardProvider = ({ children }) => {
  const keyboardRef = useRef();
  const [keyboardVisible, setKeyboardVisibility] = useState();
  const [keyboardState, setKeyboardState] = useState();
  const [layoutName, setLayoutName] = useState("shift");
  const [language, setLanguage] = useState("ru");

  const handleKeyPress = (button) => {
    if (button === "{lock}") {
      setLayoutName(layoutName === "default" ? "lock" : "default");
    } else if (button === "{shift}") {
      setLayoutName(layoutName === "default" ? "shift" : "default");
    } else if (button === "{lang}") {
      setLanguage(language === "ru" ? "en" : "ru");
    } else if (layoutName === "shift") {
      setLayoutName("default");
    }
  };

  const handleKeyboardState = (state) => {
    if (state === "") setLayoutName("shift");
    setKeyboardState(state);
  };

  useEffect(() => {
    if (keyboardState === "") setLayoutName("shift");
    keyboardRef.current?.setInput(keyboardState);
  }, [keyboardState]);

  return (
    <KeyboardContext.Provider
      value={[keyboardState, setKeyboardVisibility, setKeyboardState, keyboardVisible]}
    >
      {children}
      {keyboardVisible && (
        <KeyboardContainer>
          <SimpleKeyboard
            keyboardRef={(r) => {
              keyboardRef.current = r;
              r.setInput(keyboardState);
            }}
            layout={getKeyboardLayout(language)}
            display={{ "{lang}": "🌐" }}
            layoutName={layoutName === "default" ? "default" : "shift"}
            onChange={handleKeyboardState}
            onKeyPress={handleKeyPress}
            mergeDisplay
          />
        </KeyboardContainer>
      )}
    </KeyboardContext.Provider>
  );
};

KeyboardProvider.propTypes = {
  children: PropTypes.node,
};

export const KeyboardContext = createContext();
export default KeyboardProvider;
