/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { EditOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";

import { formatPrice } from "utils/currency";
import { stringifyModifiers } from "utils/helpers";
import {
  ORDER_LINE_STATUS_LABELS,
  ORDER_LINE_STATUS_COLORS,
  ORDER_LINE_STATUS_ICONS,
} from "constants/index";
import colors from "theme/colors";
import { ColoredCard } from "components/Card";

import Cart from "../../../containers/cart.container";

const StatusText = styled.small`
  color: ${(props) => props.color};
`;

const CartItemCard = styled(ColoredCard)`
  min-height: 80px;
  margin-bottom: ${(props) => (props.isLast ? 0 : 4)}px;
`;

const CounterButtonContainer = styled.div`
  cursor: pointer;
  position: absolute;
  height: 30px;
  width: 40px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.border};
`;

const MinusButtonContainer = styled(CounterButtonContainer)`
  border-bottom-left-radius: 5px;
  right: 40px;
  top: 0;
`;

const PlusButtonContainer = styled(CounterButtonContainer)`
  border-top-right-radius: 5px;
  right: 0;
  top: 0;
`;

const CartItem = ({
  orderLine,
  t,
  onChangeOrderLineNote,
  onOrderLineDeleted,
  disabled,
  onChangeOrderLine,
  isLast,
  index,
  ...props
}) => {
  const cart = Cart.useContainer();

  const handleChangeOrderLineNote = (e) => {
    if (onChangeOrderLineNote && !orderLine.id) {
      e.stopPropagation();
      onChangeOrderLineNote();
    }
  };

  const handleIncrement = (e) => {
    e.stopPropagation();
    cart.increment(index);
  };

  const handleDecrement = (e) => {
    e.stopPropagation();
    if (orderLine.count > 1) cart.decrement(index);
    else {
      cart.removeOrderLine(index);
      onOrderLineDeleted(index);
    }
  };

  const StatusIcon = ORDER_LINE_STATUS_ICONS[orderLine.status];

  return (
    <CartItemCard
      key={orderLine.id}
      title={orderLine.display_name}
      color={disabled ? colors.gainsboro : (orderLine.color || colors.defaultCartItemColor)}
      footerRightText={formatPrice(orderLine.total_price)}
      footerLeftText={t("cashier.Cashier.CartPanel.CartItem.Count", {
        count: orderLine.count, price: formatPrice(orderLine.price),
      })}
      footerTextColor={disabled ? colors.black : colors.white}
      onClick={onChangeOrderLine}
      isLast={isLast}
      body={(
        <div style={{ paddingBottom: 5, marginLeft: 15 }}>
          {orderLine.modifiers?.filter((m) => m.value.length > 0)
            .map((modifier) => (
              <div key={modifier.modifier_group_id}>
                {modifier.list_group_name}: {stringifyModifiers(modifier.value)}
              </div>
            ))}
          {orderLine.id ? (
            <>
              <StatusText color={ORDER_LINE_STATUS_COLORS[orderLine.status]}>
                <StatusIcon />
              &nbsp;
                {ORDER_LINE_STATUS_LABELS[orderLine.status]}
              </StatusText>
              {orderLine.notes && (
                <>
                  <br />
                  <i>{orderLine.notes}</i>
                </>
              )}
            </>
          ) : (
            <i onClick={handleChangeOrderLineNote}>
              <EditOutlined />
              &nbsp;
              {orderLine.notes || t("cashier.Cashier.CartPanel.NotesModal.OrderLine.ButtonTitle")}
            </i>
          )}
        </div>
      )}
      {...props}
    >
      {!orderLine.id && (
        <>
          <MinusButtonContainer onClick={handleDecrement}>
            <MinusOutlined style={{ color: "black", fontSize: 16 }} />
          </MinusButtonContainer>
          <PlusButtonContainer onClick={handleIncrement}>
            <PlusOutlined style={{ color: "black", fontSize: 16 }} />
          </PlusButtonContainer>
        </>
      )}
    </CartItemCard>
  );
};

CartItem.propTypes = {
  orderLine: PropTypes.object,
  onChangeOrderLineNote: PropTypes.func,
  onOrderLineDeleted: PropTypes.func,
  onChangeOrderLine: PropTypes.func,
  t: PropTypes.func,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isLast: PropTypes.bool,
  index: PropTypes.number,
};

export default CartItem;
