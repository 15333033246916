import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Modal, Row, Col } from "antd";
import styled from "styled-components";
import { useObservable } from "rxjs-hooks";
import { switchMap } from "rxjs/operators";

import ConnectData from "containers/connect.container";

import i18n from "i18n";
import { ORDER_PAYMENT_TYPE, PAYMENT_METHOD_NAME, PAYMENT_METHOD_TYPE } from "constants/index";
import { formatPrice } from "utils/currency";

import colors from "theme/colors";

const Block = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  border: 1px solid ${colors.whisper};
  min-height: 80px;
  background-color: ${(props) =>
    (props.isActive ? colors.dodgerBlue : colors.white)};
  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  span {
    padding: 15px;
    text-align: center;
    font-size: 16px;
    color: ${(props) => props.isActive && colors.white};
  }
`;

const REASONS = Object.values(i18n.t("const:cancel_reasons", { returnObjects: true }));

const CancelModal = ({ order, partialRefundAmount, onSubmitCancel, t, ...modalProps }) => {
  const { payment_methods } = ConnectData.useContainer();
  const orderPayments = useObservable((_s, inputs$) => inputs$.pipe(
    switchMap(([o]) => o.order_payments.observe()),
  ), [], [order]);

  const paid = orderPayments.reduce((amount, curr) =>
    amount + (curr.type === ORDER_PAYMENT_TYPE.PAYMENT ? curr.amount : -curr.amount), 0);

  const returnAmount = partialRefundAmount ?? paid;

  const returnMethods = useMemo(() => {
    const paymentsByMethodId = _.groupBy(orderPayments, (p) => p.payment_method_id);

    const retMethods = returnAmount > 0.001 ? Object.values(paymentsByMethodId)
      .map((methodPayments) => methodPayments.reduce((acc, curr) => ({
        payment_method: payment_methods.find((pm) => pm.id === curr.payment_method_id),
        amount: curr.type === ORDER_PAYMENT_TYPE.PAYMENT
          ? acc.amount + curr.amount : acc.amount - curr.amount,
      }), {
        amount: 0,
      }))
      .filter((pm) => pm.amount > 0)
      .flatMap((pm, idx, amountsWithMethods) => {
        const methods = [
          [{ payment_method: pm.payment_method, value: Math.min(pm.amount, returnAmount) }],
        ];

        if (amountsWithMethods.length > 1 && pm.amount < returnAmount) {
          // rotate array to prevent duplicated methods
          const rotated = amountsWithMethods.slice(idx).concat(amountsWithMethods.slice(0, idx));
          methods.push(rotated
            .reduce((acc, curr) => {
              const currentAmount = acc.reduce((amount, ref) => amount + ref.value, 0);
              const remaining = returnAmount - currentAmount;
              if (remaining > 0) {
                return acc.concat({
                  payment_method: curr.payment_method, value: Math.min(remaining, curr.amount),
                });
              }
              return acc;
            }, [])
            .sort((lhs, rhs) => lhs.payment_method.id - rhs.payment_method.id));
        }

        return methods;
      })
      .sort((lhs, rhs) => {
        const lhsTotalAmount = lhs.reduce((acc, curr) => acc + curr.value, 0);
        const rhsTotalAmount = rhs.reduce((acc, curr) => acc + curr.value, 0);
        return rhsTotalAmount - lhsTotalAmount;
      })
      .filter((method, idx, methods) => {
        const jsonStr = JSON.stringify(method);
        const firstIdx = methods.findIndex((m) => JSON.stringify(m) === jsonStr);
        return firstIdx === idx;
      })
      .map((method) => {
        if (method.length > 1) {
          const methodLabels = method.map((pm) =>
            `${pm.payment_method.type === PAYMENT_METHOD_TYPE.CUSTOM
              ? pm.payment_method.name
              : PAYMENT_METHOD_NAME[pm.payment_method.name]} (${formatPrice(pm.value)})`);
          return {
            label: `${i18n.t("cashier.Cashier.CartPanel.CancelModal.SplitReturn")}: ${
              methodLabels.join(" + ")}`,
            values: method.map((pm) =>
              ({ amount: pm.value, paymentMethodId: pm.payment_method.id })),
          };
        }
        const paymentMethodName = method[0].payment_method.type === PAYMENT_METHOD_TYPE.CUSTOM
          ? method[0].payment_method.name : PAYMENT_METHOD_NAME[method[0].payment_method.name];
        return {
          label: `${paymentMethodName} ${formatPrice(method[0].value)}`,
          values: [{
            amount: method[0].value,
            paymentMethodId: method[0].payment_method.id,
          }],
        };
      }) : [];

    return [{
      values: [], label: t("cashier.Cashier.CartPanel.CancelModal.NoReturn"),
    }].concat(retMethods);
  }, [orderPayments, payment_methods, paid, returnAmount]);

  const [reason, setReason] = useState(REASONS[0]);
  const [refunds, setRefunds] = useState(returnMethods[0].values);

  useEffect(() => {
    setRefunds(returnMethods[returnMethods.length > 1 ? 1 : 0].values);
  }, [returnMethods]);

  return (
    <Modal
      visible
      width="60%"
      closable={false}
      onOk={() => onSubmitCancel({ refunds, reason })}
      transitionName="none"
      maskTransitionName="none"
      {...modalProps}
    >
      <Row type="flex" gutter={16}>
        <Col sm={24} lg={12}>
          {returnMethods.map(({ values, label }) => (
            <Block
              onClick={() => setRefunds(values)}
              role="button"
              isActive={values === refunds}
              key={label}
            >
              <span>{label}</span>
            </Block>
          ))}
        </Col>
        <Col sm={24} md={12}>
          {REASONS.map((reasonOption) => (
            <Block
              key={reasonOption}
              onClick={() => setReason(reasonOption)}
              role="button"
              isActive={reasonOption === reason}
            >
              <span>{reasonOption}</span>
            </Block>
          ))}
        </Col>
      </Row>
    </Modal>
  );
};

CancelModal.propTypes = {
  order: PropTypes.object.isRequired,
  partialRefundAmount: PropTypes.number,
  onSubmitCancel: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default CancelModal;
