import React, { useState, useEffect, useRef } from "react";
import { Badge, Button, Row, Col } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";

import colors from "theme/colors";
import { Align } from "theme/globalStyles";
import { formatPrice } from "utils/currency";
import { ColoredCard } from "components/Card";

import CurrentOrder from "../../../containers/currentOrder.container";

const ModifierGroupTitle = styled.label`
  display: block;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 6px;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const AlignVertical = styled(Align)`
  align-items: center;
`;

const ModifierItemContainer = styled.div`
  .ant-badge {
    width: 100%;
  }

  .ant-badge-count {
    overflow: hidden;
    cursor: pointer;
    min-width: 30px;
    height: 30px;
    border-radius: 15px;
    line-height: 30px;
  }
`;

const ModifierGroup = ({
  modifierGroup,
  modifiers,
  onChoose,
  onRemove,
  t,
  ...rest
}) => (
  <div {...rest}>
    <ModifierGroupTitle
      dangerouslySetInnerHTML={{
        __html: modifierGroup.max_selection <= 1
          ? t("cashier.Cashier.MenuPanel.SelectModifier", { name: modifierGroup.name })
          : t("cashier.Cashier.MenuPanel.SelectModifiersRange", {
            from: modifierGroup.min_selection,
            to: modifierGroup.max_selection,
            name: modifierGroup.name,
          }),
      }}
    />
    <ModifierItemContainer>
      <Row gutter={[12, 8]}>
        {modifierGroup.modifier_items.map((item) => {
          const count = modifiers[modifierGroup.id]?.filter((el) => el.id === item.id).length;
          const displayCount = modifierGroup.max_selection > 1 && count > 0;
          const displayBadge = count > 0
            && !(modifierGroup.min_selection === 1 && modifierGroup.max_selection === 1);
          return (
            <Col key={item.id} xs={8} md={10} lg={8} xl={6}>
              <Badge
                count={displayBadge ? "—" : null}
                onClick={() => onRemove(item)}
                offset={[-5, 5]}
              >
                <ColoredCard
                  onClick={(e) => { e.stopPropagation(); onChoose(item); }}
                  color={count > 0 ? colors.defaultCartItemColor : colors.lightGrey}
                  title={item.name}
                  footerRightText={formatPrice(item.price)}
                  footerLeftText={displayCount ? `x${count}` : ""}
                  footerTextColor={count > 0 ? "white" : "black"}
                  style={{ lineHeight: 1.5 }}
                />
              </Badge>
            </Col>
          );
        })}
      </Row>
    </ModifierItemContainer>
  </div>
);

ModifierGroup.propTypes = {
  modifierGroup: PropTypes.object.isRequired,
  modifiers: PropTypes.object,
  onChoose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const ModifierItemPanel = ({
  menuItemGroup,
  selectedMenuItemIndex,
  orderLine,
  onSave,
  onCancel,
  onRemove,
  t,
}) => {
  const [modifiers, setModifiers] = useState({});
  const submitAfterStateChange = useRef(false);
  const { orderType } = CurrentOrder.useContainer();

  const menuItem = menuItemGroup.menu_items[0];
  const modifierGroups = menuItem.menu_item_modifier_groups.map((v) => v.modifier_group);

  const count = orderLine?.count || 1;
  const price = menuItem.price + Object.values(modifiers)
    .flat()
    .reduce((acc, item) => acc + item.price, 0);

  const handleDone = () => {
    const formattedModifiers = Object.keys(modifiers).map((groupId) => ({
      modifier_group_id: parseInt(groupId, 10),
      list_group_name: modifierGroups.find((group) => group.id === parseInt(groupId, 10)).name,
      value: modifiers[groupId].map((item) => ({
        id: item.id, list_name: item.name, price: item.price,
      })),
    }));

    onSave({
      menu_item_id: menuItem.id,
      display_name: menuItem.name,
      price,
      count,
      total_price: price * count,
      category_id: menuItemGroup.category_id,
      menu_group_id: menuItemGroup.id,
      unit: menuItem.product_id ? menuItem.product.unit : menuItem.tech_card.unit,
      modifiers: formattedModifiers,
      color: menuItemGroup.color,
    });
  };

  useEffect(() => {
    if (submitAfterStateChange.current) {
      handleDone();
    }
  }, [modifiers]);

  const isAllModifierGroupsSingular = modifierGroups
    .every((group) => group.min_selection === 1 && group.max_selection === 1);

  const doneButtonDisabled = modifierGroups.some(({ id, min_selection, max_selection }) => {
    const selectionCount = modifiers[id]?.length ?? 0;
    return selectionCount < min_selection || selectionCount > max_selection;
  });

  useEffect(() => {
    if (!doneButtonDisabled && isAllModifierGroupsSingular && !orderLine) {
      handleDone();
    }
  }, [doneButtonDisabled]);

  useEffect(() => {
    if (orderLine) {
      setModifiers(orderLine.modifiers.reduce((acc, modifier) => {
        const group = modifierGroups.find((g) => g.id === modifier.modifier_group_id);
        return {
          ...acc,
          [group.id]: modifier.value
            .map(({ id }) => group.modifier_items.find((item) => item.id === id)),
        };
      }, {}));
    } else {
      const defaultModifiersByOrderType = modifierGroups.reduce((acc, group) => ({
        ...acc,
        [group.id]: group.modifier_items
          .filter((i) => i.default_value?.[orderType])
          .slice(0, group.max_selection),
      }), {});

      const isReadyToSubmit = modifierGroups.every(({ id, min_selection, max_selection }) => {
        const selectionCount = defaultModifiersByOrderType[id].length;
        return selectionCount >= min_selection && selectionCount <= max_selection
          && selectionCount > 0;
      });

      if (isReadyToSubmit) {
        submitAfterStateChange.current = true;
      }

      setModifiers({
        ...Object.fromEntries(
          // eslint-disable-next-line no-unused-vars
          Object.entries(defaultModifiersByOrderType).filter(([key, value]) => value.length > 0),
        ),
      });
    }
  }, [orderLine]);

  const handleModifierItem = (group, item) => {
    const selectedItems = modifiers[group.id] || [];
    const isSelected = selectedItems.find((el) => el.id === item.id);

    const isRadioInput = group.min_selection === 1 && group.max_selection === 1;
    if (isRadioInput) {
      if (isSelected) return false;
      return setModifiers({ ...modifiers, [group.id]: [item] });
    }

    const isOneItemPerModifier = group.min_selection === 0 && group.max_selection === 1;
    if (isOneItemPerModifier) {
      return setModifiers({
        ...modifiers,
        [group.id]: isSelected
          ? selectedItems.filter((el) => el.id !== item.id)
          : [item],
      });
    }

    const value = selectedItems.length === group.max_selection
      ? selectedItems.slice(1).concat(item) : selectedItems.concat(item);
    return setModifiers({ ...modifiers, [group.id]: value });
  };

  const handleRemoveModifierItem = (group, item) => {
    const removeIndex = modifiers[group.id].findIndex((i) => i.id === item.id);
    setModifiers({
      ...modifiers,
      [group.id]: modifiers[group.id].filter((i, index) => removeIndex !== index),
    });
  };

  return (
    <div
      style={{
        background: colors.surface,
        padding: 10,
        borderRadius: 8,
      }}
    >
      <AlignVertical align="space-between">
        <div>
          <span style={{ fontSize: 22, fontWeight: 600, marginRight: 10 }}>
            {menuItem.name}
          </span>
          <span style={{ fontSize: 18 }}>
            {t("cashier.Cashier.MenuPanel.Total")}: {formatPrice(price * count)}
          </span>
        </div>
        <div>
          {selectedMenuItemIndex != null && (
            <StyledButton
              id="delete-item-btn"
              type="danger"
              onClick={() => onRemove(selectedMenuItemIndex)}
            >
              {t("Delete")}
            </StyledButton>
          )}
          <StyledButton
            id="done-item-btn"
            type="primary"
            onClick={handleDone}
            disabled={doneButtonDisabled}
          >
            {t("Done")}
          </StyledButton>
          <Button id="cancel-item-btn" onClick={onCancel}>
            {t("Cancel")}
          </Button>
        </div>
      </AlignVertical>
      {modifierGroups.map((group) => (
        <ModifierGroup
          key={group.id}
          modifierGroup={group}
          onChoose={(item) => handleModifierItem(group, item)}
          onRemove={(item) => handleRemoveModifierItem(group, item)}
          modifiers={modifiers}
          t={t}
        />
      ))}
    </div>
  );
};

ModifierItemPanel.propTypes = {
  menuItemGroup: PropTypes.object,
  selectedMenuItemIndex: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  t: PropTypes.func.isRequired,
  orderLine: PropTypes.object,
};

export default ModifierItemPanel;
