import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";
import { List, Row, Col, Tag, Typography } from "antd";
import { DateTime } from "luxon";
import { Q } from "@nozbe/watermelondb";
import { useObservable } from "rxjs-hooks";
import { map, switchMap } from "rxjs/operators";
import { CreditCardOutlined } from "@ant-design/icons";

import { useService } from "pos-service";
import ConnectData from "containers/connect.container";
import { ORDER_TYPE } from "constants/index";
import colors from "theme/colors";
import { formatPrice } from "utils/currency";

import { PAYMENT_TYPE_ICONS } from "../constants";

const { Text, Title } = Typography;

const OrderItem = styled(List.Item)`
  cursor: pointer;
  padding: 7px 10px;
  :hover {
    background-color: ${colors.whisper};
  }
  ${(props) => props.$isActive && css`
    background-color: ${colors.whisper};
  `}

  .number {
    display: inline;
  }

  .location {
    width: 130px;
    text-align: right;
  }

  .payments .anticon {
    padding-left: 6px;
  }

  .ant-tag {
    margin-right: 0px;
  }
`;

const OrderCard = ({ t, order, isActive, onSelect }) => {
  const { service } = useService();
  const { payment_methods } = ConnectData.useContainer();

  const orderPayments = useObservable((_s, inputs$) => inputs$.pipe(
    switchMap(([o]) => o.order_payments.observe()),
    map((payments) => payments.sort((lhs, rhs) => lhs.created_at - rhs.created_at)),
  ), [], [order]);
  const orderLines = useObservable((_s, inputs$) => inputs$.pipe(
    switchMap(([orderId]) => service.db.get("order_lines").query(
      Q.where("order_id", orderId),
    ).observeWithColumns("updated_at")),
    map((arr) => arr.sort((lhs, rhs) => lhs.id.localeCompare(rhs.id))),
  ), [], [order.id]);

  const { street, house, flat } = order.delivery_info?.address || {};

  const [paymentColor, paymentMessage, paymentIcons] = useMemo(() => {
    const paid = orderPayments.reduce((amount, curr) =>
      amount + (curr.type === "payment" ? curr.amount : -curr.amount), 0);

    const icons = _(orderPayments)
      .map((op) => payment_methods.find((pm) => pm.id === op.payment_method_id).type)
      .uniq()
      .map((type) => {
        const Icon = PAYMENT_TYPE_ICONS[type];
        return <Icon key={type} />;
      })
      .value();

    if (order.list_price < 0.001) {
      return [colors.white, "", []];
    }
    if (Math.abs(order.list_price - paid) < 0.001) {
      return [colors.green, t("archive.OrderCard.Payment.Paid"), icons];
    }
    if (paid > 0.001) {
      return [colors.yellow, t("archive.OrderCard.Payment.PaidPartially"), icons];
    }
    if (orderPayments.some((op) => op.type === "refund")) {
      return [colors.red, t("archive.OrderCard.Payment.Refunded"), icons];
    }
    return [
      colors.lightGrey, t("archive.OrderCard.Payment.NotPaid"), [<CreditCardOutlined key="card" />],
    ];
  }, [order.list_price, orderPayments]);

  const detailsTextStyle = { width: "100%", fontSize: "0.9em", display: "block" };

  return (
    <OrderItem $isActive={isActive} onClick={() => onSelect(order)}>
      <Row type="flex" align="middle" justify="space-between">
        <Col>
          <Title level={4} className="number">{order.display_number}</Title>
          &nbsp;
          <Text>{DateTime.fromJSDate(order.time).toLocaleString(DateTime.TIME_SIMPLE)}</Text>
        </Col>
        <Col>
          <Text strong>{formatPrice(order.list_price)}</Text>
        </Col>
      </Row>
      <Row>
        <Col offset={1} span={23}>
          <Text ellipsis style={detailsTextStyle} type="secondary">
            {orderLines.map((ol) => ol.display_name).join(", ")}
          </Text>
        </Col>
      </Row>
      {order.type === ORDER_TYPE.DELIVERY && (
        <Row>
          <Col offset={1} span={23}>
            <Text ellipsis style={detailsTextStyle} type="secondary">
              {[street, house, flat].filter((el) => el).join(", ")}
            </Text>
          </Col>
        </Row>
      )}
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <span className="payments" style={{ color: paymentColor }}>
            {paymentIcons}
            &nbsp;
            <span style={{ fontSize: "0.9em" }}>{paymentMessage}</span>
          </span>
        </Col>
        <Col>
          <Tag color={colors.statusColors[order.status]}>
            {t(`const:order_status.${order.status}`)}
          </Tag>
        </Col>
      </Row>
    </OrderItem>
  );
};

OrderCard.propTypes = {
  order: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default OrderCard;
