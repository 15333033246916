import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useLocalStorage } from "utils/useLocalStorage";
import useKeyboard from "components/Keyboard/useKeyboard";
import FullscreenModal from "components/FullscreenModal";
import colors from "theme/colors";

import "react-simple-keyboard/build/css/index.css";

const Container = styled(FullscreenModal)`
  .simple-keyboard.hg-theme-default.salempos-theme {
    background: transparent;
  }

  .simple-keyboard.hg-layout-default .hg-button {
    background: ${colors.nevada};
    color: white;
    font-size: 18px;
    box-shadow: none;
    border-bottom: 0px;
    height: 45px;
  }

  .simple-keyboard.hg-layout-default .hg-button.hg-activeButton {
    background: ${colors.dodgerBlue};
  }

  .close-icon {
    svg {
      font-size: 60px;
    }
  }

  .ant-input {
    font-size: 22px;
  }

  .ant-input-clear-icon {
    font-size: 22px;
  }
`;

const ContentContainer = styled.div`
  padding: 40px 50px;
`;

const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 395px);
`;

const Title = styled.span`
  font-size: 26px;
  width: 100%;
`;

const NotesInput = styled(Input)`
  width: 60%;
  height: 50px;
`;

const SaveButton = styled(Button)`
  font-size: 20px;
  height: 50px;
  margin-left: 10px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SavedNotesContainer = styled.div`
  display: inline-block;
  padding: 20px 0px;
  max-height: 150px;
  overflow: hidden;
  width: 70%;
`;

const NotesModal = ({
  onCancel, onSubmit, title, savedNotesKey, initialValue, placeholder,
}) => {
  const { t } = useTranslation();
  const keyboard = useKeyboard({ initialValue, showInitially: true });

  const [savedNotes, setSavedNotes] = useLocalStorage(savedNotesKey, {});

  const savedNotesSorted = useMemo(
    () => Object.entries(savedNotes)
      // eslint-disable-next-line no-unused-vars
      .sort(([note1, count1], [note2, count2]) => count2 - count1)
      .map(([note]) => note),
    [savedNotes],
  );

  const handleSave = () => {
    const newNote = keyboard.state && keyboard.state.trim().length > 0
      ? keyboard.state.trim() : null;
    if (newNote && savedNotesKey) {
      const notesArray = newNote.split(",").map((n) => n.trim()).filter((n) => n);
      setSavedNotes({
        ...savedNotes,
        ...notesArray.reduce((acc, note) => {
          acc[note] = (savedNotes[note] || 0) + 1;
          return acc;
        }, {}),
      });
    }
    onSubmit(newNote);
    keyboard.setState("");
  };

  const addSavedNote = (savedNote) => {
    const notesArray = keyboard.state?.split(",").map((n) => n.trim()).filter((n) => n) || [];
    if (notesArray.includes(savedNote)) {
      keyboard.setState(notesArray.filter((note) => note !== savedNote).join(", "));
    } else {
      keyboard.setState([...notesArray, savedNote].join(", "));
    }
  };

  return (
    <Container>
      <ContentContainer>
        <HeaderContainer>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <CloseOutlined className="close-icon" onClick={onCancel} />
        </HeaderContainer>
        <NotesContainer>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <NotesInput
              size="large"
              value={keyboard.state}
              name="notes"
              onChange={(e) => keyboard.setState(e.target.value)}
              placeholder={placeholder}
              allowClear
            />
            <SaveButton type="primary" onClick={handleSave}>
              {t("cashier.Cashier.CartPanel.NotesModal.Save")}
            </SaveButton>
          </div>
          <SavedNotesContainer>
            {savedNotesSorted.map((note) => (
              <Button
                key={note}
                style={{ marginRight: 10, marginBottom: 5 }}
                size="large"
                onClick={() => addSavedNote(note)}
                type={keyboard.state.split(", ").some((n) => n === note) ? "primary" : "default"}
              >
                {note}
              </Button>
            ))}
          </SavedNotesContainer>
        </NotesContainer>
      </ContentContainer>
    </Container>
  );
};

NotesModal.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  savedNotesKey: PropTypes.string,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
};

export default NotesModal;
