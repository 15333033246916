import { Model } from "@nozbe/watermelondb";
import { field, readonly, date, relation } from "@nozbe/watermelondb/decorators";

export default class OrderCancel extends Model {
  static table = "order_cancels";

  static associations = {
    orders: { type: "belongs_to", key: "order_id" },
  };

  @field("reason") reason;
  @field("notes") notes;
  @field("refund") refund;
  @field("user_id") user_id;
  @field("source") source;
  @readonly @date("created_at") created_at;

  @relation("orders", "order_id") order;
}
