import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row } from "antd";
import { useTranslation } from "react-i18next";

import ConnectData from "containers/connect.container";

import CartPanel from "./components/CartPanel";
import MenuPanel from "./components/MenuPanel";
import TablePanel from "./components/TablePanel";

import CurrentOrder from "./containers/currentOrder.container";

import { PANEL_STATE } from "./constants";

const Container = styled.div`
  margin: 8px 12px 0;
`;

const Cashier = ({ history }) => {
  const { t } = useTranslation();

  const currentOrder = CurrentOrder.useContainer();
  const { menu_categories } = ConnectData.useContainer();

  const [menuPanelState, setMenuPanelState] = useState({
    state: PANEL_STATE.CATEGORY,
    menu_category_id: menu_categories[0].id,
  });
  const [showTablePanel, setShowTablePanel] = useState(false);

  const handleSelectCategory = (categoryId) => {
    setMenuPanelState({
      state: PANEL_STATE.CATEGORY,
      menu_category_id: categoryId || menu_categories[0].id,
    });
  };

  const handleChooseOrderLine = (index, hasModifiers) => {
    setMenuPanelState({
      state: hasModifiers ? PANEL_STATE.EDIT_MODIFIER : PANEL_STATE.EDIT,
      cart_index: index,
    });
  };

  const handleSaveMenuItem = (orderLine) => {
    if ([PANEL_STATE.EDIT, PANEL_STATE.EDIT_MODIFIER].includes(menuPanelState.state)) {
      currentOrder.cart.updateOrderLine(menuPanelState.cart_index, orderLine);
    } else {
      currentOrder.cart.addOrderLine(orderLine);
    }
    handleSelectCategory(orderLine.category_id);
  };

  const convertDataForCart = (menuGroup) => {
    const menuItem = menuGroup.menu_items[0];
    return {
      count: 1,
      total_price: parseFloat(menuItem.price),
      menu_group_id: menuGroup.id,
      category_id: menuGroup.category_id,
      menu_item_id: menuItem.id,
      unit: menuItem.product_id ? menuItem.product.unit : menuItem.tech_card.unit,
      display_name: menuGroup.name,
      price: parseFloat(menuItem.price),
      color: menuGroup.color,
    };
  };

  const handleSelectOrAddMenuItem = (menuItemGroup) => {
    if (menuItemGroup.menu_items.length === 1) {
      if (menuItemGroup.menu_items[0].menu_item_modifier_groups.length > 0) {
        setMenuPanelState({
          state: PANEL_STATE.NEW_MODIFIER,
          menu_category_id: menuItemGroup.category_id,
          menu_group_id: menuItemGroup.id,
        });
      } else {
        currentOrder.cart.addOrderLine(convertDataForCart(menuItemGroup));
        setMenuPanelState({
          state: PANEL_STATE.CATEGORY,
          menu_category_id: menuPanelState.menu_category_id,
        });
      }
    } else {
      setMenuPanelState({
        state: PANEL_STATE.NEW,
        menu_category_id: menuItemGroup.category_id,
        menu_group_id: menuItemGroup.id,
      });
    }
  };

  const handleRemoveOrderLineAndReturnHome = (index) => {
    handleSelectCategory();
    currentOrder.cart.removeOrderLine(index);
  };

  const handleResetOrder = () => {
    handleSelectCategory();
    currentOrder.clear();
  };

  return (
    <Container>
      <Row gutter={8}>
        <MenuPanel
          state={menuPanelState}
          onSelectCategory={handleSelectCategory}
          onSaveMenuItem={handleSaveMenuItem}
          onSelectMenuItem={handleSelectOrAddMenuItem}
          onRemoveOrderLineAndReturnHome={handleRemoveOrderLineAndReturnHome}
          onBackToCategory={handleSelectCategory}
          t={t}
        />
        <CartPanel
          history={history}
          onChooseOrderLine={handleChooseOrderLine}
          onResetOrder={handleResetOrder}
          onShowTablePanel={() => setShowTablePanel(true)}
          t={t}
          menuPanelState={menuPanelState}
          onOrderLineDeleted={(index) => {
            if (menuPanelState.cart_index === index) handleSelectCategory();
          }}
        />
      </Row>
      {showTablePanel && <TablePanel t={t} onClose={() => setShowTablePanel(false)} />}
    </Container>
  );
};

Cashier.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Cashier;
