import {
  BellOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  LikeOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import i18n from "../i18n";

export const ORDER_STATUS_LABELS = i18n.t("const:order_status", { returnObjects: true });
export const ORDER_LINE_STATUS_LABELS = i18n.t("const:order_line_status", { returnObjects: true });

export const ORDER_LINE_STATUS_ICONS = {
  created: BellOutlined,
  received: ClockCircleOutlined,
  processing: ThunderboltOutlined,
  ready: CheckOutlined,
  served: LikeOutlined,
  cancelled: CloseOutlined,
};

export const ORDER_LINE_STATUS_COLORS = {
  received: "#e7707d",
  processing: "#28cacc",
  ready: "#83d160",
  served: "#ffce45",
  created: "#bb1f1f",
  cancelled: "#fb3e44",
};

export const ORDER_TYPE_LABELS = i18n.t("const:order_type", { returnObjects: true });

export const ORDER_STATUS = {
  RECEIVED: "received",
  PROCESSING: "processing",
  READY: "ready",
  ON_DELIVERY: "on_delivery",
  SERVED: "served",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
};

export const ORDER_LINE_STATUS = {
  RECEIVED: "received",
  PROCESSING: "processing",
  READY: "ready",
  SERVED: "served",
  CANCELLED: "cancelled",
};

export const ORDER_TYPE = {
  IN_STORE: "in_store",
  PICKUP: "pickup",
  DELIVERY: "delivery",
};

export const PAYMENT_METHOD_TYPE = {
  CASH: "cash",
  CARD: "card",
  CUSTOM: "custom",
};

export const ORDER_PAYMENT_TYPE = {
  PAYMENT: "payment",
  REFUND: "refund",
};

export const PAYMENT_METHOD_NAME = {
  salempos_generated_card: i18n.t("const:payment_method_name.card"),
  salempos_generated_cash: i18n.t("const:payment_method_name.cash"),
};
