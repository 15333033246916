import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import PropTypes from "prop-types";

import { useService } from "pos-service";

import { ORDER_TYPE } from "constants/index";

import Orders from "containers/orders.container";
import CurrentUser from "containers/current-user";

import OrdersList from "components/OrdersList";

import OrderCard from "./components/OrderCard";
import AssignCourierModal from "./components/AssignCourierModal";
import OrderListHeader from "./components/OrderListHeader";

const DeliveryOrders = ({ history }) => {
  const { t } = useTranslation();
  const { user } = CurrentUser.useContainer();
  const { service } = useService();
  const { openOrders, deliveryStats } = Orders.useContainer();
  const [orderToAssignCourier, setOrderToAssignCourier] = useState(null);

  const deliveryAndPickupOrders = openOrders.filter(
    (order) => order.type === ORDER_TYPE.DELIVERY || order.type === ORDER_TYPE.PICKUP,
  );

  const handleAssignCourier = (courier) =>
    service.assignCourier(user.id, orderToAssignCourier, courier.id)
      .then(() => setOrderToAssignCourier(null))
      .catch(notification.error);

  const renderOrderCard = (
    order, setOrderToPay, setOrderToCancel, syncScrollButtons, isLast,
  ) => (
    <OrderCard
      t={t}
      key={order.id}
      order={order}
      onPay={setOrderToPay}
      onCancel={setOrderToCancel}
      onAssignCourier={setOrderToAssignCourier}
      onExpand={syncScrollButtons}
      isLast={isLast}
    />
  );

  return (
    <>
      <OrdersList
        history={history}
        orders={deliveryAndPickupOrders}
        stats={deliveryStats}
        renderOrderCard={renderOrderCard}
        listHeader={<OrderListHeader t={t} />}
      />
      {orderToAssignCourier && (
        <AssignCourierModal
          order={orderToAssignCourier}
          onSelectCourier={handleAssignCourier}
          onClose={() => setOrderToAssignCourier(null)}
        />
      )}
    </>
  );
};

DeliveryOrders.propTypes = {
  history: PropTypes.object,
};

export default DeliveryOrders;
