import React from "react";
import PropTypes from "prop-types";

import Orders from "containers/orders.container";

import CurrentOrder from "./containers/currentOrder.container";
import Cart from "./containers/cart.container";

import Cashier from "./Cashier";

const CashierContainer = ({ match, history }) => {
  const { openOrders } = Orders.useContainer();
  const foundOrder = match.params.id && openOrders.find((o) => o.id === match.params.id);
  if (match.params.id && !foundOrder) {
    history.push("/open-orders");
    return null;
  }
  return (
    <Cart.Provider initialState={foundOrder?.id}>
      <CurrentOrder.Provider initialState={foundOrder}>
        <Cashier history={history} />
      </CurrentOrder.Provider>
    </Cart.Provider>
  );
};

CashierContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default CashierContainer;
