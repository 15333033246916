import React, { useState } from "react";
import { Modal, notification, Spin } from "antd";
import PropTypes from "prop-types";
import { of } from "rxjs";
import { distinctUntilChanged, map, switchMap } from "rxjs/operators";
import { useObservable } from "rxjs-hooks";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";

import { useLocalStorage } from "utils/useLocalStorage";
import { printCheck } from "utils/print";
import ConnectData from "containers/connect.container";
import colors from "theme/colors";
import { ORDER_PAYMENT_TYPE } from "constants/index";

import pos from "utils/pos";
import CustomPaymentPanel from "./components/CustomPaymentPanel";
import PaymentPanel from "./components/PaymentPanel";

const LargeSpin = styled(Spin)`
  .ant-spin {
    max-height: 600px;
  }
  .ant-spin-text {
    padding-top: 30px !important;
    margin-left: 10px;
    font-size: 24px;
  }
  .ant-spin-dot {
    margin: -20px;
  }
`;

const PaymentModal = ({
  history, onPay, listPrice, order, t, ...modalProps
}) => {
  const [isPayingWithPOS, setPayingWithPOS] = useState(false);
  const [customPaymentMethodId, setCustomPaymentMethodId] = useState(null);
  const [printSettings, setPrintSettings] = useLocalStorage("print_settings", {
    ifJustCreated: true, ifCreatedBefore: true,
  });

  const {
    check_settings, payment_methods, pos_payment_methods, location,
  } = ConnectData.useContainer();
  const paid = useObservable((_s, inputs$) => inputs$.pipe(
    switchMap(([o]) => (o ? o.order_payments.observe() : of([]))),
    map((payments) => payments.reduce((amount, curr) =>
      amount + (curr.type === ORDER_PAYMENT_TYPE.PAYMENT ? curr.amount : -curr.amount), 0)),
    distinctUntilChanged(),
  ), 0, [order]);

  const toPay = listPrice - paid;

  const handlePay = (payments, posDevice, posPayment) => {
    if (posDevice) setPayingWithPOS(true);
    return (posDevice ? pos.pay(posDevice, posPayment) : Promise.resolve())
      .then((metadata) => onPay(
        metadata
          ? payments.map((p) => (p.paymentMethodId === posPayment.paymentMethodId
            ? { ...p, metadata } : p))
          : payments,
      ))
      .then((updatedOrder) => {
        if (order ? printSettings.ifCreatedBefore : printSettings.ifJustCreated) {
          printCheck(updatedOrder, check_settings, payment_methods);
        }
      })
      .catch((err) => {
        if (posDevice) {
          Modal.confirm({
            title: t("cashier.Payment.PaymentPanel.PayWithPOSError"),
            okText: t("cashier.Payment.PaymentPanel.Confirm"),
            cancelText: t("Close"),
            onOk: () => handlePay(payments),
          });
        } else {
          notification.error(err);
        }
      })
      .finally(() => setPayingWithPOS(false));
  };

  return (
    <Modal
      width="60%"
      closable={false}
      transitionName="none"
      maskTransitionName="none"
      visible
      style={{ top: 10 }}
      bodyStyle={{ background: colors.background, margin: 0, borderRadius: 8 }}
      footer={false}
      {...modalProps}
    >
      <LargeSpin
        spinning={isPayingWithPOS}
        tip={t("cashier.Payment.PaymentPanel.PayingWithPOS")}
        indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
      >
        {!customPaymentMethodId ? (
          <PaymentPanel
            paymentAmount={toPay}
            location={location}
            isNewOrder={!order}
            paymentMethods={pos_payment_methods}
            onOrderPayment={handlePay}
            onSetCustomPaymentMethod={(cpm) => setCustomPaymentMethodId(cpm.id)}
            onPrintSettingsChange={setPrintSettings}
            printSettings={printSettings}
            t={t}
            onClose={modalProps.onCancel}
          />
        ) : (
          <CustomPaymentPanel
            paymentAmount={toPay}
            isNewOrder={!order}
            paymentMethod={pos_payment_methods.find((pm) => pm.id === customPaymentMethodId)}
            onOrderPayment={handlePay}
            onCancel={() => setCustomPaymentMethodId(null)}
            onPrintSettingsChange={setPrintSettings}
            printSettings={printSettings}
            t={t}
            onClose={modalProps.onCancel}
          />
        )}
      </LargeSpin>
    </Modal>
  );
};

PaymentModal.propTypes = {
  history: PropTypes.object.isRequired,
  onPay: PropTypes.func.isRequired,
  listPrice: PropTypes.number.isRequired,
  order: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default PaymentModal;
