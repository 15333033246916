import React from "react";
import PropTypes from "prop-types";
import useKeyboard from "components/Keyboard/useKeyboard";
import { Button, Col, Input, Row } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import colors from "theme/colors";

const SelectItem = styled(Col)`
  background: ${colors.surface};
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => (props.active ? colors.dodgerBlue : colors.border)};
  border-radius: 5px;
  padding: 15px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
`;

const SearchSelector = ({
  items, value, onSelect, onClose, placeholder, containerStyle,
}) => {
  const { t } = useTranslation();
  const keyboard = useKeyboard();

  const filteredItems = keyboard.state
    ? items.filter((i) => i.name.startsWith(keyboard.state))
    : items;

  return (
    <div style={{ ...containerStyle, background: colors.background }}>
      <div style={{ display: "flex", flexDirection: "row", padding: 10 }}>
        <Input
          size="large"
          placeholder={placeholder}
          onFocus={keyboard.show}
          onBlur={keyboard.onBlur}
          value={keyboard.state}
          onChange={(e) => keyboard.setState(e.target.value)}
        />
        <Button
          style={{ marginLeft: 5 }}
          size="large"
          danger
          onClick={onClose}
        >
          {t("Cancel")}
        </Button>
      </div>
      <div style={{ margin: "0 10px" }}>
        <Row gutter={[8, 8]}>
          {filteredItems.map((i) => (
            <Col span={6} key={i.id}>
              <SelectItem active={i.id === value?.id} onClick={() => { onSelect(i); onClose(); }}>
                {i.name}
              </SelectItem>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

SearchSelector.propTypes = {
  items: PropTypes.array.isRequired,
  value: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  containerStyle: PropTypes.object,
  placeholder: PropTypes.string,
};

export default SearchSelector;
