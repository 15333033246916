import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import Numpad from "@salempos/numpad";

import useKeyboard from "components/Keyboard/useKeyboard";
import ConnectData from "containers/connect.container";
import CurrentUser from "containers/current-user";
import { useService } from "pos-service";
import colors from "theme/colors";

import DateTimeBlock from "./DateTimeBlock";
import OpenShiftReport from "./OpenShiftReport";

const OPEN_SHIFT_STAGES = ["open_cash_amounts", "summary"];
const LAST_STAGE = OPEN_SHIFT_STAGES[OPEN_SHIFT_STAGES.length - 1];

const OpenShift = ({ onClose }) => {
  const { t } = useTranslation();
  const { terminals } = ConnectData.useContainer();
  const { user } = CurrentUser.useContainer();
  const { service } = useService();
  const keyboard = useKeyboard();

  const [openCashAmounts, setOpenCashAmounts] = useState({});
  const [selectedInput, setSelectedInput] = useState(terminals[0].id);
  const [stage, setStage] = useState(OPEN_SHIFT_STAGES[0]);

  const lastClosedShift = service.lastClosedShift.getValue();

  const handleValue = (number) => {
    setOpenCashAmounts({
      ...openCashAmounts,
      [selectedInput]: number,
    });
  };

  const correctionAmount = useMemo(() => {
    if (!lastClosedShift) {
      return 0;
    }
    const openTotal = Object.values(openCashAmounts)
      .reduce((total, amount) => total + parseFloat(amount), 0);
    const closeTotal = Object.values(lastClosedShift.close_cash_amounts)
      .reduce((total, amount) => total + parseFloat(amount), 0);
    return closeTotal - openTotal;
  }, [openCashAmounts, lastClosedShift?.close_cash_amounts]);

  const submitDisabled = useMemo(() =>
    terminals.some((term) => !openCashAmounts[term.id]),
  [openCashAmounts]);

  const handleBack = () => {
    setStage(OPEN_SHIFT_STAGES[OPEN_SHIFT_STAGES.indexOf(stage) - 1]);
  };

  const handleOk = () => {
    if (Math.abs(correctionAmount) > 0.01 && stage === OPEN_SHIFT_STAGES[0]) {
      setStage(OPEN_SHIFT_STAGES[OPEN_SHIFT_STAGES.indexOf(stage) + 1]);
    } else {
      service.openShift(
        user.id,
        openCashAmounts,
        { amount: correctionAmount, reason: keyboard.state },
      ).then(onClose);
    }
  };

  return (
    <Modal
      visible
      onCancel={onClose}
      onOk={handleOk}
      title={t("ShiftManager.OpeningShift")}
      centered
      bodyStyle={stage === LAST_STAGE ? { background: colors.background } : {}}
      width="90%"
      style={{ maxWidth: 850, top: keyboard.visible ? "-20%" : 0 }}
      footer={[
        stage === OPEN_SHIFT_STAGES[0] ? (
          <Button key="cancel" size="large" onClick={onClose}>
            {t("Cancel")}
          </Button>
        ) : (
          <Button key="back" size="large" onClick={handleBack}>
            {t("ShiftManager.Back")}
          </Button>
        ),
        <Button key="close" size="large" onClick={handleOk} type="primary" disabled={submitDisabled}>
          {t("ShiftManager.OpenShift")}
        </Button>,
      ]}
    >
      {lastClosedShift && stage === LAST_STAGE ? (
        <OpenShiftReport
          amounts={openCashAmounts}
          terminals={terminals}
          lastShiftCloseAmounts={lastClosedShift.close_cash_amounts}
          correctionAmount={correctionAmount}
          keyboard={keyboard}
        />
      ) : (
        <>
          <span style={{ fontSize: 16 }}>{t("ShiftManager.Input.Label")}</span>
          <Form layout="vertical" style={{ marginTop: 10 }}>
            {terminals.map((terminal) => (
              <Form.Item
                key={terminal.id}
                style={{ marginBottom: 10 }}
              >
                <Input
                  size="large"
                  onFocus={() => setSelectedInput(terminal.id)}
                  value={openCashAmounts[terminal.id]}
                  placeholder={terminal.name}
                />
              </Form.Item>
            ))}
          </Form>
          <div style={{ display: "flex", justifyContent: "space-between", fontSize: 16 }}>
            {user && <span>{t("ShiftManager.OpenUser")}: {user.name}</span>}
            <DateTimeBlock />
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
            <Numpad
              value={openCashAmounts[selectedInput]}
              onChange={handleValue}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

OpenShift.propTypes = {
  onClose: PropTypes.func,
};

export default OpenShift;
