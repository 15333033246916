import { Model } from "@nozbe/watermelondb";
import { field, readonly, date, relation } from "@nozbe/watermelondb/decorators";

export default class CourierAssign extends Model {
  static table = "courier_assigns";

  static associations = {
    orders: { type: "belongs_to", key: "order_id" },
  };

  @field("courier_id") courier_id;
  @field("user_id") user_id;
  @readonly @date("created_at") created_at;

  @relation("orders", "order_id") order;
}
