import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import colors from "theme/colors";
import { ORDER_TYPE, ORDER_TYPE_LABELS } from "constants/index";
import Orders from "containers/orders.container";
import ConnectData from "containers/connect.container";

import CurrentOrder from "../containers/currentOrder.container";

const Background = styled.div`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.5);
  z-index: 2;
  position: absolute;
`;

const Panel = styled.div`
  background: ${colors.background};
  z-index: 3;
  height: 80%;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
`;

const ContainerFlexbox = styled.div`
  display: flex;
  margin-bottom: 5px;
  flex-wrap: wrap;
  justify-content: ${(props) => props.justify || "flex-start"};
`;

const ExpandContainerFlexbox = styled(ContainerFlexbox)`
  margin-right: -5px;
`;

const Block = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 120px;
  height: 70px;
  border-radius: 5px;
  margin-right: 5px;
  text-align: center;
  font-weight: bold;
  background-color: ${(props) => (props.color || colors.white)};
  color: ${(props) => props.color && colors.white};
`;

const CancelBlock = styled.button`
  cursor: pointer;
  text-align: center;
  width: 100px;
  height: 70px;
  border: 1px solid red;
  border-radius: 5px;
  text-align: center;
  background-color: red;
  color: ${colors.white};
`;

const TablePanel = ({ t, onClose }) => {
  const { openOrders } = Orders.useContainer();
  const { tables } = ConnectData.useContainer();
  const { orderType, setOrderType, tableId, setTableId } = CurrentOrder.useContainer();

  const busyTables = openOrders.map((order) => order.table_id).filter((id) => id);

  useEffect(() => {
    if (tableId) {
      setOrderType(ORDER_TYPE.IN_STORE);
    }
  }, [tableId]);

  const handleSelectOrderType = (value) => {
    setOrderType(value);
    setTableId(null);
    onClose();
  };

  return (
    <Background>
      <Panel>
        <ExpandContainerFlexbox justify="center">
          {tables.map((table) => (
            <Block
              onClick={() => {
                setTableId(table.id);
                onClose();
              }}
              key={table.id}
              // eslint-disable-next-line no-nested-ternary
              color={table.id === tableId
                ? colors.dodgerBlue
                : (busyTables.includes(table.id) ? colors.lightGrey : null)}
            >
              {table.name}
            </Block>
          ))}
        </ExpandContainerFlexbox>
        <ContainerFlexbox justify="space-between">
          <ContainerFlexbox>
            <Block
              color={orderType === ORDER_TYPE.IN_STORE && colors.dodgerBlue}
              onClick={() => handleSelectOrderType(ORDER_TYPE.IN_STORE)}
            >
              {ORDER_TYPE_LABELS[ORDER_TYPE.IN_STORE]}
            </Block>
            <Block
              color={orderType === ORDER_TYPE.PICKUP && colors.dodgerBlue}
              onClick={() => handleSelectOrderType(ORDER_TYPE.PICKUP)}
            >
              {ORDER_TYPE_LABELS[ORDER_TYPE.PICKUP]}
            </Block>
          </ContainerFlexbox>
          <CancelBlock
            onClick={onClose}
          >
            {t("Close")}
          </CancelBlock>
        </ContainerFlexbox>
      </Panel>
    </Background>
  );
};

TablePanel.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TablePanel;
