import React, { useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Row, Col, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useObservable } from "rxjs-hooks";
import { map, switchMap } from "rxjs/operators";

import {
  ORDER_PAYMENT_TYPE, ORDER_TYPE_LABELS, PAYMENT_METHOD_NAME, PAYMENT_METHOD_TYPE,
} from "constants/index";
import ConnectData from "containers/connect.container";
import { useService } from "pos-service";
import { printComponent } from "utils/print";

const Container = styled.div`
  padding: 12px;
`;

const StatsHeader = ({ children }) => (
  <tr>
    <th colSpan={3}>{children}</th>
  </tr>
);

StatsHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

const StatsRow = ({ label, value, count }) => (
  <tr>
    <td width="170px">{label}</td>
    <td width="30px">{count}</td>
    <td width="90px" style={{ textAlign: "right" }}>{value}</td>
  </tr>
);

StatsRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  count: PropTypes.number,
};

const Stats = () => {
  const { t } = useTranslation();

  const {
    terminal, location, payment_methods, delivery_zones, users,
  } = ConnectData.useContainer();
  const { service } = useService();

  const openCashierShift = useObservable(
    () => service.openCashierShift$, service.openCashierShift.getValue(),
  );

  const shiftOpenAmountsTotal = useMemo(() => Object.values(openCashierShift.open_cash_amounts)
    .reduce((sum, terminalAmount) => sum + parseFloat(terminalAmount), 0), [openCashierShift]);

  const shiftTransactions = useObservable((_s, inputs$) => inputs$.pipe(
    switchMap(([shift]) => shift.finance_transactions.observe()),
  ), [], [openCashierShift]);

  const shiftIncomesTotal = useMemo(() => shiftTransactions
    .filter(({ type }) => type === "income")
    .reduce((sum, { amount }) => sum + amount, 0), [shiftTransactions]);

  const shiftExpensesTotal = useMemo(() => shiftTransactions
    .filter(({ type }) => type === "expense")
    .reduce((sum, { amount }) => sum + amount, 0), [shiftTransactions]);

  const shiftPayments = useObservable((_s, inputs$) => inputs$.pipe(
    switchMap(([shift]) => shift.order_payments.observe()),
    map((ops) => ops.map((op) => ({
      // eslint-disable-next-line no-underscore-dangle
      ...op._raw, diff: op.type === ORDER_PAYMENT_TYPE.PAYMENT ? op.amount : -op.amount,
    }))),
  ), [], [openCashierShift]);

  const orders = useObservable(() => service.shiftOrders$(), []);

  const totalRevenue = useMemo(
    () => shiftPayments.reduce((acc, { diff }) => acc + diff, 0), [shiftPayments],
  );

  const revenueByPaymentMethods = useMemo(() => shiftPayments.reduce(
    (acc, { diff, payment_method_id }) => {
      const foundIdx = acc.findIndex((r) => r.paymentMethod.id === payment_method_id);
      if (foundIdx >= 0) {
        return acc.map((r, idx) => (idx === foundIdx ? { ...r, amount: r.amount + diff } : r));
      }
      const paymentMethod = payment_methods.find((pm) => pm.id === payment_method_id);
      return acc.concat({ paymentMethod, amount: diff });
    }, [],
  ), [shiftPayments]);

  const refunds = useMemo(() => shiftPayments.reduce(
    (acc, { type, amount, payment_method_id }) => {
      if (type === ORDER_PAYMENT_TYPE.PAYMENT) { return acc; }
      const foundIdx = acc.findIndex((r) => r.paymentMethod.id === payment_method_id);
      if (foundIdx >= 0) {
        return acc.map((r, idx) => (idx === foundIdx ? { ...r, amount: r.amount + amount } : r));
      }
      const paymentMethod = payment_methods.find((pm) => pm.id === payment_method_id);
      return acc.concat({ paymentMethod, amount });
    }, [],
  ), [shiftPayments]);

  const revenueByOrderType = useMemo(() => shiftPayments.reduce(
    (acc, { order_id, diff }) => {
      const order = orders.find((o) => o.id === order_id);
      const typeStats = acc[order.type] || { orders: new Set(), revenue: 0 };
      return {
        ...acc,
        [order.type]: { orders: typeStats.orders.add(order_id), revenue: typeStats.revenue + diff },
      };
    }, {},
  ), [shiftPayments]);

  const revenueByUsers = useMemo(() => shiftPayments.reduce(
    (acc, { user_id, order_id, diff }) => {
      const userStats = acc[user_id] || {
        name: users.find((u) => u.id === user_id)?.name, orders: new Set(), revenue: 0,
      };
      return {
        ...acc,
        [user_id]: {
          ...userStats, orders: userStats.orders.add(order_id), revenue: userStats.revenue + diff,
        },
      };
    }, {},
  ), [shiftPayments]);

  const salesByUsers = useMemo(() => orders.reduce(
    (acc, { id, user_id, list_user, list_price }) => {
      const userStats = acc[user_id] || { name: list_user, orders: new Set(), sales: 0 };
      return {
        ...acc,
        [user_id]: {
          ...userStats, orders: userStats.orders.add(id), sales: userStats.sales + list_price,
        },
      };
    }, {},
  ), [orders]);

  const stats_by_courier = useMemo(() => Object.values(orders.reduce(
    (acc, { courier_id, delivery_info }) => {
      const deliveryZone = delivery_info && delivery_zones
        .find((dz) => dz.id === delivery_info.delivery_zone_id);
      if (!delivery_info || !deliveryZone) {
        return acc;
      }
      const courierStats = acc[courier_id] || {
        id: courier_id,
        name: users.find((u) => u.id === courier_id)?.name,
        delivery_zones: [],
      };
      const zoneIdx = courierStats.delivery_zones.findIndex((dz) => dz.id === deliveryZone.id);
      const newZones = zoneIdx >= 0
        ? courierStats.delivery_zones.map((dz, idx) => (zoneIdx === idx ? {
          ...dz, count: dz.count + 1, profit: dz.profit + deliveryZone.price,
        } : dz))
        : courierStats.delivery_zones.concat({
          ...deliveryZone, count: 1, profit: deliveryZone.price,
        });
      return { ...acc, [courier_id]: { ...courierStats, delivery_zones: newZones } };
    }, {},
  )), [orders]);

  const renderCommonStats = (
    <table border="0">
      <tbody>
        <StatsRow label={t("shift.Location")} value={location.name} />
        <StatsRow label={t("shift.Cashier")} value={terminal.name} />
        <StatsRow label={t("shift.Date")} value={openCashierShift.opened_at.toLocaleString()} />

        <StatsRow label={t("shift.NumberOfOrders")} value={orders.length} />
        <StatsRow label={t("shift.TotalRevenue")} value={totalRevenue} />

        <StatsHeader>{t("shift.CashierShift")}:</StatsHeader>
        <StatsRow label={t("shift.ShiftOpeningAmount")} value={shiftOpenAmountsTotal} />
        <StatsRow label={t("shift.ShiftIncomes")} value={shiftIncomesTotal} />
        <StatsRow label={t("shift.ShiftExpenses")} value={shiftExpensesTotal} />

        {revenueByPaymentMethods.length > 0 && (
          <>
            <StatsHeader>{t("shift.RevenueByPaymentMethod")}:</StatsHeader>
            {revenueByPaymentMethods.map((p) => (
              <StatsRow
                key={p.paymentMethod.name}
                label={p.paymentMethod.type === PAYMENT_METHOD_TYPE.CUSTOM
                  ? p.paymentMethod.name : PAYMENT_METHOD_NAME[p.paymentMethod.name]}
                value={p.amount}
              />
            ))}
          </>
        )}

        {refunds.length > 0 && (
          <>
            <StatsHeader>{t("shift.Refunds")}:</StatsHeader>
            {refunds.map((p) => (
              <StatsRow
                key={p.paymentMethod.name}
                label={p.paymentMethod.type === PAYMENT_METHOD_TYPE.CUSTOM
                  ? p.paymentMethod.name : PAYMENT_METHOD_NAME[p.paymentMethod.name]}
                value={p.amount}
              />
            ))}
          </>
        )}

        {Object.keys(revenueByOrderType).length > 0 && (
          <>
            <StatsHeader>{t("shift.RevenueByOrderType")}:</StatsHeader>
            {Object.entries(revenueByOrderType).map(([type, stats]) => (
              <StatsRow
                key={type}
                label={ORDER_TYPE_LABELS[type]}
                value={stats.revenue}
                count={stats.orders.size}
              />
            ))}
          </>
        )}

        {Object.keys(revenueByUsers).length > 0 && (
          <>
            <StatsHeader>{t("shift.RevenueByUsers")}:</StatsHeader>
            {Object.entries(revenueByUsers).map(([id, r]) => (
              <StatsRow key={id} label={r.name} value={r.revenue} count={r.orders.size} />
            ))}
          </>
        )}

        {Object.keys(salesByUsers).length > 0 && (
          <>
            <StatsHeader>{t("shift.SalesByUsers")}:</StatsHeader>
            {Object.entries(salesByUsers).map(([id, s]) => (
              <StatsRow key={id} label={s.name} value={s.sales} count={s.orders.size} />
            ))}
          </>
        )}
      </tbody>
    </table>
  );

  const handlePrintStats = () => {
    const printCourierStats = stats_by_courier.length > 0 && (
      <table border="0">
        <tbody>
          <StatsHeader>{t("shift.NumberOfDeliveries")}:</StatsHeader>
          {stats_by_courier.map((stats) => {
            const totalCount = stats.delivery_zones.reduce((acc, dz) => acc + dz.count, 0);
            const totalProfit = stats.delivery_zones.reduce((acc, dz) => acc + dz.profit, 0);
            return (
              <StatsRow
                key={stats.id}
                label={stats.name || t("shift.NoAssignedCourier")}
                count={totalCount}
                value={totalProfit}
              />
            );
          })}
        </tbody>
      </table>
    );
    return printComponent(
      <div style={{ color: "#000", fontFamily: "sans-serif" }}>
        {renderCommonStats}
        {printCourierStats}
      </div>,
    );
  };

  return (
    <Container>
      <Row type="flex" justify="end">
        <Button type="primary" onClick={handlePrintStats}>{t("shift.Print")}</Button>
      </Row>
      <Row gutter={24}>
        <Col md={12} lg={8}>
          {renderCommonStats}
        </Col>
        <Col md={12} lg={8}>
          <table border="0">
            <tbody>
              {stats_by_courier.map((stats) => {
                const totalCount = stats.delivery_zones.reduce((acc, dz) => acc + dz.count, 0);
                const totalProfit = stats.delivery_zones.reduce((acc, dz) => acc + dz.profit, 0);
                return (
                  <React.Fragment key={stats.id}>
                    <StatsHeader>
                      {t("shift.Delivery")}: {stats.name
                        || t("shift.NoAssignedCourier")} ({totalCount} - {totalProfit})
                    </StatsHeader>
                    {stats.delivery_zones.map((dz) => (
                      <StatsRow key={dz.name} label={dz.name} count={dz.count} value={dz.profit} />
                    ))}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
};

export default Stats;
