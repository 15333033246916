import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { filter, take } from "rxjs/operators";

import Context from "./context";

import LocalDbService from "./adapters/LocalDbService";
import { SYNC_STATUS } from "./adapters/SyncManager";

export const Provider = ({ config, client, children }) => {
  const [context, setContext] = useState({
    initializing: true, error: null, service: null,
  });

  useEffect(() => {
    if (context.initializing !== true) {
      setContext({ initializing: true, error: null, service: null });
    }
    const service = new LocalDbService(config, client);
    const subscription = service.syncManager.status$.pipe(
      filter(({ status }) => [SYNC_STATUS.ERROR, SYNC_STATUS.SYNCED].includes(status)),
      take(1),
    ).subscribe(() => setContext({ initializing: false, error: null, service }));

    return () => {
      subscription.unsubscribe();
      service.cleanup();
    };
  }, [JSON.stringify(config)]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const useService = () => useContext(Context);

Provider.propTypes = {
  config: PropTypes.shape({
    terminal: PropTypes.shape({
      id: PropTypes.number.isRequired,
      settings: PropTypes.object,
      main: PropTypes.bool.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    merchant: PropTypes.shape({
      plan: PropTypes.string.isRequired,
    }).isRequired,
    tables: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    commonSettings: PropTypes.shape({
      service_percent: PropTypes.number.isRequired,
      shift_offset: PropTypes.number.isRequired,
    }).isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    paymentMethods: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })),
    menuItemsById: PropTypes.objectOf(PropTypes.shape({
      product_id: PropTypes.number,
      tech_card: PropTypes.shape({
        workshop_id: PropTypes.number,
      }),
    })),
  }).isRequired,
  client: PropTypes.object.isRequired,
  children: PropTypes.node,
};
