import styled, { createGlobalStyle } from "styled-components";

import colors from "theme/colors";

export const Align = styled.div`
  display: flex;
  justify-content: ${(props) => props.align};
`;

const GlobalStyle = createGlobalStyle`
  #root {
    height: 100%;
  }

  .app-layout {
    height: auto;
    min-height: 100%;
  }

  .cap-2-line {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .ant-form-item-label {
    white-space: pre-wrap;
  }

  .ant-table-body > table {
    border: 1px solid ${colors.whisper};
  }

  .ant-table-placeholder {
    border-right: 1px solid ${colors.whisper};
    border-left: 1px solid ${colors.whisper};
  }

  .no-scroll-bar {
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export default GlobalStyle;
