import { appSchema, tableSchema } from "@nozbe/watermelondb";

export default appSchema({
  version: 3,
  tables: [
    tableSchema({
      name: "orders",
      columns: [
        { name: "location_id", type: "number", isIndexed: true },
        { name: "time", type: "number", isIndexed: true },
        { name: "type", type: "string", isIndexed: true },
        { name: "source", type: "string", isIndexed: true },
        { name: "number", type: "number" },
        { name: "user_id", type: "number", isOptional: true, isIndexed: true },
        { name: "api_key_id", type: "number", isOptional: true },
        { name: "integration_id", type: "number", isOptional: true },
        { name: "list_user", type: "string", isOptional: true },
        { name: "table_id", type: "number", isOptional: true },
        { name: "list_table_name", type: "string", isOptional: true },
        { name: "status", type: "string", isIndexed: true },
        { name: "status_updated_at", type: "number" },
        { name: "list_price", type: "number" },
        { name: "notes", type: "string", isOptional: true },
        { name: "customer_id", type: "string", isOptional: true },
        { name: "delivery_info_id", type: "string", isOptional: true },
        { name: "courier_id", type: "number", isOptional: true },
        { name: "completed_shift_id", type: "string", isIndexed: true, isOptional: true },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
        { name: "customer", type: "string", isOptional: true },
        { name: "delivery_info", type: "string", isOptional: true },
      ],
    }),
    tableSchema({
      name: "courier_assigns",
      columns: [
        { name: "order_id", type: "string" },
        { name: "courier_id", type: "number" },
        { name: "user_id", type: "number" },
        { name: "created_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "order_payments",
      columns: [
        { name: "order_id", type: "string", isIndexed: true },
        { name: "amount", type: "number" },
        { name: "type", type: "string" },
        { name: "payment_method_id", type: "number" },
        { name: "metadata", type: "string", isOptional: true },
        { name: "user_id", type: "number", isOptional: true },
        { name: "terminal_id", type: "number", isOptional: true },
        { name: "shift_id", type: "string", isIndexed: true, isOptional: true },
        { name: "created_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "order_price_updates",
      columns: [
        { name: "order_id", type: "string", isIndexed: true },
        { name: "message", type: "string" },
        { name: "order_lines_total", type: "number" },
        { name: "discount", type: "number" },
        { name: "service_percent", type: "number" },
        { name: "delivery_price", type: "number" },
        { name: "list_price", type: "number" },
        { name: "user_id", type: "number", isOptional: true },
        { name: "created_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "order_status_updates",
      columns: [
        { name: "order_id", type: "string", isIndexed: true },
        { name: "status", type: "string" },
        { name: "terminal_id", type: "number", isOptional: true },
        { name: "user_id", type: "number", isOptional: true },
        { name: "created_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "order_cancels",
      columns: [
        { name: "order_id", type: "string", isIndexed: true },
        { name: "reason", type: "string" },
        { name: "notes", type: "string", isOptional: true },
        { name: "refund", type: "boolean" },
        { name: "user_id", type: "number", isOptional: true },
        { name: "source", type: "string" },
        { name: "created_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "order_lines",
      columns: [
        { name: "order_id", type: "string", isIndexed: true },
        { name: "menu_item_id", type: "number" },
        { name: "display_name", type: "string" },
        { name: "count", type: "number" },
        { name: "notes", type: "string", isOptional: true },
        { name: "price", type: "number" },
        { name: "total_price", type: "number" },
        { name: "unit", type: "string" },
        { name: "modifiers", type: "string", isOptional: true },
        { name: "status", type: "string" },
        { name: "status_updated_at", type: "number" },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "order_line_cancels",
      columns: [
        { name: "order_line_id", type: "string", isIndexed: true },
        { name: "reason", type: "string" },
        { name: "refund", type: "boolean" },
        { name: "write_off", type: "boolean" },
        { name: "user_id", type: "number", isOptional: true },
        { name: "created_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "order_line_status_updates",
      columns: [
        { name: "order_line_id", type: "string", isIndexed: true },
        { name: "status", type: "string" },
        { name: "terminal_id", type: "number", isOptional: true },
        { name: "user_id", type: "number", isOptional: true },
        { name: "created_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "cashier_shifts",
      columns: [
        { name: "number", type: "number" },
        { name: "open_user_id", type: "number" },
        { name: "close_user_id", type: "number", isOptional: true },
        { name: "open_cash_amounts", type: "string" },
        { name: "close_cash_amounts", type: "string", isOptional: true },
        { name: "actual_cash_amounts", type: "string", isOptional: true },
        { name: "expected_cash_amounts", type: "string", isOptional: true },
        { name: "opened_at", type: "number" },
        { name: "closed_at", type: "number", isOptional: true },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
    tableSchema({
      name: "finance_transactions",
      columns: [
        { name: "type", type: "string" },
        { name: "category_id", type: "number", isOptional: true },
        { name: "transfer_to_account_id", type: "number", isOptional: true },
        { name: "transfer_to_amount", type: "number", isOptional: true },
        { name: "description", type: "string", isOptional: true },
        { name: "account_id", type: "number" },
        { name: "amount", type: "number" },
        { name: "time", type: "number" },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
        { name: "user_id", type: "number" },
        { name: "terminal_id", type: "number", isIndexed: true },
        { name: "shift_id", type: "string", isIndexed: true, isOptional: true },
      ],
    }),
  ],
});
