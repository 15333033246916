/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */

const clearOrdersRecursive = (orders, db) => {
  const [order, ...next] = orders;

  return Promise.all([
    order.order_lines.fetch(),
    order.courier_assigns.fetch(),
    order.order_cancels.fetch(),
    order.order_payments.fetch(),
    order.order_price_updates.fetch(),
    order.order_status_updates.fetch(),
  ])
    .then((orderData) => orderData.flat())
    .then((records) => records.filter((r) => r._raw._status === "synced"))
    .then((records) => db.write(
      (writer) => writer.batch(...records.map((r) => r.prepareDestroyPermanently())),
    ))
    .then(() => db.write(() => order.destroyPermanently()))
    .then(() => next.length > 0 && clearOrdersRecursive(next, db));
};

const clearShiftsRecursive = (shifts, db) => {
  const [shift, ...next] = shifts;
  console.log("[🧹] Cleaning shift: ", shift);

  return shift.orders.fetch()
    .then((orders) => orders.filter((r) => r._raw._status === "synced"))
    .then((orders) => orders.length > 0 && clearOrdersRecursive(orders, db))
    .then(() => db.write(() => shift.destroyPermanently()))
    .then(() => next.length > 0 && clearShiftsRecursive(next, db));
};

export default {
  clean: (shifts, db) => {
    if (shifts.length < 3) return Promise.resolve();
    const shiftsToDelete = shifts
      .sort((a, b) => (b.closed_at || new Date()) - (a.closed_at || new Date()))
      .slice(2)
      .filter((s) => s._raw._status === "synced");
    return clearShiftsRecursive(shiftsToDelete, db);
  },
};
