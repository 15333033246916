import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { Layout, Spin, Modal } from "antd";
import { useTranslation } from "react-i18next";
import preval from "preval.macro";

import SettingsModal from "components/SettingsModal";
import Header from "components/Header";
import ShiftManagerProvider from "components/ShiftManager/provider";
import GlobalStyle from "theme/globalStyles";
import { ORDER_TYPE } from "constants/index";
import CurrentUser from "containers/current-user";
import Orders from "containers/orders.container";
import colors from "theme/colors";

import { useService } from "./pos-service";

import OpenOrders from "./pages/openOrders";
import Cashier from "./pages/cashier";
import PinCode from "./pages/pin-code";
import Stats from "./pages/stats";
import Archive from "./pages/archive";
import DeliveryOrders from "./pages/deliveryOrders";
import ErrorPage from "./pages/error-page";
import ShiftClosed from "./pages/shift-closed";

const Loader = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const StyledLayout = styled(Layout)`
  margin-left: none;
  background: ${colors.background};
`;

const AppLayout = ({ onLogout }) => {
  const { t } = useTranslation();
  const [showSettings, setShowSettings] = useState(false);
  const { user, setUser } = CurrentUser.useContainer();
  const { openOrders } = Orders.useContainer();
  const { initializing, error, service } = useService();
  const version = preval`module.exports = require("../package.json").version`;

  const handleLogout = () => {
    service.clearDB();
    onLogout();
  };

  const openOrdersCount = user ? openOrders.filter(
    (order) => order.type === ORDER_TYPE.IN_STORE && (user.isAdmin || order.user_id === user.id),
  ).length : 0;

  const deliveryOrdersCount = openOrders
    .filter((order) => order.type === ORDER_TYPE.DELIVERY || order.type === ORDER_TYPE.PICKUP)
    .length;

  if (error) {
    return (
      <ErrorPage onLogout={handleLogout} error={error.message} />
    );
  }

  if (initializing) {
    return <Loader />;
  }

  const handleTerminalLogout = () => Modal.confirm({
    title: t("system.TerminalLogout.Confirm.Title"),
    content: t("system.TerminalLogout.Confirm.Message"),
    transitionName: "",
    maskTransitionName: "",
    zIndex: 999999,
    onOk: handleLogout,
    onCancel() {},
  });

  const handleQuitApp = window.electron ? () => Modal.confirm({
    title: t("system.Quit.Confirm"),
    transitionName: "",
    maskTransitionName: "",
    zIndex: 999999,
    onOk() { window.electron.quit(); },
    onCancel() {},
  }) : null;

  return (
    <>
      <GlobalStyle />
      {!user && (
        <PinCode
          version={version}
          onTerminalLogout={handleTerminalLogout}
          onQuitApp={handleQuitApp}
        />
      )}
      <ShiftManagerProvider>
        <Layout className="app-layout" tabIndex="-1">
          <StyledLayout>
            <Header
              orderCounts={{
                delivery: deliveryOrdersCount, openOrder: openOrdersCount,
              }}
              onLogout={() => setUser(null)}
              onOpenSettingPanel={() => setShowSettings(true)}
              user={user}
            />
            {service.isShiftOpen ? (
              <Switch>
                <Route path="/cashier/:id?" component={Cashier} />
                <Route path="/open-orders" component={OpenOrders} />
                <Route path="/delivery-orders" component={DeliveryOrders} />
                <Route path="/stats" component={Stats} />
                <Route path="/archive" component={Archive} />
                <Redirect to="/open-orders" />
              </Switch>
            ) : (
              <Switch>
                <Route path="/shift-closed" component={ShiftClosed} />
                <Redirect to="/shift-closed" />
              </Switch>
            )}
          </StyledLayout>
        </Layout>
        {showSettings && (
          <SettingsModal
            onClose={() => setShowSettings(false)}
            onTerminalLogout={handleTerminalLogout}
            onQuitApp={handleQuitApp}
          />
        )}
      </ShiftManagerProvider>
    </>
  );
};

AppLayout.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default AppLayout;
