import * as Sentry from "@sentry/react";
import _ from "lodash";
import { synchronize } from "@nozbe/watermelondb/sync";
import gql from "graphql-tag";
import { BehaviorSubject } from "rxjs";

import logDebug from "./logDebug";

const PULL_CHANGES_QUERY = gql`
  query PullSyncChanges($lastPulledAt: Int!, $shiftId: String) {
    pullSyncChanges(lastPulledAt: $lastPulledAt, shiftId: $shiftId) {
      timestamp
      changes {
        orders {
          created {
            id
          }
          deleted
          updated {
            id
            location_id
            time
            type
            source
            number
            user_id
            api_key_id
            integration_id
            list_user
            table_id
            list_table_name
            status
            status_updated_at
            completed_shift_id
            list_price
            notes
            customer_id
            delivery_info_id
            courier_id
            created_at
            updated_at
            customer {
              id
              name
              phone_number
            }
            delivery_info {
              id
              cash_return
              payment_method_id
              address_id
              delivery_zone_id
              address {
                id
                customer_id
                street
                house
                flat
                notes
                geo
              }
            }
          }
        }
        order_lines {
          created {
            id
          }
          deleted
          updated {
            id
            order_id
            menu_item_id
            display_name
            count
            notes
            price
            total_price
            unit
            modifiers
            status
            status_updated_at
            created_at
            updated_at
          }
        }
        order_payments {
          created {
            id
          }
          deleted
          updated {
            id
            order_id
            amount
            type
            payment_method_id
            shift_id
            metadata
            user_id
            terminal_id
            created_at
          }
        }
        order_price_updates {
          created {
            id
          }
          deleted
          updated {
            id
            order_id
            message
            order_lines_total
            discount
            service_percent
            delivery_price
            list_price
            user_id
            created_at
          }
        }
        cashier_shifts {
          created {
            id
          }
          deleted
          updated {
            id
            number
            open_user_id
            close_user_id
            open_cash_amounts
            close_cash_amounts
            actual_cash_amounts
            expected_cash_amounts
            opened_at
            closed_at
            updated_at
            created_at
          }
        }
        finance_transactions {
          created {
            id
          }
          deleted
          updated {
            id
            type
            category_id
            transfer_to_account_id
            transfer_to_amount
            description
            account_id
            amount
            time
            created_at
            updated_at
            user_id
            terminal_id
            shift_id
          }
        }
      }
    }
  }
`;

const PUSH_CHANGES_MUTATION = gql`
  mutation PushSyncChanges($lastPulledAt: Int!, $changes: SyncPushChangesInput!) {
    pushSyncChanges(lastPulledAt: $lastPulledAt, changes: $changes) {
      status
    }
  }
`;

export const SYNC_STATUS = {
  STOPPED: "stopped",
  IN_PROGRESS: "in-progress",
  SYNCED: "synced",
  ERROR: "error",
};

const PULL_JSON_FIELDS = {
  order_lines: ["modifiers"],
  order_payments: ["metadata"],
  cashier_shifts: [
    "open_cash_amounts",
    "close_cash_amounts",
    "actual_cash_amounts",
    "expected_cash_amounts",
  ],
  orders: ["customer", "delivery_info"],
};

const PUSH_JSON_FIELDS = {
  order_lines: ["modifiers"],
  order_payments: ["metadata"],
  cashier_shifts: [
    "open_cash_amounts",
    "close_cash_amounts",
    "actual_cash_amounts",
    "expected_cash_amounts",
  ],
};

export default class SyncManager {
  constructor(service, client) {
    this.service = service;
    this.client = client;
    this.status = new BehaviorSubject({ status: SYNC_STATUS.STOPPED });
  }

  get status$() {
    return this.status.asObservable();
  }

  start() {
    if (this.status.getValue().status === SYNC_STATUS.STOPPED) {
      this.syncAndSchedule();
    }
  }

  syncAndSchedule() {
    const { lastSync } = this.status.getValue();
    this.status.next({ status: SYNC_STATUS.IN_PROGRESS });
    return this.sync()
      .then(() => {
        if (this.status.getValue().status !== SYNC_STATUS.STOPPED) {
          this.syncTimeout = setTimeout(() => this.syncAndSchedule(), 7000);
          this.status.next({ status: SYNC_STATUS.SYNCED, lastSync: Date.now() });
        }
      })
      .catch((error) => {
        if (error.message === "Errors.Sync.ModifiedOnServer") {
          this.syncAndSchedule();
        } else if (this.status.getValue().status !== SYNC_STATUS.STOPPED) {
          this.syncTimeout = setTimeout(() => this.syncAndSchedule(), 7000);
          this.status.next({
            status: SYNC_STATUS.ERROR, error, lastSync, nextSync: Date.now() + 7000,
          });
        }
      });
  }

  stop() {
    this.status.next({ status: SYNC_STATUS.STOPPED });
    clearTimeout(this.syncTimeout);
  }

  sync() {
    return synchronize({
      database: this.service.db,
      sendCreatedAsUpdated: true,
      _unsafeBatchPerCollection: true,
      pullChanges: ({ lastPulledAt }) => this.client.query({
        query: PULL_CHANGES_QUERY,
        variables: {
          lastPulledAt: lastPulledAt ?? 1,
          shiftId: this.service.openCashierShift.getValue()?.id || null,
        },
        fetchPolicy: "no-cache",
      }).then(({
        data: {
          pullSyncChanges: { changes: { __typename, ...ch }, timestamp },
        },
      }) => ({
        changes: _.mapValues(ch, ({ created, updated, deleted }, key) => {
          const parseFields = (v) => ({
            ...v,
            ...(PULL_JSON_FIELDS[key]?.reduce((acc, field) => ({
              ...acc,
              [field]: v[field] ? JSON.stringify(v[field]) : null,
            }), {}) ?? {}),
          });
          return {
            created: created.map(parseFields),
            updated: updated.map(parseFields),
            deleted,
          };
        }),
        timestamp,
      })),
      pushChanges: ({ lastPulledAt, changes }) => {
        const pushChanges = _(changes)
          .mapValues(({ created, updated, deleted }, key) => {
            const omitKeys = ["_status", "_changed", "customer", "delivery_info"].concat(
              ["order_cancels", "order_line_cancels"].includes(key) ? ["id"] : [],
            );
            const normalizeFields = (v) => ({
              ..._.omit(v, omitKeys),
              ...(PUSH_JSON_FIELDS[key]?.reduce((acc, field) => ({
                ...acc,
                [field]: JSON.parse(v[field]),
              }), {}) ?? {}),
            });
            return {
              created: created.map(normalizeFields),
              updated: updated.map(normalizeFields),
              deleted,
            };
          })
          .value();
        return this.client.mutate({
          mutation: PUSH_CHANGES_MUTATION,
          variables: { lastPulledAt, changes: pushChanges },
          fetchPolicy: "no-cache",
        });
      },
    }).catch((error) => {
      logDebug("synchronize Error", error);
      Sentry.captureException(error, {
        tags: { section: "replication" },
      });
      throw error;
    });
  }
}
