import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { ApolloProvider } from "@apollo/client";

import { syncStorage } from "utils/useLocalStorage";

import App from "./App";
import createClient from "./createClient";

const Loader = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const SyncedApp = () => {
  const [isSynced, setIsSynced] = useState(false);
  const [client, setClient] = useState(null);
  useEffect(() => {
    syncStorage().finally(() => {
      setIsSynced(true);
      createClient().then(setClient);
    });
  }, []);

  if (!isSynced || !client) return <Loader />;

  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );
};

export default SyncedApp;
