import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const ColoredTime = styled.span`
  ${({ color }) => color && css`
    color: ${color};
  `};
`;

const Timer = ({ start, textColor }) => {
  const [duration, setDuration] = useState(null);

  useEffect(() => {
    setDuration((Date.now() - start) / 1000);
  }, [start]);

  useEffect(() => {
    const timerId = setTimeout(() => setDuration((d) => d + 1), 1000);
    return () => clearTimeout(timerId);
  }, [duration]);

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor((duration % 3600) % 60);

  return (
    <ColoredTime className="timer" color={textColor && textColor(duration)}>
      {hours > 0 ? `${hours}:` : ""}
      {minutes < 10 ? `0${minutes}` : minutes}:
      {seconds < 10 ? `0${seconds}` : seconds}
    </ColoredTime>
  );
};

Timer.propTypes = {
  start: PropTypes.instanceOf(Date),
  textColor: PropTypes.func,
};

export default Timer;
