import { Model } from "@nozbe/watermelondb";
import { field, readonly, date, relation } from "@nozbe/watermelondb/decorators";

export default class OrderPriceUpdate extends Model {
  static table = "order_price_updates";

  static associations = {
    orders: { type: "belongs_to", key: "order_id" },
  };

  @field("message") message;
  @field("order_lines_total") order_lines_total;
  @field("discount") discount;
  @field("service_percent") service_percent;
  @field("delivery_price") delivery_price;
  @field("list_price") list_price;
  @field("user_id") user_id;
  @readonly @date("created_at") created_at;

  @relation("orders", "order_id") order;
}
