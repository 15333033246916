import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button, Col, Form, Input, Radio, Row,
} from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { useService } from "pos-service";
import CurrentUser from "containers/current-user";
import ConnectData from "containers/connect.container";

import useKeyboard from "components/Keyboard/useKeyboard";
import Numpad from "components/Numpad";
import colors from "theme/colors";

import SearchSelector from "../SearchSelector";

const NumpadCol = styled(Col)`
  background: ${colors.background};
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 8px;
  align-items: center;
`;

const CreateTransaction = ({ onCloseModal }) => {
  const { t } = useTranslation();
  const { finance_categories } = ConnectData.useContainer();
  const { user } = CurrentUser.useContainer();
  const { service } = useService();

  const [type, setType] = useState("income");
  const commentsKeyboard = useKeyboard();
  const [amount, setAmount] = useState();
  const [categorySelectorVisible, setCategorySelectorVisible] = useState(false);
  const [category, setCategory] = useState();

  useEffect(() => {
    setCategory(undefined);
  }, [type]);

  const handleCreate = () =>
    service.createTransaction(
      user.id, type, category.id, commentsKeyboard.state, parseFloat(amount),
    )
      .then(onCloseModal);

  if (categorySelectorVisible) {
    return (
      <SearchSelector
        items={finance_categories.filter((c) => (type === "expense" ? c.allow_expenses : c.allow_income))}
        value={category}
        onSelect={setCategory}
        onClose={() => setCategorySelectorVisible(false)}
        placeholder={t("createTransaction.CategorySelector.CategoryName")}
        containerStyle={{
          height: "calc(100% - 50px)",
          borderRadius: "0 0 8px 8px",
        }}
      />
    );
  }

  return (
    <Row style={{ height: "calc(100% - 50px)" }}>
      <Col span={12}>
        <Form size="large" layout="vertical" style={{ margin: 20 }} initialValues={{ type }}>
          <Form.Item name="type">
            <Radio.Group onChange={(e) => setType(e.target.value)}>
              <Radio.Button value="income">{t("createTransaction.Income")}</Radio.Button>
              <Radio.Button value="expense">{t("createTransaction.Expense")}</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <Input
              value={amount}
              placeholder={t("createTransaction.Sum")}
              required
            />
          </Form.Item>
          <Form.Item>
            <Input.Search
              placeholder={t("createTransaction.CategorySelector.Category")}
              enterButton={t("createTransaction.CategorySelector.Select")}
              size="large"
              value={category?.name}
              onSearch={(v) => {
                if (!v) setCategory();
                setCategorySelectorVisible(true);
              }}
              allowClear
              required
            />
          </Form.Item>
          <Form.Item>
            <Input.TextArea
              placeholder={t("createTransaction.Comment")}
              onFocus={commentsKeyboard.show}
              onBlur={commentsKeyboard.onBlur}
              value={commentsKeyboard.state}
              onChange={(e) => commentsKeyboard.setState(e.target.value)}
              required
            />
          </Form.Item>
          <Form.Item>
            <Button
              onClick={handleCreate}
              type="primary"
              htmlType="submit"
            >
              {t("createTransaction.Create")}
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <NumpadCol span={12}>
        <Numpad
          value={amount}
          onChange={setAmount}
          onConditions={() => commentsKeyboard.visible}
          onConditionsBackspace={() => commentsKeyboard.visible}
        />
      </NumpadCol>
    </Row>
  );
};

CreateTransaction.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
};

export default CreateTransaction;
