import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import colors from "theme/colors";

import FoodIcon from "assets/food-icons";

import useKeyboard from "components/Keyboard/useKeyboard";
import Card from "components/Card";
import ScrollableContainer from "components/ScrollableContainer";

const CategoryCard = styled(Card)`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  line-height: 1;
  margin-bottom: ${(props) => (props.isLast ? "0" : "8px")};
  ${(props) => (props.isSelected ? css`
    background: ${colors.dodgerBlue};
    color: ${colors.white};
  ` : "")}
`;

const CategoriesSidebar = ({ menuCategories, onCategorySelect, selectedCategoryId }) => {
  const keyboard = useKeyboard({ clear: false });
  const handleCategorySelect = (id) => {
    keyboard.clearState();
    onCategorySelect(id);
  };

  return (
    <ScrollableContainer>
      {({ containerRef, showScrollButtons, UpButton, DownButton }) => (
        <>
          {showScrollButtons && (<UpButton style={{ marginBottom: 8 }} />)}
          <div
            ref={containerRef}
            className="no-scroll-bar"
            style={{
              height: `calc(100vh - 68px - ${showScrollButtons ? 132 : 0}px)`,
              overflowY: "scroll",
              borderRadius: 8,
            }}
          >
            {menuCategories.map((menuCategory, index) => {
              const isSelected = !keyboard.state && selectedCategoryId === menuCategory.id;
              return (
                <CategoryCard
                  key={menuCategory.id}
                  role="button"
                  onClick={() => handleCategorySelect(menuCategory.id)}
                  isLast={index === menuCategories.length - 1}
                  isSelected={isSelected}
                >
                  <FoodIcon
                    name={menuCategory.icon || "dome-plate-cover"}
                    style={{ width: 30, marginBottom: 7 }}
                    stroke={isSelected ? colors.white : colors.black}
                  />
                  {menuCategory.name}
                </CategoryCard>
              );
            })}
          </div>
          {showScrollButtons && (<DownButton style={{ marginTop: 8 }} />)}
        </>
      )}
    </ScrollableContainer>
  );
};

CategoriesSidebar.propTypes = {
  menuCategories: PropTypes.array,
  onCategorySelect: PropTypes.func.isRequired,
  selectedCategoryId: PropTypes.number,
};

export default CategoriesSidebar;
