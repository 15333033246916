/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import {
  ORDER_LINE_STATUS_LABELS,
  ORDER_LINE_STATUS_COLORS,
  ORDER_LINE_STATUS_ICONS,
} from "constants/index";
import { stringifyModifiers } from "utils/helpers";

const StatusText = styled.small`
  color: ${(props) => props.color};
`;

const NameColumn = ({ orderLine, onClick }) => {
  const { t } = useTranslation();
  const StatusIcon = ORDER_LINE_STATUS_ICONS[orderLine.status];
  return (
    <div>
      {orderLine.display_name}{orderLine.notes && orderLine.id && <i> ({orderLine.notes})</i>}
      <div>
        {orderLine.modifiers?.filter((m) => m.value.length > 0)
          .map((modifier) => (
            <div key={modifier.modifier_group_id} style={{ marginLeft: 15 }}>
              {modifier.list_group_name}: {stringifyModifiers(modifier.value)}
            </div>
          ))}
      </div>
      <div>
        {orderLine.id ? (
          <StatusText color={ORDER_LINE_STATUS_COLORS[orderLine.status]}>
            <StatusIcon />
            &nbsp;
            {ORDER_LINE_STATUS_LABELS[orderLine.status]}
          </StatusText>
        ) : (
          <i
            onClick={onClick && ((e) => { e.stopPropagation(); onClick(); })}
            style={{ marginLeft: 15 }}
          >
            <EditOutlined />
            &nbsp;
            {orderLine.notes || t("cashier.Cashier.CartPanel.NotesModal.OrderLine.ButtonTitle")}
          </i>
        )}
      </div>
    </div>
  );
};

NameColumn.propTypes = {
  orderLine: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default NameColumn;
