import { Model } from "@nozbe/watermelondb";
import { field, readonly, date, relation } from "@nozbe/watermelondb/decorators";

export default class OrderStatusUpdate extends Model {
  static table = "order_status_updates";

  static associations = {
    orders: { type: "belongs_to", key: "order_id" },
  };

  @field("status") status;
  @field("terminal_id") terminal_id;
  @field("user_id") user_id;
  @readonly @date("created_at") created_at;

  @relation("orders", "order_id") order;
}
